<template>
    <v-col cols="12">
        <v-col
            cols="12"
            class="text-center d-flex flex-column justify-center align-items-center"
            v-if="wrongCoach"
            style="height: 50vh"
        >
            <v-card-title class="justify-content-center">Mitglied bereits Berater/in zugewiesen</v-card-title>
            <v-card-text>
                {{ wrongCoach.vorname + ' ' + wrongCoach.nachname }}
                <br />
                {{ wrongCoach.email }}
                <br />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="startAnamnese(false)" color="primary">
                    {{ anamnese._id ? 'Fragebogen bearbeiten' : 'Fragebogen erstellen' }}
                </v-btn>
            </v-card-actions>
        </v-col>
        <v-col
            cols="12"
            class="text-center d-flex flex-column justify-center align-items-center"
            v-if="noNutritionDashboard && !wrongCoach"
            style="height: 50vh"
        >
            <v-card-title class="justify-content-center">Ernährungsdashboard für Kunden erstellen</v-card-title>

            <v-btn
                v-if="!newDashboardForm"
                class="self-center rounded-xl"
                style="max-width: 200px; align-self: center"
                @click="startForm()"
                color="primary"
            >
                Erstellen
            </v-btn>
            <v-form ref="nutritionDashboardForm" v-else class="self-center rounded-xl" style="max-width: 400px">
                <user-search
                    :value="newDashboardForm.coach"
                    :key="newDashboardForm.coach"
                    ignoreRights
                    filled
                    instructor
                    label="Ernährungscoach"
                    @update="updateCoach"
                ></user-search>
                <v-text-field
                    number
                    :rules="rules.required"
                    filled
                    rounded
                    class="mb-2"
                    hide-details
                    label="Startgewicht"
                    v-model="newDashboardForm.startWeight"
                ></v-text-field>
                <v-text-field
                    number
                    filled
                    :rules="rules.required"
                    hide-details
                    rounded
                    label="Zielgewicht"
                    v-model="newDashboardForm.targetWeight"
                ></v-text-field>
                <v-select
                    v-model="newDashboardForm.allergies"
                    :items="allergenFilters"
                    multiple
                    label="Allergien"
                ></v-select>
                <v-combobox
                    v-model="newDashboardForm.targets"
                    :items="targetPossibilities"
                    multiple
                    label="Deine Ziele"
                ></v-combobox>
                <v-btn @click="saveNutritionDashboard()" color="primary" :loading="saving" rounded>Erstellen</v-btn>
            </v-form>
        </v-col>
        <anamnese-bogen-create ref="anamneseBogenRef" @closeAnamnesebogen="closed()"></anamnese-bogen-create>
    </v-col>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'
import anamneseBogenCreate from '../../../components/anamneseBogenCreate'

export default {
    components: {
        userSearch,
        anamneseBogenCreate,
    },
    props: {
        user: {
            required: true,
        },
        allergenFilters: {
            required: true,
        },
        wrongCoach: {},
    },
    data() {
        return {
            saving: false,
            rules: {
                required: [(val) => !!val || `Bitte fülle das Feld aus.`],
            },
            targetPossibilities: ['Ziel 1', 'Ziel 2', 'Ziel 3'],
            data: null,
            newDashboardForm: null,
            anamnese: null,
            noNutritionDashboard: true,
        }
    },
    created() {
        this.loadAnamnese()
    },
    methods: {
        async loadAnamnese() {
            this.loading = true
            this.anamnese = await api.getAnamneseNutritionForUser(this.user._id)
            this.loading = false
        },
        startAnamnese(viewOnly = false) {
            this.$refs.anamneseBogenRef.setVisible(
                this.anamnese?._id ? this.user._id : null || null,
                viewOnly ? false : this.anamnese?._id ? this.anamnese._id : true,
                true,
                this.user._id
            )
        },
        async saveNutritionDashboard() {
            if (this.$refs.nutritionDashboardForm.validate()) {
                try {
                    this.saving = true
                    this.newDashboardForm.user = this.user._id
                    let data = await api.createNutritionDashboard(this.newDashboardForm)
                    this.$toast.success('Ernährungsdashboard wurde erstellt.')
                    this.$emit('saved')
                } catch (e) {
                    this.$toast.error('Beim erstellen ist ein Fehler aufgetreten.')
                }
            } else {
                this.$toast.error('Fülle alle Felder aus.')
            }
        },
        updateCoach(val) {
            this.newDashboardForm.coach = val
        },
        startForm() {
            this.newDashboardForm = {
                coach: null,
                startWeight: this.user.weights.length ? this.user.weights[this.user.weights.length - 1].value : 0,
                targetWeight: 0,
                targets: [],
                allergies: [],
            }
        },
    },
}
</script>
