<template>
    <v-card dark flat class="px-5 mt-5">
        <v-row style="padding-bottom: 150px">
            <v-col v-for="stat of employeeStats" :key="stat._id" cols="12">
                <v-card-title class="font-weight-bold">{{ stat.name }}</v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-card color="primary" class="pa-0">
                            <v-card-title class="white--text text-h6 pb-0">Quadratmeter</v-card-title>
                            <v-card-text class="white--text text-subtitle-1">
                                {{ stat.squareMeter }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card color="primary" class="pa-0">
                            <v-card-title class="white--text text-h6 pb-0">Anzahl Kunden</v-card-title>
                            <v-card-text class="white--text text-subtitle-1">
                                {{ stat.customerAmount }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card @click="percentages = stat.percentage" color="primary" class="pa-0">
                            <v-card-title class="white--text text-h6 pb-0">Stellenprozente</v-card-title>
                            <v-card-text class="white--text text-subtitle-1">
                                {{ totalPercentage(stat.percentage) }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-subtitle class="mt-2 font-weight-bold">
                    Check-Ins pro Tag und Tagesabschnitt (Ø der letzten 4 Wochen)
                </v-card-subtitle>
                <span v-if="stat.checkinStat && stat.checkinStat.checkinsByDay">{{ mapCheckinStats(stat) }}</span>
                <v-simple-table class="mt-2" v-if="stat.checkinStat && stat.checkinStat.checkinsByDay">
                    <thead>
                        <tr>
                            <th class="text-left">Tag</th>
                            <th class="text-left">Morgen</th>
                            <th class="text-left">Nachmittag</th>
                            <th class="text-left">Abend</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(checkinDay, index) of mapCheckinStats(stat)"
                            :key="checkinDay.dayOfWeek + '-' + stat._id"
                        >
                            <td>{{ dayOfWeek[index] }}</td>
                            <td>{{ checkinDay.morningCheckins }}</td>
                            <td>{{ checkinDay.middayCheckins }}</td>
                            <td>{{ checkinDay.eveningCheckins }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog persistent :value="percentages" max-width="500">
            <v-card max-height="500" style="overflow: scroll" v-if="percentages">
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="primary" outlined @click="percentages = null">Schließen</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(value, title) in summarizedPercentages" :key="title">
                            <v-list-item-content>
                                <v-list-item-title>{{ title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ value }} %</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import api from '@/api'

export default {
    components: {},
    data() {
        return {
            percentages: null,
            employeeStats: [],
            dayOfWeek: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
        }
    },
    async created() {
        this.init()
    },
    methods: {
        totalPercentage(stat) {
            return stat.reduce((sum, currentItem) => sum + currentItem.percentage, 0)
        },
        mapCheckinStats(stat) {
            return [...stat.checkinStat.checkinsByDay].sort((a, b) => {
                // Rechne für die Sortierung um, dass Montag (2) der erste Tag wird
                const dayOrder = (day) => (day === 1 ? 8 : day) // Sonntag wird auf 8 gesetzt, damit er am Ende steht
                return dayOrder(a.dayOfWeek) - dayOrder(b.dayOfWeek)
            })
        },
        async init() {
            try {
                let infos = await api.fetchEmployeeStatistics()
                this.employeeStats = infos.employeeStatistic
                console.log(infos)
            } catch (e) {
                console.log(e)
            }
        },
    },
    computed: {
        summarizedPercentages() {
            const summary = {}
            if (!this.percentages) return summary
            this.percentages.forEach((item) => {
                let title = item.title || 'Kein Titel'
                if (summary[title]) {
                    summary[title] += item.percentage
                } else {
                    summary[title] = item.percentage
                }
            })
            return summary
        },
    },
}
</script>
