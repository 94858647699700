<template>
  <v-dialog
    persistent
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card max-width="700px" flat class="d-flex mx-auto">
      <v-row no-gutters v-if="!contract || loading">
        <v-col cols="12" class="d-flex justify-center">
          <v-card-title>Verträge</v-card-title>
        </v-col>
        <v-col cols="12">
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center" cols="12">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-auto d-flex" v-else>
        <v-col
          cols="12"
          class="text-center"
          v-if="
            fieldEntries.membership &&
            fieldEntries.membership.selection &&
            membershipInfos &&
            membershipInfos.title
          "
        >
          <v-chip class="mt-3" color="primary" pill v-on="on">
            {{ membershipInfos.title }} |
            {{
              parseInt(membershipInfos.duration.years) == 1
                ? membershipInfos.duration.years + " Jahr"
                : parseInt(membershipInfos.duration.years) == 2
                ? membershipInfos.duration.years + " Jahre"
                : parseInt(membershipInfos.duration.years) == 3
                ? membershipInfos.duration.years + " Jahre"
                : ""
            }}

            {{
              membershipInfos.duration.months == 1
                ? membershipInfos.duration.months + " Monat"
                : membershipInfos.duration.months > 1
                ? membershipInfos.duration.months + " Monate"
                : ""
            }}
            |

            <span
              class="pl-1"
              v-if="!blackFridaySelectionValidation && !messeContract"
            >
              {{ formatPriceFixedString(getTotalPrice()) }}.-
            </span>
            <span class="pl-1" v-if="messeContract">
              {{ formatPriceFixedString(parseInt(getTotalPriceMesse())) }}.-
            </span>
            <span class="pl-1" v-if="blackFridaySelectionValidation">
              {{
                parseInt(getTotalPrice()) -
                (parseInt(membershipInfos.price) -
                  parseInt(
                    blackFridayDiscountCalculatorManual(
                      parseInt(membershipInfos.price) +
                        parseInt(membershipInfos.signUpFee)
                    )
                  ))
              }}.-
            </span>
          </v-chip>
        </v-col>

        <form-wizard
          ref="formWizard"
          @onNextStep="nextStep"
          @onPreviousStep="previousStep"
          @onSubmit="submitContract"
          @onTempSubmit="submitContract(true)"
          :showTempSubmit="!publicContract"
          :publicContract="publicContract"
          :tabLength="contractFields.length"
          @onCancel="cancel"
          :buttonsLoading="buttonsLoading"
          scope="props"
        >
          <tab-content
            v-for="(field, index) of contractFields"
            :key="index + '-contractFieldIndex'"
            title=""
            class="d-flex flex-grow-1 flex-column"
          >
            <v-row class="pb-0 px-0 flex-grow-0">
              <v-col
                cols="12"
                class="pb-0 px-0 pt-0"
                :class="{
                  'justify-center d-flex flex-column text-center':
                    $vuetify.breakpoint.xs,
                }"
              >
                <v-card-title class="px-0 h3 pt-0">
                  <span v-if="false" style="word-break: break-word">
                    {{
                      contract.formFields.filter((e) => e.inputType === "title")
                        .length > 1
                        ? field.identifier
                        : contract.formFields.find(
                            (e) => e.inputType === "title"
                          ).inputValue
                    }}
                  </span>
                  <span v-else>Mitgliedschaftsvertrag</span>
                </v-card-title>
                <v-card-subtitle class="pb-0 mb-0 px-0 h6">{{
                  field.identifier
                }}</v-card-subtitle>
              </v-col>
              <v-col
                class="my-0 py-0 px-0 infoText"
                v-if="
                  !publicContract && field.salesText && !showExternalPackages
                "
                cols="12"
              >
                <v-card-text style="overflow: visible" class="my-0 pb-5 px-0">
                  <div
                    v-html="
                      field.salesText.replace(
                        /<p><br><\/p>/g,
                        '<p class=\'smallHeight\'><br></p>'
                      )
                    "
                  ></div>
                </v-card-text>
              </v-col>
              <v-col
                class="my-0 py-0 px-0 infoText"
                v-if="
                  publicContract && field.publicText && !showExternalPackages
                "
                cols="12"
              >
                <v-card-text style="overflow: visible" class="my-0 pb-5 px-0">
                  <div
                    v-html="
                      field.publicText.replace(
                        /<p><br><\/p>/g,
                        '<p class=\'smallHeight\'><br></p>'
                      )
                    "
                  ></div>
                </v-card-text>
              </v-col>
              <v-col
                class="my-0 py-0 px-0 infoText"
                v-if="showExternalPackages"
                cols="12"
              >
                <v-card-text class="my-0 pb-0 px-0">
                  Wähle weitere Zusatzpakete aus, die dein Training effektiver
                  und attraktiver machen.
                </v-card-text>
              </v-col>
            </v-row>

            <v-row
              class="my-auto pt-5 pb-0 flex-grow-0"
              v-if="field.inputType === 'category'"
            >
              <v-col
                cols="6"
                class="d-flex flex-row justify-space-between pb-2 pt-0"
                v-for="(category, i) of field.inputValue.filter((e) => {
                  let studioCheck = e.studios.includes(studio._id);
                  if (publicContract && e.title.toString() === 'Goodwill-Abo')
                    studioCheck = false;
                  if (publicContract && e.title.toString() === 'Erneuerung')
                    studioCheck = false;
                  if (
                    publicContract &&
                    e.title.toString() === 'Abonnementübernahme'
                  )
                    studioCheck = false;
                  if (publicContract && e.title.toString() === 'OLMA')
                    studioCheck = false;
                  return studioCheck;
                })"
                :class="{
                  'pr-1 pl-0': i % 2 === 0,
                  'pl-1 pr-0': i % 2 !== 0,
                }"
                :key="i + '-indexCategory'"
              >
                <v-card
                  class="px-5 py-2 optionCard d-flex align-center text-center"
                  outlined
                  fill-height
                  @click="selectCategory(category)"
                  :class="{
                    selectedOption:
                      fieldEntries.category.selection &&
                      fieldEntries.category.selection.title &&
                      fieldEntries.category.selection.title === category.title,
                  }"
                >
                  <v-row no-gutters>
                    <v-col
                      :cols="category.description ? 10 : 12"
                      class="align-center d-flex justify-center"
                    >
                      {{ category.title }}
                    </v-col>
                    <v-col v-if="category.description" cols="2">
                      <v-tooltip class="ml-auto" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click.stop>
                            <v-icon
                              :color="
                                fieldEntries.category.selection &&
                                fieldEntries.category.selection.title &&
                                fieldEntries.category.selection.title ===
                                  category.title
                                  ? 'white'
                                  : ''
                              "
                              v-on="on"
                              v-bind="attrs"
                            >
                              mdi-information-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ category.description }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>

                <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
              </v-col>

              <v-col
                v-if="
                  fieldEntries.category.selection &&
                  fieldEntries.category.selection.notes
                "
                cols="12"
              >
                <v-text-field
                  v-if="!fieldEntries.category.selection.notesSelection"
                  v-model="fieldEntries.category.selection.notesAnswer"
                  :disabled="viewOnly"
                  :label="fieldEntries.category.selection.additionalInfo"
                ></v-text-field>
                <v-select
                  v-else
                  :disabled="viewOnly"
                  v-model="fieldEntries.category.selection.notesAnswer"
                  :label="fieldEntries.category.selection.additionalInfo"
                  :items="fieldEntries.category.selection.notesSelectionOptions"
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  fieldEntries.category.selection &&
                  fieldEntries.category.selection.notes &&
                  fieldEntries.category.selection.notesDescription &&
                  fieldEntries.category.selection.notesDescription.length > 1
                "
                class="py-0"
                cols="12"
              >
                <v-alert type="info">
                  <span>{{
                    fieldEntries.category.selection.notesDescription
                  }}</span>
                </v-alert>
              </v-col>
              <v-col
                v-if="
                  fieldEntries.category.selection &&
                  fieldEntries.category.selection.title ===
                    'Abonnementübernahme'
                "
                cols="12"
              >
                <v-row v-if="!publicContract">
                  <v-col
                    cols="12"
                    v-if="fieldEntries.category.isTakeover"
                    class="px-0 mt-0"
                  >
                    <user-search
                      class="pt-0 mt-0"
                      :value="fieldEntries.category.takeover._id"
                      :disabled="viewOnly"
                      ignoreRights
                      label="Übernahme von"
                      :key="fieldEntries.category.takeover._id"
                      @update="updateTakeover"
                    ></user-search>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.category.takeover._id"
                    class="d-flex justify-center my-0 py-0"
                    cols="12"
                    align="center"
                  >
                    <v-card-text class="text-center">
                      Hiermit bestätige ich, dass ich mein Abonnement übergebe.
                    </v-card-text>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.category.takeover._id"
                    class="d-flex justify-center"
                    cols="12"
                    align="center"
                  >
                    <br />
                    <signature-pad
                      v-if="!viewOnly"
                      :signature-data="fieldEntries.category.takeover.signature"
                      @saveSignature="saveSignatureTakeover"
                      ref="signatureTakeover"
                    ></signature-pad>

                    <v-img
                      v-else
                      max-width="200px"
                      :src="fieldEntries.category.takeover.signature"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="
                  fieldEntries.category.selection &&
                  fieldEntries.category.selection.title !== 'Erneuerung' &&
                  fieldEntries.category.selection.title !==
                    'Abonnementübernahme'
                "
                cols="12"
              >
                <v-row v-if="!publicContract">
                  <v-col cols="12">
                    <v-switch
                      v-model="fieldEntries.category.isAffiliate"
                      class="mt-0 pt-0"
                      :disabled="viewOnly"
                      @change="changeAffiliateStatus"
                      label="wurdest Du weiterempfohlen?"
                    ></v-switch>
                  </v-col>
                  <v-col
                    class="pt-0 mt-0"
                    cols="12"
                    v-if="fieldEntries.category.isAffiliate"
                  >
                    <v-col cols="12" class="px-0 mt-0">
                      <user-search
                        class="pt-0 mt-0"
                        :value="fieldEntries.category.affiliateInfos._id"
                        :disabled="viewOnly"
                        ignoreRights
                        label="Weiterempfehlung von"
                        :key="fieldEntries.category.affiliateInfos._id"
                        @update="updateAffiliate"
                      ></user-search>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="mb-0 pb-0" cols="12">
                    <v-switch
                      v-model="fieldEntries.category.isAffiliate"
                      class="mt-0 pt-0"
                      :disabled="viewOnly"
                      @change="changeAffiliateStatus"
                      label="wurdest Du weiterempfohlen?"
                    ></v-switch>
                  </v-col>
                  <v-col
                    class="pt-0 mt-0"
                    cols="12"
                    v-if="fieldEntries.category.isAffiliate"
                  >
                    <v-col cols="12" class="px-0 pt-0 mt-0">
                      <v-text-field
                        v-model="fieldEntries.category.affiliateInfos.firstName"
                        class="mt-0 pt-0"
                        label="Vorname"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="px-0 pt-0 mt-0">
                      <v-text-field
                        v-model="fieldEntries.category.affiliateInfos.lastName"
                        class="mt-0 pt-0"
                        label="Nachname"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="px-0 pt-0 mt-0">
                      <v-text-field
                        v-model="fieldEntries.category.affiliateInfos.email"
                        class="mt-0 pt-0"
                        label="E-Mail"
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="my-auto flex-grow-0 pb-0"
              v-if="field.inputType === 'customer'"
            >
              <v-col cols="12">
                <v-row v-if="!publicContract">
                  <v-col
                    v-show="!fieldEntries.customer.infos"
                    cols="12"
                    class="px-0 mt-5"
                  >
                    <user-search
                      :value="fieldEntries.customer.contractCreator"
                      :disabled="viewOnly"
                      ignoreRights
                      :key="fieldEntries.customer.contractCreator"
                      instructor
                      label="Visum (Vertragersteller)"
                      @update="updateCreator"
                    ></user-search>
                  </v-col>
                  <v-col
                    v-show="!fieldEntries.customer.infos"
                    cols="12"
                    class="px-0 mt-5"
                  >
                    <user-search
                      :value="fieldEntries.customer.selection"
                      :disabled="
                        viewOnly ||
                        fieldEntries.customer.contractCreator === null
                      "
                      ignoreRights
                      label="Kunde"
                      :key="fieldEntries.customer.selection"
                      @update="updateCustomer"
                    ></user-search>
                  </v-col>
                  <v-col
                    v-if="!viewOnly && !fieldEntries.customer.infos"
                    cols="12"
                  >
                    <v-btn
                      color="primary"
                      :disabled="fieldEntries.customer.contractCreator === null"
                      @click="showRegisterDialog = true"
                    >
                      Neuen Account erstellen
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <v-card-title
                      v-if="fieldEntries.customer.contractCreator === null"
                      style="word-break: keep-all"
                      class="justify-center"
                    >
                      Melde dich mit deinem Roody Account an oder erstelle einen
                      neuen Account um fortzufahren
                    </v-card-title>
                  </v-col>
                  <v-col
                    class="d-flex justify-center"
                    v-if="fieldEntries.customer.contractCreator === null"
                    cols="12"
                  >
                    <v-btn color="primary" @click="showLoginDialog = true"
                      >Anmelden</v-btn
                    >
                  </v-col>
                  <v-col
                    class="d-flex justify-center"
                    v-if="fieldEntries.customer.contractCreator === null"
                    cols="12"
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="showRegisterDialog = true"
                    >
                      Neuen Account erstellen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row v-if="fieldEntries.customer.infos">
                  <v-col
                    v-if="!publicContract"
                    cols="12"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      class="mx-0"
                      v-if="!viewOnly"
                      color="primary"
                      @click="resetCustomer"
                    >
                      Kunde ändern
                    </v-btn>
                  </v-col>
                  <v-col v-else cols="12" class="d-flex justify-center">
                    <v-btn
                      class="mx-0"
                      v-if="!viewOnly"
                      color="primary"
                      @click="resetCustomerPublicContract"
                    >
                      Kunde ändern
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="fieldEntries.customer.infos">
                  <v-col cols="12">
                    <v-select
                      :disabled="viewOnly"
                      label="Anrede"
                      :items="[
                        { name: 'Herr', value: 'm' },
                        { name: 'Frau', value: 'w' },
                      ]"
                      item-value="value"
                      item-text="name"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.gender"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      label="Vorname"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      label="Nachname"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      @change="updateCustomerBirthday"
                      label="Geburtstag"
                      type="date"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.birthday"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      label="Telefon"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :disabled="viewOnly"
                      label="E-Mail"
                      type="email"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      :disabled="viewOnly"
                      label="Strasse"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.street"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      type="number"
                      label="Postleitzahl"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.zip"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="viewOnly"
                      label="Stadt"
                      class="my-0 py-0"
                      v-model="fieldEntries.customer.infos.city"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              class="my-auto flex-grow-0 pb-0 px-0"
              v-if="field.inputType === 'membership'"
            >
              <v-col
                cols="6"
                class="d-flex flex-row justify-space-between pb-2 pt-0"
                v-for="(membership, i) of Object.keys(membershipFields)
                  .sort((a, b) => {
                    if (a.toString() === 'Kombi Card') return -1;
                    if (b.toString() === 'Kombi Card') return 1;
                    return 0;
                  })
                  .filter((e) => {
                    if (e.toLowerCase().includes('messe')) {
                      if (publicContract) {
                        return false;
                      }
                      if (
                        userService.hasRight('c_showBlackFridayOffer') ||
                        $moment().isBetween('2024-11-13', '2024-11-18')
                      ) {
                        return true;
                      }
                      return false;
                    }
                    if (blackFridayValidation) {
                      return true;
                    } else {
                      if (e.toLowerCase().includes('black')) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  })"
                :class="{
                  'pr-1 pl-0': i % 2 === 0,
                  'pl-1 pr-0': i % 2 !== 0,
                }"
                :key="membership + '-indexMS'"
              >
                <v-card
                  class="px-5 py-2 optionCard d-flex align-center justify-center text-center"
                  outlined
                  @click="
                    () => {
                      if (
                        fieldEntries.membership.selection &&
                        fieldEntries.membership.selection.title &&
                        membershipFields[membership]
                          .map((m) => m.title)
                          .includes(fieldEntries.membership.selection.title)
                      )
                        unselectOption('membership');
                      else selectMembership(membership);
                    }
                  "
                  :class="{
                    messeAngebotChip: membership
                      .toLowerCase()
                      .includes('messe'),
                    isSale: checkIfSale(membershipFields[membership]),
                    blackFridayOffer:
                      membership.toLowerCase().includes('black') &&
                      blackFridayValidation,
                    premiumOffer: membership.toLowerCase().includes('premium'),
                    selectedOption:
                      fieldEntries.membership.selection &&
                      fieldEntries.membership.selection.title &&
                      membershipFields[membership]
                        .map((m) => m.title)
                        .includes(fieldEntries.membership.selection.title),
                  }"
                >
                  <div
                    v-if="checkIfSale(membershipFields[membership])"
                    class="special-offer-chip"
                  >
                    Angebot
                  </div>
                  <!-- Chip für Special Offer, der nur angezeigt wird, wenn blackFridayOffer als Klasse vorhanden ist -->
                  <div
                    v-if="
                      membership.toLowerCase().includes('black') &&
                      blackFridayValidation
                    "
                    class="special-offer-chip"
                  >
                    Special Offer
                  </div>
                  <div
                    v-if="membership.toLowerCase().includes('messe')"
                    class="special-offer-chip-messe"
                  >
                    Messe Angebot
                  </div>
                  <div
                    v-if="false && membership.toLowerCase().includes('premium')"
                    class="special-offer-premium-chip"
                  >
                    👑
                  </div>
                  {{ membership }}
                </v-card>

                <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
              </v-col>

              <v-dialog
                max-width="500px"
                class="contractCreator__dialog"
                background="white"
                :value="showMembershipCategoryPicker"
                persistent
              >
                <v-card elevation="0" max-width="500px" class="mx-auto editor">
                  <v-card-title
                    style="word-break: break-word"
                    class="contractCreator__dialog-title font-weight-bold mb-5"
                  >
                    Mitgliedschaft auswählen
                  </v-card-title>
                  <v-row
                    no-gutters
                    class="px-5"
                    v-if="
                      showMembershipCategoryPicker &&
                      membershipFields[membershipCategory].length > 1
                    "
                  >
                    <v-col cols="12" class="mb-5">
                      <v-row
                        no-gutters
                        v-for="(m, y) of membershipFields[membershipCategory]"
                        :key="y + '-indexOfMembership'"
                      >
                        <v-col
                          class="d-flex justify-center align-center"
                          cols="2"
                        >
                          <v-btn
                            :disabled="viewOnly"
                            large
                            icon
                            @click="selectOption('membership', m)"
                          >
                            <v-icon color="primary"
                              >mdi-plus-circle-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                        <v-col cols="10" class="d-flex align-center">
                          <v-row no-gutters>
                            <v-col cols="12">
                              {{ m.title }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-card-actions
                    class="contractCreator__dialog-actions d-flex justify-center"
                  >
                    <v-btn
                      :disabled="viewOnly"
                      color="grey darken-1"
                      text
                      @click="cancelMembershipCategorySelection()"
                    >
                      Abbrechen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                max-width="500px"
                class="contractCreator__dialog"
                background="white"
                :value="showDurationPicker"
                persistent
              >
                <v-card
                  v-if="showDurationPicker"
                  elevation="0"
                  max-width="500px"
                  class="mx-auto editor"
                >
                  <v-card-title
                    style="word-break: break-word"
                    class="contractCreator__dialog-title font-weight-bold mb-5"
                  >
                    {{ showDurationPicker.title }}
                  </v-card-title>
                  <v-col
                    v-if="showDurationPicker.description"
                    class="pt-0"
                    cols="12"
                  >
                    <v-alert type="info" class="align-center d-flex">
                      <span v-html="showDurationPicker.description"></span>
                    </v-alert>
                  </v-col>
                  <v-row
                    no-gutters
                    v-if="!showDurationPicker.flexActive"
                    class="px-5"
                  >
                    <v-col
                      v-for="(duration, i) of showDurationPicker.studioSettings[
                        studio._id
                      ].durations"
                      :key="i + '-durationIndex'"
                      cols="12"
                      class="mb-5"
                    >
                      <v-row no-gutters>
                        <v-col
                          class="d-flex justify-center align-center"
                          cols="2"
                        >
                          <v-btn
                            :disabled="viewOnly"
                            large
                            icon
                            @click="
                              selectDuration(
                                showDurationPicker &&
                                  showDurationPicker.optionType
                                  ? showDurationPicker.optionType
                                  : 'membership',
                                duration
                              )
                            "
                          >
                            <v-icon color="primary"
                              >mdi-plus-circle-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="10"
                          v-if="
                            !showDurationPicker.title
                              .toLowerCase()
                              .includes('messe')
                          "
                        >
                          <span v-if="parseInt(duration.years) !== 0">
                            {{ duration.years }}
                            {{
                              parseInt(duration.years) === 1 ? "Jahr" : "Jahre"
                            }}
                          </span>
                          <span v-else-if="parseInt(duration.months) !== 0">
                            {{ duration.months }}
                            {{
                              parseInt(duration.months) === 1
                                ? "Monat"
                                : "Monate"
                            }}
                          </span>
                          <span v-else-if="parseInt(duration.days) !== 0">
                            {{ duration.days }}
                            {{ parseInt(duration.days) === 1 ? "Tag" : "Tage" }}
                          </span>
                          <span v-if="duration.saleActive">
                            CHF
                            {{ duration.salePrice }}
                            .- anstatt CHF
                            <span v-if="!noSignUpFeeCategory"
                              >{{ duration.price }}.-</span
                            >
                            <span v-else>
                              {{
                                parseFloat(duration.price) -
                                parseFloat(duration.signUpFee)
                              }}.-
                            </span>
                            <br />
                          </span>
                          <span v-else-if="!blackFridaySelectionValidation">
                            CHF {{ formatPriceFixedString(duration.price) }}.-
                            <br />
                          </span>
                          <span v-else>
                            CHF
                            {{
                              blackFridayDiscountCalculatorManual(
                                parseInt(duration.price) +
                                  parseInt(duration.signUpFee)
                              )
                            }}
                            .- anstatt CHF
                            {{
                              formatPriceFixedString(
                                parseInt(duration.price) +
                                  parseInt(duration.signUpFee)
                              )
                            }}.-
                            <br />
                          </span>

                          <span
                            v-if="
                              duration.signUpFee !== null &&
                              parseInt(duration.signUpFee) !== 0 &&
                              !noSignUpFeeCategory
                            "
                          >
                            <span
                              v-if="
                                !preSale &&
                                !forceGiftSignupFee &&
                                !blackFridaySelectionValidation &&
                                !duration.saleActive &&
                                !duration.saleGiftSignUpFee
                              "
                            >
                              Aktivierungs-Tarif CHF {{ duration.signUpFee }}.-
                            </span>
                            <span v-else-if="!noSignUpFeeCategory">
                              <span>
                                Aktivierungs-Tarif von CHF
                                {{ duration.signUpFee }} geschenkt
                              </span>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="10" v-else>
                          <span v-if="parseInt(duration.years) !== 0">
                            {{ duration.years }}
                            {{
                              parseInt(duration.years) === 1 ? "Jahr" : "Jahre"
                            }}
                          </span>
                          <span v-else-if="parseInt(duration.months) !== 0">
                            {{ duration.months }}
                            {{
                              parseInt(duration.months) === 1
                                ? "Monat"
                                : "Monate"
                            }}
                          </span>
                          <span v-else-if="parseInt(duration.days) !== 0">
                            {{ duration.days }}
                            {{ parseInt(duration.days) === 1 ? "Tag" : "Tage" }}
                          </span>
                          <span>
                            CHF
                            {{
                              messeCalc(
                                showDurationPicker.title.toLowerCase(),
                                parseInt(duration.years)
                              )
                            }}.- anstatt CHF
                            {{
                              formatPriceFixedString(
                                parseInt(duration.price) +
                                  parseInt(duration.signUpFee)
                              )
                            }}.-
                            <br />
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else class="px-5">
                    <v-col
                      v-if="
                        showDurationPicker.flexSelection.toString() === 'perDay'
                      "
                      cols="12"
                      class="mb-3"
                    >
                      <v-text-field
                        v-if="
                          showDurationPicker.flexLimits &&
                          'min' in showDurationPicker.flexLimits &&
                          'max' in showDurationPicker.flexLimits
                        "
                        :max="showDurationPicker.flexLimits.max"
                        :min="showDurationPicker.flexLimits.min"
                        type="number"
                        v-model="membershipFlexSelection"
                        label="Anzahl Tage auswählen"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        type="number"
                        v-model="membershipFlexSelection"
                        label="Anzahl Tage auswählen"
                      ></v-text-field>
                      <v-card-text
                        v-if="membershipFlexSelection"
                        class="px-0 mx-0"
                      >
                        Mitgliedschaftspreis: CHF
                        {{
                          formatPriceFixedString(
                            showDurationPicker.studioSettings[studio._id].flex
                              .prices.perDay.price
                          ) * membershipFlexSelection
                        }}.-
                      </v-card-text>
                      <v-card-text v-else class="px-0 mx-0">
                        Mitgliedschaftspreis: CHF
                        {{
                          formatPriceFixedString(
                            showDurationPicker.studioSettings[studio._id].flex
                              .prices.perDay.price
                          )
                        }}.- pro Tag
                      </v-card-text>
                    </v-col>
                    <v-col v-else cols="12" class="mb-3">
                      <v-select
                        v-model="membershipFlexSelection"
                        :items="
                          showDurationPicker.flexLimits.options.map((e) => {
                            return { name: e + ' Monate', value: e };
                          })
                        "
                        item-text="name"
                        item-value="value"
                        label="Anzahl Monate auswählen"
                      ></v-select>
                      <v-card-text
                        v-if="membershipFlexSelection"
                        class="px-0 mx-0"
                      >
                        Mitgliedschaftspreis: CHF
                        {{
                          formatPriceFixedString(
                            formatPriceFixed(
                              showDurationPicker.studioSettings[studio._id].flex
                                .prices.perMonth.price
                            ) * membershipFlexSelection
                          )
                        }}.-
                      </v-card-text>
                      <v-card-text v-else class="px-0 mx-0">
                        Mitgliedschaftspreis: CHF
                        {{
                          formatPriceFixedString(
                            showDurationPicker.studioSettings[studio._id].flex
                              .prices.perMonth.price
                          )
                        }}.- pro Monat
                      </v-card-text>
                    </v-col>
                  </v-row>

                  <v-card-actions
                    class="contractCreator__dialog-actions d-flex justify-center"
                  >
                    <v-btn
                      :disabled="viewOnly"
                      color="grey darken-1"
                      text
                      @click="
                        unselectOption(
                          showDurationPicker && showDurationPicker.optionType
                            ? showDurationPicker.optionType
                            : null
                        )
                      "
                    >
                      Abbrechen
                    </v-btn>
                    <v-spacer v-if="showDurationPicker.flexActive"></v-spacer>
                    <v-btn
                      :disabled="viewOnly"
                      v-if="showDurationPicker.flexActive"
                      color="grey darken-1"
                      text
                      @click="
                        selectDurationFlex(
                          showDurationPicker && showDurationPicker.optionType
                            ? showDurationPicker.optionType
                            : null
                        )
                      "
                    >
                      Auswählen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <v-row
              class="my-auto flex-grow-0 pb-0"
              v-if="
                field.inputType === 'packages' &&
                fieldEntries.membership.selection &&
                fieldEntries.membership.selectedDuration
              "
            >
              <v-col
                class="mt-5 px-0 mb-0 pb-0"
                cols="12"
                v-if="
                  forceGiftSignupFee &&
                  !noSignUpFeeCategory &&
                  membershipInfos.signUpFee !== null &&
                  parseInt(membershipInfos.signUpFee) !== 0 &&
                  !preSale
                "
              >
                <v-alert type="info" color="primary" class="white--text mb-0">
                  Du erhälst den Aktivierungs-Tarif geschenkt
                </v-alert>
              </v-col>
              <v-col
                v-if="
                  !noSignUpFeeCategory &&
                  membershipInfos.signUpFee !== null &&
                  parseInt(membershipInfos.signUpFee) !== 0 &&
                  contract &&
                  contract.promotions &&
                  contract.promotions.giftSignUpFeeIfPackagesPriceHit &&
                  !preSale &&
                  !forceGiftSignupFee &&
                  !blackFridaySelectionValidation &&
                  !isTakeoverContract
                "
                class="mt-5 px-0 mb-0 pb-0"
                cols="12"
              >
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-if="fieldEntries.packages.length === 0"
                >
                  Erhalte den Aktivierungs-Tarif CHF
                  {{ membershipInfos.signUpFee }}.- geschenkt, indem du
                  Zusatzpakete im Wert von mindestens CHF
                  {{
                    contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount
                  }}.- auswählst
                </v-alert>
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-else-if="
                    getPackagesTotal() <
                    parseFloat(
                      contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount
                    )
                  "
                >
                  Füge noch Zusatzpakete im Wert von mindestens CHF
                  {{
                    parseFloat(
                      contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount
                    ) - getPackagesTotal()
                  }}.- hinzu und der Aktivierungs-Tarif ist ein Geschenk für
                  dich!
                </v-alert>
                <v-alert
                  v-else
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                >
                  Du erhälst den Aktivierungs-Tarif geschenkt
                </v-alert>
              </v-col>
              <v-col
                v-if="
                  !noSignUpFeeCategory &&
                  membershipInfos.signUpFee !== null &&
                  membershipInfos.signUpFee !== 0 &&
                  contract &&
                  contract.promotions &&
                  contract.promotions.giftSignUpFeeIfAdditionalPackages &&
                  !isTakeoverContract
                "
                class="px-0 mt-5 mb-0 pb-0"
                cols="12"
              >
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-if="fieldEntries.packages.length === 0"
                >
                  Erhalte den Aktivierungs-Tarif geschenkt, indem du mindestens
                  {{
                    contract.promotions.giftSignUpFeeIfAdditionalPackagesCount
                  }}
                  Zusatzpakete auswählst
                </v-alert>
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-else-if="
                    fieldEntries.packages.length <
                    contract.promotions.giftSignUpFeeIfAdditionalPackagesCount
                  "
                >
                  Nur noch
                  {{
                    contract.promotions.giftSignUpFeeIfAdditionalPackagesCount -
                    fieldEntries.packages.length
                  }}
                  Zusatzpaket{{
                    contract.promotions.giftSignUpFeeIfAdditionalPackagesCount -
                      fieldEntries.packages.length >
                    1
                      ? "e"
                      : ""
                  }}
                  und der Aktivierungs-Tarif ist ein Geschenk für dich!
                </v-alert>
                <v-alert
                  v-else
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                >
                  Du erhälst den Aktivierungs-Tarif geschenkt
                </v-alert>
              </v-col>
              <v-col
                v-if="showExternalPackages"
                cols="12"
                class="px-0 mx-0 mb-0 pb-0 mt-5"
              >
                <v-alert type="info" class="mx-0 mb-0"
                  >Zusatzpakete aus anderen Studios</v-alert
                >
              </v-col>

              <v-col
                v-if="
                  contract &&
                  contract.promotions &&
                  contract.promotions.giftPackageAdditionalPackages &&
                  !isTakeoverContract
                "
                cols="12"
                class="px-0 mt-0 mb-0 pb-0"
              >
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-if="fieldEntries.packages.length === 0"
                >
                  Erhalte ein Zusatzpaket im ersten Jahr geschenkt, wenn Du
                  mindestens
                  {{
                    parseInt(
                      contract.promotions.giftPackageAdditionalPackagesCount
                    ) - 1
                  }}
                  Zusatzpakete auswählst
                </v-alert>
                <v-alert
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                  v-else-if="
                    fieldEntries.packages.length <
                      contract.promotions.giftPackageAdditionalPackagesCount &&
                    !(
                      parseInt(
                        contract.promotions.giftPackageAdditionalPackagesCount
                      ) -
                        1 -
                        fieldEntries.packages.length ===
                      0
                    )
                  "
                >
                  Nur noch
                  {{
                    parseInt(
                      contract.promotions.giftPackageAdditionalPackagesCount
                    ) -
                    1 -
                    fieldEntries.packages.length
                  }}
                  Zusatzpaket{{
                    parseInt(
                      contract.promotions.giftPackageAdditionalPackagesCount
                    ) -
                      1 -
                      fieldEntries.packages.length >
                    1
                      ? "e"
                      : ""
                  }}
                  und Du erhälst ein Zusatzpaket im ersten Jahr geschenkt!
                </v-alert>
                <v-alert
                  v-else-if="
                    parseInt(
                      contract.promotions.giftPackageAdditionalPackagesCount
                    ) -
                      1 -
                      fieldEntries.packages.length ===
                    0
                  "
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                >
                  Wähle ein weiteres Zusatzpaket aus und du erhältst es im
                  ersten Jahr geschenkt!
                </v-alert>
                <v-alert
                  v-else
                  type="info"
                  color="primary"
                  class="white--text mb-0"
                >
                  Du erhälst ein Zusatzpaket im ersten Jahr geschenkt
                </v-alert>
              </v-col>

              <v-col cols="12" class="mt-4 mx-0 px-0">
                <v-row class="my-auto flex-grow-0 pb-0 px-0" no-gutters>
                  <v-col
                    cols="6"
                    class="d-flex flex-row justify-space-between pb-2"
                    :class="{
                      'pr-1': i % 2 === 0,
                      'pl-1': i % 2 !== 0,
                    }"
                    v-for="(packag, i) of field.inputValue
                      .filter(
                        (e) =>
                          e.studios.includes(studio._id) &&
                          studio._id in e.studioSettings &&
                          e.studioSettings[studio._id].memberships &&
                          e.studioSettings[studio._id].memberships.find(
                            (m) =>
                              m.title.toString() ===
                              fieldEntries.membership.selection.title.toString()
                          )
                      )
                      .filter((e) => {
                        if (showExternalPackages) {
                          if (
                            isFlexiContractCheck &&
                            (studio.code === 'pfungen' ||
                              studio.code === 'winterthur')
                          ) {
                            return (
                              e.isExternalPackage === true &&
                              (e.title.includes('Milon') ||
                                e.title.includes('Tücherservice'))
                            );
                          } else {
                            return e.isExternalPackage === true;
                          }
                        } else {
                          return !e.isExternalPackage;
                        }
                      })
                      .filter((e) => {
                        if (
                          fieldEntries.customer &&
                          fieldEntries.customer.isUnderage &&
                          e.hideForMinors
                        )
                          return false;
                        return true;
                      })"
                    :key="i + '-indexPackages'"
                  >
                    <v-card
                      class="optionCard align-center justify-center d-flex fill-height"
                      outlined
                      :class="{
                        selectedOption: fieldEntries.packages.find(
                          (p) => p.title === packag.title
                        ),
                      }"
                      @click="
                        () => {
                          if (disableEinsteinHydrojet(packag.title)) {
                            alertThis('Nur mit Wellness/Spa Plus');
                          } else {
                            if (
                              fieldEntries.packages.find(
                                (p) => p.title === packag.title
                              )
                            )
                              removePackage(packag);
                            else addPackage(packag);
                          }
                        }
                      "
                    >
                      <v-row no-gutters>
                        <v-col
                          v-if="
                            packag.studioSettings &&
                            packag.studioSettings[studio._id] &&
                            packag.studioSettings[studio._id]
                              .showAdditionalInfoPrint &&
                            packag.studioSettings[studio._id]
                              .showAdditionalInfoPrintText
                          "
                          cols="12"
                          class="mt-2"
                        >
                          <v-alert
                            class="mb-0 py-1"
                            type="info"
                            color="primary"
                          >
                            {{
                              packag.studioSettings[studio._id]
                                .showAdditionalInfoPrintText
                            }}
                          </v-alert>
                        </v-col>
                        <v-col
                          :cols="
                            packag.studioSettings &&
                            packag.studioSettings[studio._id] &&
                            packag.studioSettings[studio._id]
                              .showAdditionalInfoPrint &&
                            packag.studioSettings[studio._id]
                              .showAdditionalInfoPrintText
                              ? '6'
                              : '12'
                          "
                        >
                          <v-row
                            style="position: relative; word-break: normal"
                            no-gutters
                          >
                            <v-col
                              :cols="
                                (showExternalPackages &&
                                  packag.packageStudios) ||
                                packag.description
                                  ? 9
                                  : 12
                              "
                              class="pl-3 py-2 d-flex align-center"
                            >
                              <div
                                class="option d-flex flex-column"
                                style="
                                  max-width: 100%;
                                  font-size: 15px;
                                  white-space: break-spaces;
                                  word-break: normal;
                                "
                              >
                                <span
                                  v-if="
                                    'amount' in packag &&
                                    packag.amountSelectable &&
                                    packag.amount !== 0 &&
                                    packag.amount !== null
                                  "
                                >
                                  {{ packag.amount }}x {{ packag.title }}
                                </span>
                                <span v-else>{{ packag.title }}</span>

                                <v-icon
                                  class="align-self-start"
                                  color="primary"
                                  v-if="getPackageInfos(packag).gifted"
                                >
                                  mdi-gift
                                </v-icon>
                                <span v-else style="font-size: 0.8rem">
                                  <span
                                    v-if="
                                      !(
                                        ostWellnessSpaPlusRabatt &&
                                        packag.title
                                          .toLowerCase()
                                          .includes('spa plus')
                                      )
                                    "
                                  >
                                    <span v-if="!messeContract">
                                      {{ getPackageInfos(packag).price }}.-
                                      CHF</span
                                    >
                                    <span v-if="messeContract">
                                      {{
                                        parseInt(
                                          getPackageInfos(packag).price
                                        ) -
                                        parseInt(
                                          getPackageInfos(packag).price
                                        ) /
                                          5
                                      }}.- CHF anstatt
                                      {{ getPackageInfos(packag).price }}.-
                                      CHF</span
                                    >
                                  </span>
                                  <span v-else>
                                    {{
                                      parseInt(getPackageInfos(packag).price) -
                                      100
                                    }}.- CHF anstatt
                                    {{ getPackageInfos(packag).price }}.- CHF
                                  </span>
                                  <!--- .pricePerWeek pro Woche -->
                                </span>
                              </div>

                              <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                            </v-col>
                            <v-col
                              cols="3"
                              v-if="
                                (showExternalPackages &&
                                  packag.packageStudios) ||
                                packag.description
                              "
                              class="d-flex px-0 flex-column justify-center align-end"
                            >
                              <v-dialog
                                v-if="packag.description"
                                transition="dialog-bottom-transition"
                                max-width="400"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click.stop icon>
                                    <v-icon
                                      :color="
                                        fieldEntries.packages.find(
                                          (p) => p.title === packag.title
                                        )
                                          ? 'white'
                                          : ''
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      mdi-information-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <template v-slot:default="dialog">
                                  <v-card>
                                    <v-toolbar color="primary" dark>
                                      {{ packag.title }}
                                    </v-toolbar>
                                    <v-row no-gutters class="gallery-section">
                                      <v-col cols="12">
                                        <div
                                          ref="scrollContainer"
                                          @scroll="updateActiveIndexPackages"
                                          style="
                                            overflow-x: scroll;
                                            white-space: nowrap;
                                            scroll-snap-type: x mandatory;
                                          "
                                        >
                                          <v-card
                                            elevation="0"
                                            transparent
                                            v-for="(
                                              image, index
                                            ) in packag.gallery"
                                            :key="index"
                                            ref="cards"
                                            style="
                                              display: inline-block;
                                              scroll-snap-align: center;
                                              width: 100%;
                                            "
                                          >
                                            <v-img
                                              contain
                                              max-height="200px"
                                              :src="'/api/' + image.src"
                                              alt="Uploaded Image"
                                            ></v-img>
                                          </v-card>
                                        </div>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        style="text-align: center"
                                      >
                                        <span
                                          v-for="(
                                            image, index
                                          ) in packag.gallery"
                                          :key="'dot-' + index"
                                          @click="scrollToIndexPackages(index)"
                                          :class="[
                                            'dot',
                                            {
                                              active:
                                                activeIndexPackages === index,
                                            },
                                          ]"
                                        ></span>
                                      </v-col>
                                    </v-row>
                                    <v-card-text class="mt-5 pb-0">
                                      {{ packag.description }}
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                      <v-btn text @click="dialog.value = false">
                                        Schliessen
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </template>
                              </v-dialog>

                              <v-tooltip
                                bottom
                                v-if="false && packag.description"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click.stop icon>
                                    <v-icon
                                      :color="
                                        fieldEntries.packages.find(
                                          (p) => p.title === packag.title
                                        )
                                          ? 'white'
                                          : ''
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      mdi-information-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ packag.description }}</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                v-if="
                                  showExternalPackages && packag.packageStudios
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click.stop icon>
                                    <v-icon
                                      :color="
                                        fieldEntries.packages.find(
                                          (p) => p.title === packag.title
                                        )
                                          ? 'white'
                                          : ''
                                      "
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      mdi-map-marker-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{
                                  packag.packageStudios.join(", ")
                                }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="
                      !publicContract &&
                      !isFlexiContractCheck &&
                      !showExternalPackages &&
                      studio.code !== 'rikon'
                    "
                    class="d-flex flex-row justify-space-between pb-2"
                    :class="{
                      'pr-1':
                        field.inputValue
                          .filter(
                            (e) =>
                              e.studios.includes(studio._id) &&
                              studio._id in e.studioSettings &&
                              e.studioSettings[studio._id].memberships &&
                              e.studioSettings[studio._id].memberships.find(
                                (m) =>
                                  m.title.toString() ===
                                  fieldEntries.membership.selection.title.toString()
                              )
                          )
                          .filter((e) => {
                            if (showExternalPackages) {
                              return e.isExternalPackage === true;
                            } else {
                              return !e.isExternalPackage;
                            }
                          })
                          .filter((e) => {
                            if (
                              fieldEntries.customer &&
                              fieldEntries.customer.isUnderage &&
                              e.hideForMinors
                            )
                              return false;
                            return true;
                          }).length %
                          2 ===
                        0,
                      'pl-1':
                        field.inputValue
                          .filter(
                            (e) =>
                              e.studios.includes(studio._id) &&
                              studio._id in e.studioSettings &&
                              e.studioSettings[studio._id].memberships &&
                              e.studioSettings[studio._id].memberships.find(
                                (m) =>
                                  m.title.toString() ===
                                  fieldEntries.membership.selection.title.toString()
                              )
                          )
                          .filter((e) => {
                            if (showExternalPackages) {
                              return e.isExternalPackage === true;
                            } else {
                              return !e.isExternalPackage;
                            }
                          })
                          .filter((e) => {
                            if (
                              fieldEntries.customer &&
                              fieldEntries.customer.isUnderage &&
                              e.hideForMinors
                            )
                              return false;
                            return true;
                          }).length %
                          2 !==
                        0,
                    }"
                    cols="6"
                  >
                    <v-card
                      class="optionCard align-center justify-center d-flex fill-height"
                      outlined
                      :class="{
                        selectedOption:
                          fieldEntries.hasPurchasedPersonalTraining,
                      }"
                      @click="togglePersonalTraining"
                    >
                      <v-row no-gutters>
                        <v-col class="pl-3" cols="12">
                          <div
                            class="option d-flex flex-column"
                            style="
                              max-width: 100%;
                              font-size: 15px;
                              white-space: break-spaces;
                              word-break: normal;
                            "
                          >
                            <span>Personal Training</span>
                            <span style="font-size: 0.8rem; padding-left: 6px">
                              <span>120.- CHF</span>
                              <!--- .pricePerWeek pro Woche -->
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col
                    v-if="false && !showExternalPackages"
                    class="d-flex flex-row justify-space-between pb-2"
                    :class="{
                      'pr-1':
                        field.inputValue
                          .filter(
                            (e) =>
                              e.studios.includes(studio._id) &&
                              studio._id in e.studioSettings &&
                              e.studioSettings[studio._id].memberships &&
                              e.studioSettings[studio._id].memberships.find(
                                (m) =>
                                  m.title.toString() ===
                                  fieldEntries.membership.selection.title.toString()
                              )
                          )
                          .filter((e) => {
                            if (showExternalPackages) {
                              return e.isExternalPackage === true;
                            } else {
                              return !e.isExternalPackage;
                            }
                          })
                          .filter((e) => {
                            if (
                              fieldEntries.customer &&
                              fieldEntries.customer.isUnderage &&
                              e.hideForMinors
                            )
                              return false;
                            return true;
                          }).length %
                          2 ===
                        0,
                      'pl-1':
                        field.inputValue
                          .filter(
                            (e) =>
                              e.studios.includes(studio._id) &&
                              studio._id in e.studioSettings &&
                              e.studioSettings[studio._id].memberships &&
                              e.studioSettings[studio._id].memberships.find(
                                (m) =>
                                  m.title.toString() ===
                                  fieldEntries.membership.selection.title.toString()
                              )
                          )
                          .filter((e) => {
                            if (showExternalPackages) {
                              return e.isExternalPackage === true;
                            } else {
                              return !e.isExternalPackage;
                            }
                          })
                          .filter((e) => {
                            if (
                              fieldEntries.customer &&
                              fieldEntries.customer.isUnderage &&
                              e.hideForMinors
                            )
                              return false;
                            return true;
                          }).length %
                          2 !==
                        0,
                    }"
                    cols="6"
                  >
                    <v-card
                      class="optionCard align-center justify-center d-flex fill-height"
                      outlined
                      :class="{
                        selectedOption:
                          fieldEntries.hasPurchasedNutritionPackage,
                      }"
                    >
                      <v-row no-gutters>
                        <v-col class="pl-3" cols="12">
                          <div
                            class="option d-flex flex-column"
                            style="
                              max-width: 100%;
                              font-size: 15px;
                              white-space: break-spaces;
                              word-break: normal;
                            "
                          >
                            <span>Ernährung</span>
                            <span style="font-size: 0.8rem; padding-left: 6px">
                              <span>ab 440 CHF</span>
                              <!--- .pricePerWeek pro Woche -->
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-dialog
              max-width="500px"
              class="contractCreator__dialog"
              background="white"
              :value="packageAmountSelectionDialog !== null"
              persistent
            >
              <v-card elevation="0" max-width="500px" class="mx-auto editor">
                <v-card-title
                  style="word-break: break-word"
                  class="contractCreator__dialog-title font-weight-bold"
                >
                  Anzahl auswählen
                </v-card-title>
                <v-row no-gutters>
                  <v-col cols="8">
                    <v-row no-gutters>
                      <v-col cols="12"
                        ><v-card-subtitle>Anzahl Kinder</v-card-subtitle></v-col
                      >
                    </v-row>
                  </v-col>
                  <v-col cols="4" class="d-flex">
                    <v-row
                      class="d-flex justify-center align-center"
                      no-gutters
                      v-if="
                        packageAmountSelectionDialog &&
                        packageAmountSelectionDialog.amount
                      "
                    >
                      <v-col cols="4" class="d-flex justify-center">
                        <v-btn
                          @click="changePackageAmount(-1)"
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-center">
                        {{ packageAmountSelectionDialog.amount }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-center">
                        <v-btn
                          @click="changePackageAmount(1)"
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-card-actions
                  class="contractCreator__dialog-actions d-flex justify-center"
                >
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="packageAmountSelectionDialog = null"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="packageAmountSelectionDialog = null"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              max-width="500px"
              class="contractCreator__dialog"
              background="white"
              :value="packageConfirmationDialog !== null"
              persistent
            >
              <v-card elevation="0" max-width="500px" class="mx-auto editor">
                <v-card-title
                  style="word-break: break-word"
                  class="contractCreator__dialog-title font-weight-bold"
                >
                  Bestätigung
                </v-card-title>
                <v-row v-if="packageConfirmationDialog" no-gutters>
                  <v-col cols="12">
                    <v-card-text
                      v-if="
                        packageConfirmationDialog.studioSettings[studio._id]
                          .additionalConfirmationText
                      "
                    >
                      {{
                        packageConfirmationDialog.studioSettings[studio._id]
                          .additionalConfirmationText
                      }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions
                  class="contractCreator__dialog-actions d-flex justify-center"
                >
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="removePackage(packageConfirmationDialog)"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="packageConfirmationDialog = null"
                  >
                    Bestätigen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-row
              class="my-auto flex-grow-0"
              v-if="field.inputType.toString() === 'pricing'"
            >
              <v-col class="mx-0 px-0" cols="12">
                <v-simple-table
                  :style="
                    $vuetify.breakpoint.xs
                      ? 'max-width: 320px; margin: 0 auto;'
                      : ''
                  "
                >
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-if="
                          fieldEntries.membership &&
                          fieldEntries.membership.selection &&
                          fieldEntries.membership.selection.title &&
                          membershipInfos
                        "
                      >
                        <td style="min-height: 60px">
                          {{ fieldEntries.membership.selection.title }}
                        </td>
                        <td
                          style="min-height: 60px; text-align: right"
                          v-if="messeContract"
                        >
                          <span>
                            CHF
                            {{
                              formatPriceFixedString(
                                messeCalc(
                                  fieldEntries.membership.selection.title.toLowerCase(),
                                  parseInt(membershipInfos.duration.years)
                                )
                              )
                            }}.- anstatt CHF
                            {{
                              formatPriceFixedString(membershipInfos.price)
                            }}.-
                          </span>
                        </td>
                        <td
                          style="min-height: 60px; text-align: right"
                          v-if="!messeContract"
                        >
                          <span
                            v-if="
                              !blackFridaySelectionValidation &&
                              !membershipInfos.saleActive
                            "
                          >
                            CHF
                            {{
                              formatPriceFixedString(membershipInfos.price)
                            }}.-
                          </span>

                          <span v-else-if="blackFridaySelectionValidation">
                            CHF
                            {{
                              blackFridayDiscountCalculatorManual(
                                parseInt(membershipInfos.price) +
                                  parseInt(membershipInfos.signUpFee)
                              )
                            }}.- anstatt CHF
                            {{
                              formatPriceFixedString(
                                parseInt(membershipInfos.price) +
                                  parseInt(membershipInfos.signUpFee)
                              )
                            }}.-
                          </span>
                          <span v-else>
                            CHF
                            {{
                              formatPriceFixedString(membershipInfos.price)
                            }}.- anstatt CHF
                            {{
                              formatPriceFixedString(
                                membershipInfos.saleOldPrice
                              )
                            }}.-
                          </span>
                        </td>
                      </tr>
                      <tr v-if="fieldEntries.membership.renewalDiscount">
                        <td style="min-height: 60px">
                          <span>Wiedereinschreiberabatt</span>
                        </td>

                        <td
                          style="min-height: 60px; text-align: right"
                          class="align-center d-flex justify-end"
                        >
                          <span class="pr-1">-</span>
                          <span class="pr-2">
                            CHF
                            {{
                              formatPriceFixedString(
                                contract.promotions.renewalDiscountAmount
                              )
                            }}.-
                          </span>
                          <v-icon color="primary">mdi-sale</v-icon>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          membershipInfos.signUpFee !== null &&
                          (parseInt(membershipInfos.signUpFee) !== 0 ||
                            membershipInfos.saleActiveSignUpFeeGifted) &&
                          !noSignUpFeeCategory
                        "
                      >
                        <td style="min-height: 60px">
                          Aktivierungs-Tarif
                          <span
                            v-if="membershipInfos.giftSignUpFee"
                            class="pl-1"
                          >
                            geschenkt
                          </span>
                        </td>
                        <td
                          v-if="membershipInfos.giftSignUpFee"
                          class="align-center d-flex justify-end"
                          style="text-align: right; min-height: 60px"
                        >
                          <span class="pr-2">CHF 0.-</span>
                          <v-icon color="primary">mdi-gift</v-icon>
                        </td>
                        <td style="min-height: 60px; text-align: right" v-else>
                          CHF
                          {{
                            formatPriceFixedString(membershipInfos.signUpFee)
                          }}.-
                        </td>
                      </tr>
                      <tr v-if="false && badge.enabled">
                        <td style="min-height: 60px">Badge</td>

                        <td style="min-height: 60px; text-align: right">
                          <span>CHF {{ badge.amount }}.-</span>
                        </td>
                      </tr>
                      <tr
                        v-for="(packag, i) of fieldEntries.packages"
                        :key="i + '-packageSumIndex'"
                      >
                        <td style="min-height: 60px">
                          <span
                            v-if="
                              packag.studioSettings &&
                              packag.studioSettings[studio._id] &&
                              packag.studioSettings[studio._id]
                                .showAdditionalInfoPrint &&
                              packag.studioSettings[studio._id]
                                .showAdditionalInfoPrintText
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  color="primary"
                                  class="mb-1"
                                >
                                  {{
                                    shortenPackagePrintText(
                                      packag.studioSettings[studio._id]
                                        .showAdditionalInfoPrintText
                                    )
                                  }}
                                </v-chip>
                              </template>
                              <span>
                                {{
                                  packag.studioSettings[studio._id]
                                    .showAdditionalInfoPrintText
                                }}
                              </span>
                            </v-tooltip>
                            <br />
                          </span>
                          <span
                            v-if="
                              getPackageInfos(packag).amount !== 0 &&
                              getPackageInfos(packag).amount !== 1
                            "
                          >
                            {{ getPackageInfos(packag).amount }}x
                          </span>
                          <span>{{ packag.title }}</span>
                        </td>
                        <td
                          style="min-height: 60px; text-align: right"
                          class="align-center d-flex justify-end"
                          v-if="messeContract"
                        >
                          <span>
                            CHF
                            {{
                              formatPriceFixedString(
                                parseInt(getPackageInfos(packag).price) -
                                  parseInt(getPackageInfos(packag).price) / 5
                              )
                            }}.- anstatt CHF
                            {{
                              formatPriceFixedString(
                                getPackageInfos(packag).price
                              )
                            }}.-
                          </span>
                        </td>
                        <td
                          style="min-height: 60px; text-align: right"
                          class="align-center d-flex justify-end"
                          v-if="!messeContract"
                        >
                          <span
                            :class="{ 'pr-2': getPackageInfos(packag).gifted }"
                          >
                            <span
                              v-if="
                                !(
                                  ostWellnessSpaPlusRabatt &&
                                  packag.title
                                    .toLowerCase()
                                    .includes('spa plus')
                                )
                              "
                            >
                              <span>
                                CHF
                                {{
                                  formatPriceFixedString(
                                    getPackageInfos(packag).price
                                  )
                                }}.-
                              </span>
                            </span>
                            <span v-else>
                              CHF
                              {{
                                parseInt(
                                  formatPriceFixedString(
                                    getPackageInfos(packag).price
                                  )
                                ) - 100
                              }}.- anstatt CHF
                              {{
                                formatPriceFixedString(
                                  getPackageInfos(packag).price
                                )
                              }}.-
                            </span>
                          </span>
                          <v-icon
                            color="primary"
                            v-if="getPackageInfos(packag).gifted"
                          >
                            mdi-gift
                          </v-icon>
                        </td>
                      </tr>
                      <tr
                        class="white--text rounded"
                        style="
                          background-color: var(--v-primary);
                          font-weight: bold;
                          letter-spacing: 0.8px;
                        "
                      >
                        <td style="min-height: 60px">Gesamt</td>
                        <td
                          v-if="
                            !blackFridaySelectionValidation && !messeContract
                          "
                          style="
                            min-height: 60px;
                            text-align: right;
                            min-width: 120px;
                          "
                        >
                          CHF {{ formatPriceFixedString(getTotalPrice()) }}.-
                        </td>
                        <td
                          style="
                            min-height: 60px;
                            text-align: right;
                            min-width: 120px;
                          "
                          v-if="messeContract"
                        >
                          CHF
                          {{ formatPriceFixedString(getTotalPriceMesse()) }}.-
                          anstatt
                          {{ formatPriceFixedString(getTotalPrice()) }}.-
                        </td>
                        <td
                          v-if="blackFridaySelectionValidation"
                          style="
                            min-height: 60px;
                            text-align: right;
                            min-width: 120px;
                          "
                        >
                          CHF
                          {{
                            parseInt(getTotalPrice()) -
                            (parseInt(membershipInfos.price) -
                              parseInt(
                                blackFridayDiscountCalculatorManual(
                                  parseInt(membershipInfos.price) +
                                    parseInt(membershipInfos.signUpFee)
                                )
                              ))
                          }}.- anstatt CHF
                          {{
                            formatPriceFixedString(
                              parseInt(getTotalPrice()) +
                                parseInt(membershipInfos.signUpFee)
                            )
                          }}.-
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col
                class="mx-0 px-0"
                cols="12"
                v-if="membershipInfos.saleActive && !isTakeoverContract"
              >
                <v-alert type="info" color="primary">
                  AKTION Du erhältst einen Rabatt von CHF
                  {{ membershipInfos.salePriceDifference }}.-
                  <span v-if="parseInt(membershipInfos.duration.years) === 1">
                    im ersten Jahr
                    <span
                      class="pl-1"
                      v-if="
                        membershipInfos.saleActiveSignUpFeeGifted &&
                        !noSignUpFeeCategory
                      "
                    >
                      und den Aktivierungs-Tarif von CHF
                      {{ membershipInfos.saleOldSignUpFee }}.- geschenkt
                    </span>
                    !
                  </span>
                  <span v-else>
                    in den ersten
                    {{ parseInt(membershipInfos.duration.years) }}
                    Jahren
                    <span
                      class="pl-1"
                      v-if="
                        membershipInfos.saleActiveSignUpFeeGifted &&
                        !noSignUpFeeCategory
                      "
                    >
                      und den Aktivierungs-Tarif von CHF
                      {{ membershipInfos.saleOldSignUpFee }}.- geschenkt
                    </span>
                    !
                  </span>
                </v-alert>
              </v-col>
              <v-col
                class="mx-0 px-0"
                cols="12"
                v-if="blackFridaySelectionValidation"
              >
                <v-alert type="info" color="primary">
                  AKTION BLACK FRIDAY Du erhältst einen Rabatt von CHF
                  {{
                    parseInt(
                      parseInt(getTotalPrice()) +
                        parseInt(membershipInfos.signUpFee)
                    ) -
                    parseInt(
                      parseInt(getTotalPrice()) -
                        (parseInt(membershipInfos.price) -
                          parseInt(
                            blackFridayDiscountCalculatorManual(
                              parseInt(membershipInfos.price) +
                                parseInt(membershipInfos.signUpFee)
                            )
                          ))
                    )
                  }}.-
                  <span v-if="parseInt(membershipInfos.duration.years) === 1"
                    >im ersten Jahr!</span
                  >
                  <span v-else>
                    in den ersten
                    {{ parseInt(membershipInfos.duration.years) }} Jahren!
                  </span>
                </v-alert>
              </v-col>
              <v-col class="px-0" v-if="badge.enabled" cols="12">
                <v-alert type="info">
                  zzgl.
                  {{ membershipFlexSelection ? "Depot" : "Kaufpreis" }} für
                  Chiparmband CHF {{ badge.amount }}.- (elektronischer Zugang
                  für verschiedene Bereiche und Funktionen) bezahlbar vor Ort
                </v-alert>
              </v-col>
              <v-col class="mx-0 px-0" cols="12" v-if="messeContract">
                <v-alert type="info">
                  MESSE ANGEBOT Du erhältst einen Rabatt von CHF
                  {{
                    parseInt(getTotalPrice()) - parseInt(getTotalPriceMesse())
                  }}.-
                  <span v-if="parseInt(membershipInfos.duration.years) === 1"
                    >im ersten Jahr!</span
                  >
                  <span v-else>
                    in den ersten
                    {{ parseInt(membershipInfos.duration.years) }} Jahren!
                  </span>
                </v-alert>
              </v-col>
              <v-col cols="12" v-if="showBonustimeButton">
                <v-btn
                  :disabled="viewOnly"
                  class="white--text"
                  :color="bonusDuration ? 'red' : 'primary'"
                  @click="addBonusDuration"
                >
                  <span v-if="parseInt(membershipInfos.duration.years) === 1">
                    +1 Monat Bonuszeit
                  </span>
                  <span v-else>+2 Monate Bonuszeit</span>
                </v-btn>
              </v-col>
              <v-col
                class="mx-0 px-0"
                cols="12"
                v-if="
                  !publicContract &&
                  contract.promotions &&
                  contract.promotions.giftSignUpFee &&
                  !noSignUpFeeCategory
                "
              >
                <v-btn
                  :disabled="viewOnly"
                  :color="
                    fieldEntries.membership.giftSignUpFee ? 'red' : 'primary'
                  "
                  @click="giftSignUpFeeToggle"
                  class="white--text"
                >
                  Aktivierungs-Tarif
                </v-btn>
              </v-col>
              <v-col
                class="mx-0 px-0"
                cols="12"
                v-if="
                  !publicContract &&
                  !messeContract &&
                  contract.promotions &&
                  contract.promotions.renewalDiscount &&
                  parseInt(membershipInfos.duration.months) !== 6
                "
              >
                <v-btn
                  :disabled="viewOnly"
                  :color="
                    fieldEntries.membership.renewalDiscount ? 'red' : 'primary'
                  "
                  @click="renewalDiscountToggle"
                  class="white--text"
                >
                  Wiedereinschreiberabatt
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="my-auto flex-grow-0"
              v-if="field.inputType === 'duration'"
            >
              <v-col class="mt-5" v-if="preSale && false" cols="12">
                <v-alert type="info">
                  Jetzt ein Abo lösen und bis zur Eröffnung
                  {{
                    studio.code === "stgallenost"
                      ? "am Montag, 08. April 2024"
                      : "im Frühjahr 2024"
                  }}
                  an allen Standorten der well come FIT Gruppe kostenlos
                  trainieren.
                  {{
                    studio.code === "stgallenost"
                      ? " Profitiere zusätzlich von einem Preisvorteil in Höhe von CHF 99.-, wenn du dein Abo jetzt löst."
                      : ""
                  }}
                </v-alert>
              </v-col>
              <v-col :class="{ 'mt-5': !preSale }" cols="12">
                <v-menu
                  ref="menu"
                  v-model="startDatePicker"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  :disabled="preSale"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$moment(startDate).format('DD.MM.YY')"
                      label="Vertragsstart"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="de-CH"
                    @change="calculateDuration(startDate)"
                    :disabled="viewOnly"
                    v-model="startDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="false"
                      text
                      color="primary"
                      @click="startDatePicker = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveAndCalculateDuration"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menue"
                  v-model="endDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$moment(endDate).format('DD.MM.YY')"
                      label="Vertragsablauf"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="de-CH"
                    :disabled="true"
                    v-model="endDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="false"
                      text
                      color="primary"
                      @click="endDatePicker = false"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menue[0].save(endDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" v-if="!publicContract">
                <v-textarea
                  :disabled="viewOnly"
                  rows="3"
                  label="zusätzliche Informationen / Hinweise"
                  v-model="additionalInfoPricing"
                  counter
                  :rules="[
                    (v) =>
                      (v || '').length <= 95 ||
                      'Bitte nur maximal 95 Zeichen eingeben',
                    (v) =>
                      !v ||
                      v.indexOf('\n') < 0 ||
                      'Bitte keine Zeilenumbrüche eingeben',
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              class="my-auto flex-grow-0 pb-0"
              v-if="
                field.inputType === 'paymentOptions' &&
                fieldEntries.membership &&
                fieldEntries.membership.selection !== null &&
                'title' in fieldEntries.membership.selection &&
                fieldEntries.membership.selectedDuration
              "
            >
              <v-col cols="12">
                <v-row
                  class="my-0 py-0 mt-3 d-flex justify-center"
                  v-if="fieldEntries.paymentOptions.selection !== null"
                >
                  <v-col class="pb-0 mb-0 d-flex justify-center" cols="12">
                    <v-btn
                      v-if="!viewOnly"
                      class="mx-auto"
                      @click="
                        fieldEntries.paymentOptions = {
                          selection: null,
                          selectedDate: null,
                          additionalInfo: '',
                        }
                      "
                      color="primary"
                    >
                      Zahlungsmethode ändern
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-for="(paymentOption, i) of field.inputValue.filter((e) =>
                      e.studios.includes(studio._id)
                    )"
                    :class="{
                      'pr-1 pl-0': i % 2 === 0,
                      'pl-1 pr-0': i % 2 !== 0,
                    }"
                    class="pb-2 pt-0"
                    :key="i + '-indexPaymentOptions'"
                    cols="6"
                  >
                    <v-card
                      v-if="
                        fieldEntries.paymentOptions.selection === null ||
                        (fieldEntries.paymentOptions.selection &&
                          fieldEntries.paymentOptions.selection.title ===
                            paymentOption.title)
                      "
                      class="optionCard align-center justify-center d-flex fill-height"
                      outlined
                      :class="{
                        selectedOption:
                          fieldEntries.paymentOptions.selection &&
                          fieldEntries.paymentOptions.selection.title &&
                          fieldEntries.paymentOptions.selection.title ===
                            paymentOption.title,
                      }"
                      @click="
                        () => {
                          if (
                            fieldEntries.paymentOptions.selection &&
                            fieldEntries.paymentOptions.selection.title &&
                            fieldEntries.paymentOptions.selection.title ===
                              paymentOption.title
                          )
                            fieldEntries.paymentOptions = {
                              selection: null,
                              selectedDate: null,
                              additionalInfo: '',
                            };
                          else
                            fieldEntries.paymentOptions = {
                              selection: paymentOption,
                              selectedDate: null,
                              additionalInfo: '',
                            };
                        }
                      "
                    >
                      <v-row no-gutters>
                        <v-col
                          :cols="paymentOption.description ? 10 : 12"
                          class="align-center d-flex justify-center"
                        >
                          {{ paymentOption.title }}
                        </v-col>
                        <v-col v-if="paymentOption.description" cols="2">
                          <v-tooltip class="ml-auto" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click.stop>
                                <v-icon
                                  :color="
                                    fieldEntries.paymentOptions.selection &&
                                    fieldEntries.paymentOptions.selection
                                      .title &&
                                    fieldEntries.paymentOptions.selection
                                      .title === paymentOption.title
                                      ? 'white'
                                      : ''
                                  "
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ paymentOption.description }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-row v-if="false">
                    <v-col
                      cols="3"
                      :class="{
                        'mt-5':
                          fieldEntries.paymentOptions.selection &&
                          fieldEntries.paymentOptions.selection.title ===
                            paymentOption.title,
                      }"
                      class="px-0 py-0 d-flex align-center"
                    >
                      <div
                        v-if="
                          fieldEntries.paymentOptions.selection &&
                          fieldEntries.paymentOptions.selection.title &&
                          fieldEntries.paymentOptions.selection.title ===
                            paymentOption.title
                        "
                      >
                        <v-btn
                          :disabled="viewOnly"
                          @click="
                            fieldEntries.paymentOptions = {
                              selection: null,
                              selectedDate: null,
                              additionalInfo: '',
                            }
                          "
                          icon
                          x-large
                        >
                          <v-icon color="primary">mdi-check-circle</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          :disabled="viewOnly"
                          x-large
                          icon
                          @click="
                            fieldEntries.paymentOptions = {
                              selection: paymentOption,
                              selectedDate: null,
                              additionalInfo: '',
                            }
                          "
                        >
                          <v-icon color="primary"
                            >mdi-plus-circle-outline</v-icon
                          >
                        </v-btn>
                      </div>

                      <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                    </v-col>
                    <v-col
                      cols="9"
                      :class="{
                        'mt-5':
                          fieldEntries.paymentOptions.selection &&
                          fieldEntries.paymentOptions.selection.title ===
                            paymentOption.title,
                      }"
                      class="px-0 py-auto d-flex align-center"
                    >
                      <span style="font-size: 1rem" class="option">
                        {{ paymentOption.title }}
                      </span>

                      <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>

              <v-col
                class="px-0"
                v-if="
                  fieldEntries.paymentOptions.selection &&
                  fieldEntries.paymentOptions.selection.additionalInfo
                "
                cols="12"
              >
                <v-card-text class="px-0">
                  {{ fieldEntries.paymentOptions.selection.additionalInfo }}
                </v-card-text>
              </v-col>

              <v-col
                class="d-flex justify-center"
                v-if="
                  fieldEntries.paymentOptions.selection !== null &&
                  !publicContract
                "
                cols="12"
              >
                <v-textarea
                  :disabled="viewOnly"
                  label="zusätzliche Informationen"
                  counter
                  :rules="[
                    (v) =>
                      (v || '').length <= 95 ||
                      'Bitte nur maximal 95 Zeichen eingeben',
                    (v) =>
                      !v ||
                      v.indexOf('\n') < 0 ||
                      'Bitte keine Zeilenumbrüche eingeben',
                  ]"
                  v-model="fieldEntries.paymentOptions.additionalInfo"
                ></v-textarea>
              </v-col>

              <!---<v-col
                                class="d-flex justify-center"
                                v-if="
                                    false &&
                                    fieldEntries.paymentOptions.selection &&
                                    fieldEntries.paymentOptions.selection.selectDate
                                "
                                cols="12">
                                <v-date-picker
                                    locale="de-DE"
                                    disabled
                                    @change="calculateDuration"
                                    v-model="fieldEntries.paymentOptions.selectedDate"></v-date-picker>
                            </v-col>-->
            </v-row>

            <v-row
              class="my-auto flex-grow-0"
              v-if="field.inputType === 'conditions'"
            >
              <v-col
                cols="12"
                v-if="
                  !conditionsHighlight &&
                  contract &&
                  contract.preConditionText &&
                  contract.preConditionText.length > 3
                "
              >
                <strong>{{ contract.preConditionText }}</strong>
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(condition, i) of field.inputValue"
                  :key="i + '-conditionIndex'"
                  class="my-0 py-0 mt-5"
                  :style="
                    conditionsHighlight
                      ? 'max-height: 250px; overflow-y: scroll;'
                      : ''
                  "
                  v-show="!conditionsHighlight || condition.highlight"
                >
                  <v-col cols="2" sm="1" class="d-flex align-center">
                    <v-card-text class="pa-0 ma-auto">{{ i + 1 }}.</v-card-text>
                  </v-col>
                  <v-col cols="10" sm="11" class="px-0 py-0">
                    <v-card-text max-width="70vw" class="py-0 px-0">
                      {{ condition.inputValue }}
                    </v-card-text>
                    <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="
                  !conditionsHighlight &&
                  contract &&
                  contract.postConditionText &&
                  contract.postConditionText.length > 3
                "
                cols="12"
              >
                <strong>{{ contract.postConditionText }}</strong>
              </v-col>
              <v-col cols="12">
                <v-row v-show="conditionsHighlight">
                  <v-col cols="12">
                    <v-card-subtitle class="mx-0 px-0"
                      >Unterschrift Kunde</v-card-subtitle
                    >
                  </v-col>
                  <v-col
                    v-if="!viewOnly"
                    class="d-flex justify-center"
                    cols="12"
                  >
                    <signature-pad
                      :signature-data="fieldEntries.conditions.signature"
                      @startDrawing="setSignatureConditions(true)"
                      @resetDrawing="setSignatureConditions(false)"
                      @saveSignature="saveSignatureConditions"
                      ref="signatureConditions"
                    ></signature-pad>
                  </v-col>

                  <v-col v-else class="d-flex justify-center" cols="12">
                    <v-img
                      max-width="200px"
                      :src="fieldEntries.conditions.signature"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="mb-0 pb-0"
                    cols="12"
                    v-if="fieldEntries.customer.isUnderage"
                  >
                    <v-card-subtitle class="mx-0 px-0 pb-0 mb-0">
                      gesetzlicher Vertreter
                    </v-card-subtitle>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="6"
                    class="text-center mb-0 pb-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="Vorname"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.firstName
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="6"
                    class="text-center mb-0 pb-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="Nachname"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.lastName
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="12"
                    class="text-center mt-0 pt-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="E-Mail"
                      type="text"
                      v-model="fieldEntries.customer.legalRepresentative.email"
                    ></v-text-field>
                    <v-text-field
                      :disabled="viewOnly"
                      label="Adresse"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.address
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="!viewOnly && fieldEntries.customer.isUnderage"
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <signature-pad
                      @startDrawing="setSignatureConditionsLR(true)"
                      @resetDrawing="setSignatureConditionsLR(false)"
                      :signature-data="
                        fieldEntries.customer.legalRepresentative.firstSignature
                      "
                      @saveSignature="
                        saveSignatureLegalRepresentativeFirstSignature
                      "
                      ref="signatureConditionsLegalRepresentativeFirst"
                    ></signature-pad>
                  </v-col>
                  <v-col
                    v-if="viewOnly && fieldEntries.customer.isUnderage"
                    class="d-flex justify-center"
                    cols="12"
                  >
                    <v-img
                      max-width="200px"
                      :src="
                        fieldEntries.customer.legalRepresentative.firstSignature
                      "
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="my-auto flex-grow-0"
              v-if="field.inputType === 'endPage'"
            >
              <v-col cols="12" class="mt-5 pt-2">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(additionalImage, index) of fieldEntries.customer
                      .additionalImage"
                    :key="index + '-additionalImageIndex'"
                  >
                    <v-expansion-panel-header
                      >Anhang {{ index + 1 }}.</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-btn v-if="!viewOnly" @click="removeImage(index)" text
                        >Löschen</v-btn
                      >
                      <v-img
                        class="mx-auto"
                        max-width="200px"
                        :key="
                          imageSources[
                            fieldEntries.customer.additionalImage[index]
                          ] + 'imgsourceKey'
                        "
                        v-if="
                          imageSources[
                            fieldEntries.customer.additionalImage[index]
                          ]
                        "
                        :src="
                          imageSources[
                            fieldEntries.customer.additionalImage[index]
                          ]
                        "
                        alt=""
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                  <v-col cols="12">
                    <v-row class="upload-section">
                      <v-col cols="12" class="mb-0">
                        <v-file-input
                          multiple
                          @change="uploadImagesContract"
                          :label="imageUploadLabelText"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row v-if="fieldEntries.customer.additionalImages">
                      <v-col cols="12">
                        <v-window v-model="activeIndexContract">
                          <v-window-item
                            v-for="(image, index) in fieldEntries.customer
                              .additionalImages"
                            :key="`card-${index}`"
                          >
                            <v-card color="transparent" height="200">
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                              >
                                <v-col align="center" cols="12">
                                  <v-img
                                    contain
                                    class="mx-auto"
                                    v-if="image.dataUrl"
                                    height="150px"
                                    max-width="200px"
                                    :src="image.dataUrl"
                                    alt="Uploaded Image"
                                  ></v-img>
                                  <v-btn
                                    v-else
                                    class="mx-auto"
                                    @click="fetchImageDataSource(index)"
                                    color="primary"
                                  >
                                    Bild laden
                                  </v-btn>
                                  <v-btn
                                    v-if="!viewOnly"
                                    class="mx-auto"
                                    @click="deleteImageContract(index)"
                                    color="error"
                                  >
                                    Löschen
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-window-item>
                        </v-window>
                        <v-card-actions class="justify-center">
                          <v-item-group
                            v-model="activeIndexContract"
                            class="text-center"
                            mandatory
                          >
                            <v-item
                              v-for="n in fieldEntries.customer.additionalImages
                                .length"
                              :key="`btn-${n}`"
                              v-slot="{ active, toggle }"
                            >
                              <v-btn :input-value="active" icon @click="toggle">
                                <v-icon>mdi-record</v-icon>
                              </v-btn>
                            </v-item>
                          </v-item-group>
                        </v-card-actions>
                      </v-col>
                    </v-row>

                    <!-- Gallery Section -->
                    <v-row
                      class="gallery-section"
                      v-if="false && fieldEntries.customer.additionalImages"
                    >
                      <v-col cols="12">
                        <div
                          ref="scrollContainer"
                          @scroll="updateActiveIndexContract"
                          style="
                            overflow-x: scroll;
                            white-space: nowrap;
                            scroll-snap-type: x mandatory;
                          "
                        >
                          <v-card
                            elevation="0"
                            transparent
                            v-for="(image, index) in fieldEntries.customer
                              .additionalImages"
                            :key="index"
                            ref="cards"
                            style="
                              display: inline-block;
                              scroll-snap-align: center;
                              width: 100%;
                            "
                          >
                            <v-img
                              contain
                              class="mx-auto"
                              max-height="200px"
                              max-width="200px"
                              :src="image.dataUrl"
                              alt="Uploaded Image"
                            ></v-img>

                            <v-card-actions>
                              <v-btn
                                class="mx-auto"
                                @click="deleteImageContract(index)"
                                color="error"
                              >
                                Löschen
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                      </v-col>
                      <v-col class="mb-5" cols="12" style="text-align: center">
                        <span
                          v-for="(image, index) in fieldEntries.customer
                            .additionalImages"
                          :key="'dot-' + index"
                          @click="scrollToIndexContract(index)"
                          :class="[
                            'dot',
                            { active: activeIndexContract === index },
                          ]"
                        ></span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col v-if="!viewOnly" cols="12" class="my-5">
                    <div class="containerFileUpload">
                      <div class="file-upload">
                        <input
                          type="file"
                          @change="onFileSelected"
                          ref="fileInput"
                          id="fileInput"
                        />
                        <label
                          v-if="!publicContract"
                          for="fileInput"
                          class="file-upload-label"
                        >
                          Bild auswählen
                        </label>
                        <label v-else for="fileInput" class="file-upload-label">
                          Selfie auswählen
                        </label>
                        <!-- Boii -->
                        <v-btn
                          color="primary"
                          class="upload-button"
                          @click="uploadPictureNew"
                          :disabled="!selectedFile"
                        >
                          Hochladen
                        </v-btn>
                      </div>
                      <p v-if="selectedFile" class="selected-file">
                        Ausgewählte Datei: {{ selectedFile.name }}
                      </p>
                      <p v-if="uploadStatus" class="upload-status">
                        {{ uploadStatus }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="
                      fieldEntries.category.selection &&
                      fieldEntries.category.selection.title
                    "
                  >
                    <v-card-text class="px-0">
                      {{ fieldEntries.category.selection.title }} zwischen
                    </v-card-text>
                  </v-col>

                  <v-col class="my-0 py-0" cols="12">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          color="primary"
                          class="white--text expand-icon-white"
                        >
                          <span>{{ companyInfos.name }}</span>
                          <template v-slot:actions>
                            <v-icon color="white">$expand</v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card-text class="mb-0 pb-0 px-0">
                            {{ companyInfos.street }}
                            <br />
                            {{ companyInfos.city }}
                            <br />
                            {{ companyInfos.phone }}
                          </v-card-text>
                          <v-card-text
                            v-if="companyInfos.useMainCompany"
                            class="my-0 py-0 px-0"
                          >
                            <br />
                            Stammcenter: {{ companyInfos.mainCompany.name }},
                            {{ companyInfos.mainCompany.street }},
                            {{ companyInfos.mainCompany.city }},
                            {{ companyInfos.mainCompany.phone }}
                          </v-card-text>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <v-col class="my-0 py-0" cols="12">
                    <v-card-text class="px-0">und</v-card-text>
                  </v-col>
                  <v-col
                    class="my-0 py-0"
                    v-if="fieldEntries.customer.infos"
                    cols="12"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          color="primary"
                          class="white--text expand-icon-white"
                        >
                          {{
                            fieldEntries.customer.infos.gender === "m"
                              ? "Herr"
                              : "Frau"
                          }}
                          {{
                            fieldEntries.customer.infos.firstName
                              ? fieldEntries.customer.infos.firstName
                              : ""
                          }}
                          {{
                            fieldEntries.customer.infos.lastName
                              ? fieldEntries.customer.infos.lastName
                              : ""
                          }}
                          <template v-slot:actions>
                            <v-icon color="white">$expand</v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card-text class="mb-0 pb-0 px-0">
                            {{
                              fieldEntries.customer.infos.email
                                ? fieldEntries.customer.infos.email
                                : ""
                            }}
                            <br />
                            {{
                              fieldEntries.customer.infos.phone
                                ? fieldEntries.customer.infos.phone
                                : ""
                            }}
                            <br />
                            {{
                              fieldEntries.customer.infos.birthday
                                ? $moment(
                                    fieldEntries.customer.infos.birthday
                                  ).format("DD.MM.YYYY")
                                : ""
                            }}
                            <br />
                            {{
                              fieldEntries.customer.infos.street
                                ? fieldEntries.customer.infos.street
                                : ""
                            }}
                            <br />
                            {{
                              fieldEntries.customer.infos.zip
                                ? fieldEntries.customer.infos.zip
                                : ""
                            }}
                            {{
                              fieldEntries.customer.infos.city
                                ? fieldEntries.customer.infos.city
                                : ""
                            }}
                          </v-card-text>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <v-col v-if="fieldEntries.category.isAffiliate" cols="12">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          color="primary"
                          class="white--text expand-icon-white d-flex justify-start"
                        >
                          <div>
                            <v-icon color="white" class="pr-2">
                              mdi-account-arrow-right-outline
                            </v-icon>
                            <span>
                              {{
                                fieldEntries.category.affiliateInfos.firstName
                                  ? fieldEntries.category.affiliateInfos
                                      .firstName
                                  : ""
                              }}
                              {{
                                fieldEntries.category.affiliateInfos.lastName
                                  ? fieldEntries.category.affiliateInfos
                                      .lastName
                                  : ""
                              }}
                            </span>
                          </div>

                          <template v-slot:actions>
                            <v-icon color="white">$expand</v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card-text class="mb-0 pb-0 px-0">
                            wurde weiterempfohlen von:
                            <br />
                            {{
                              fieldEntries.category.affiliateInfos.email
                                ? fieldEntries.category.affiliateInfos.email
                                : ""
                            }}
                            <br />
                          </v-card-text>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col class="mx-0 px-0" cols="12">
                    <v-simple-table
                      :style="
                        $vuetify.breakpoint.xs
                          ? 'max-width: 320px; margin: 0 auto;'
                          : ''
                      "
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-if="
                              fieldEntries.membership &&
                              fieldEntries.membership.selection &&
                              fieldEntries.membership.selection.title &&
                              membershipInfos
                            "
                          >
                            <td style="min-height: 60px">
                              {{ fieldEntries.membership.selection.title }}
                              <br />
                              <span
                                v-if="startDate && endDate"
                                style="font-size: 13px"
                              >
                                {{ $moment(startDate).format("DD.MM.YY") }} bis
                                {{ $moment(endDate).format("DD.MM.YY") }}
                              </span>
                            </td>
                            <td
                              style="min-height: 60px; text-align: right"
                              v-if="messeContract"
                            >
                              <span>
                                CHF
                                {{
                                  formatPriceFixedString(
                                    messeCalc(
                                      fieldEntries.membership.selection.title.toLowerCase(),
                                      parseInt(membershipInfos.duration.years)
                                    )
                                  )
                                }}.- anstatt CHF
                                {{
                                  formatPriceFixedString(membershipInfos.price)
                                }}.-
                              </span>
                            </td>
                            <td
                              v-else
                              style="min-height: 60px; text-align: right"
                            >
                              <span
                                v-if="
                                  !blackFridaySelectionValidation &&
                                  !membershipInfos.saleActive
                                "
                              >
                                CHF
                                {{
                                  formatPriceFixedString(membershipInfos.price)
                                }}.-
                              </span>

                              <span v-else-if="blackFridaySelectionValidation">
                                CHF
                                {{
                                  blackFridayDiscountCalculatorManual(
                                    parseInt(membershipInfos.price) +
                                      parseInt(membershipInfos.signUpFee)
                                  )
                                }}.- anstatt CHF
                                {{
                                  formatPriceFixedString(
                                    parseInt(membershipInfos.price) +
                                      parseInt(membershipInfos.signUpFee)
                                  )
                                }}.-
                              </span>
                              <span v-else>
                                CHF
                                {{
                                  formatPriceFixedString(membershipInfos.price)
                                }}.- anstatt CHF
                                {{
                                  formatPriceFixedString(
                                    membershipInfos.saleOldPrice
                                  )
                                }}.-
                              </span>
                            </td>
                          </tr>
                          <tr v-if="fieldEntries.membership.renewalDiscount">
                            <td style="min-height: 60px">
                              <span>Wiedereinschreiberabatt</span>
                            </td>

                            <td
                              class="align-center d-flex justify-end"
                              style="text-align: right; min-height: 60px"
                            >
                              <span class="pr-1">-</span>
                              <span class="pr-2">
                                CHF
                                {{
                                  formatPriceFixedString(
                                    contract.promotions.renewalDiscountAmount
                                  )
                                }}.-
                              </span>
                              <v-icon color="primary">mdi-sale</v-icon>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              membershipInfos.signUpFee !== null &&
                              (parseInt(membershipInfos.signUpFee) !== 0 ||
                                membershipInfos.saleActiveSignUpFeeGifted) &&
                              !noSignUpFeeCategory
                            "
                          >
                            <td style="min-height: 60px">
                              Aktivierungs-Tarif
                              <span
                                class="pl-1"
                                v-if="membershipInfos.giftSignUpFee"
                              >
                                geschenkt
                              </span>
                            </td>
                            <td
                              v-if="membershipInfos.giftSignUpFee"
                              class="align-center d-flex justify-end"
                              style="text-align: right; min-height: 60px"
                            >
                              <span class="pr-2">CHF 0.-</span>
                              <v-icon color="primary">mdi-gift</v-icon>
                            </td>
                            <td
                              style="min-height: 60px; text-align: right"
                              v-else
                            >
                              CHF
                              {{
                                formatPriceFixedString(
                                  membershipInfos.signUpFee
                                )
                              }}.-
                            </td>
                          </tr>
                          <tr v-if="false && badge.enabled">
                            <td style="min-height: 60px">Badge</td>

                            <td style="min-height: 60px; text-align: right">
                              <span>CHF {{ badge.amount }}.-</span>
                            </td>
                          </tr>
                          <tr
                            v-for="(packag, i) of fieldEntries.packages"
                            :key="i + '-packageSumIndex'"
                          >
                            <td style="min-height: 60px">
                              <span
                                v-if="
                                  packag.studioSettings &&
                                  packag.studioSettings[studio._id] &&
                                  packag.studioSettings[studio._id]
                                    .showAdditionalInfoPrint &&
                                  packag.studioSettings[studio._id]
                                    .showAdditionalInfoPrintText
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      color="primary"
                                      class="mb-1"
                                    >
                                      {{
                                        shortenPackagePrintText(
                                          packag.studioSettings[studio._id]
                                            .showAdditionalInfoPrintText
                                        )
                                      }}
                                    </v-chip>
                                  </template>
                                  <span>
                                    {{
                                      packag.studioSettings[studio._id]
                                        .showAdditionalInfoPrintText
                                    }}
                                  </span>
                                </v-tooltip>
                                <br />
                              </span>
                              <span
                                v-if="
                                  getPackageInfos(packag).amount !== 0 &&
                                  getPackageInfos(packag).amount !== 1
                                "
                              >
                                {{ getPackageInfos(packag).amount }}x
                              </span>
                              <span>{{ packag.title }}</span>
                            </td>
                            <td
                              style="min-height: 60px; text-align: right"
                              class="align-center d-flex justify-end"
                              v-if="messeContract"
                            >
                              <span>
                                CHF
                                {{
                                  formatPriceFixedString(
                                    parseInt(getPackageInfos(packag).price) -
                                      parseInt(getPackageInfos(packag).price) /
                                        5
                                  )
                                }}.- anstatt CHF
                                {{
                                  formatPriceFixedString(
                                    getPackageInfos(packag).price
                                  )
                                }}.-
                              </span>
                            </td>
                            <td
                              class="align-center d-flex justify-end"
                              style="text-align: right; min-height: 60px"
                              v-else
                            >
                              <v-icon
                                color="primary"
                                v-if="getPackageInfos(packag).gifted"
                              >
                                mdi-gift
                              </v-icon>

                              <span
                                v-if="
                                  !(
                                    ostWellnessSpaPlusRabatt &&
                                    packag.title
                                      .toLowerCase()
                                      .includes('spa plus')
                                  )
                                "
                              >
                                CHF
                                {{
                                  formatPriceFixedString(
                                    getPackageInfos(packag).price
                                  )
                                }}.-
                              </span>
                              <span v-else>
                                CHF
                                {{
                                  parseInt(
                                    formatPriceFixedString(
                                      getPackageInfos(packag).price
                                    )
                                  ) - 100
                                }}.- anstatt CHF
                                {{
                                  formatPriceFixedString(
                                    getPackageInfos(packag).price
                                  )
                                }}.-
                              </span>
                            </td>
                          </tr>
                          <tr
                            class="white--text rounded"
                            style="
                              background-color: var(--v-primary);
                              font-weight: bold;
                              letter-spacing: 0.8px;
                            "
                          >
                            <td style="min-height: 60px">Gesamt</td>
                            <td
                              v-if="
                                !blackFridaySelectionValidation &&
                                !messeContract
                              "
                              style="
                                min-height: 60px;
                                text-align: right;
                                min-width: 120px;
                              "
                            >
                              CHF
                              {{ formatPriceFixedString(getTotalPrice()) }}.-
                            </td>
                            <td
                              style="
                                min-height: 60px;
                                text-align: right;
                                min-width: 120px;
                              "
                              v-if="messeContract"
                            >
                              CHF
                              {{
                                formatPriceFixedString(getTotalPriceMesse())
                              }}.- anstatt
                              {{ formatPriceFixedString(getTotalPrice()) }}.-
                            </td>
                            <td
                              v-if="blackFridaySelectionValidation"
                              style="
                                min-height: 60px;
                                text-align: right;
                                min-width: 120px;
                              "
                            >
                              CHF
                              {{
                                parseInt(getTotalPrice()) -
                                (parseInt(membershipInfos.price) -
                                  parseInt(
                                    blackFridayDiscountCalculatorManual(
                                      parseInt(membershipInfos.price) +
                                        parseInt(membershipInfos.signUpFee)
                                    )
                                  ))
                              }}.- anstatt CHF
                              {{
                                formatPriceFixedString(
                                  parseInt(getTotalPrice()) +
                                    parseInt(membershipInfos.signUpFee)
                                )
                              }}.-
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col class="mx-0 px-0" cols="12" v-if="messeContract">
                    <v-alert type="info">
                      MESSE ANGEBOT Du erhältst einen Rabatt von CHF
                      {{
                        parseInt(getTotalPrice()) -
                        parseInt(getTotalPriceMesse())
                      }}.-
                      <span
                        v-if="parseInt(membershipInfos.duration.years) === 1"
                        >im ersten Jahr!</span
                      >
                      <span v-else>
                        in den ersten
                        {{ parseInt(membershipInfos.duration.years) }} Jahren!
                      </span>
                    </v-alert>
                  </v-col>
                  <v-col class="px-0" cols="12">
                    <v-col
                      class="mx-0 px-0"
                      cols="12"
                      v-if="membershipInfos.saleActive"
                    >
                      <v-alert type="info" color="primary">
                        AKTION Du erhältst einen Rabatt von CHF
                        {{ membershipInfos.salePriceDifference }}.-
                        <span
                          v-if="parseInt(membershipInfos.duration.years) === 1"
                        >
                          im ersten Jahr
                          <span
                            class="pl-1"
                            v-if="
                              membershipInfos.saleActiveSignUpFeeGifted &&
                              !noSignUpFeeCategory
                            "
                          >
                            und den Aktivierungs-Tarif von CHF
                            {{ membershipInfos.saleOldSignUpFee }}.- geschenkt
                          </span>
                          !
                        </span>
                        <span v-else>
                          in den ersten
                          {{ parseInt(membershipInfos.duration.years) }}
                          Jahren
                          <span
                            class="pl-1"
                            v-if="
                              membershipInfos.saleActiveSignUpFeeGifted &&
                              !noSignUpFeeCategory
                            "
                          >
                            und den Aktivierungs-Tarif von CHF
                            {{ membershipInfos.saleOldSignUpFee }}.- geschenkt
                          </span>
                          !
                        </span>
                      </v-alert>
                    </v-col>
                    <v-col
                      class="mx-0 px-0"
                      cols="12"
                      v-if="blackFridaySelectionValidation"
                    >
                      <v-alert type="info" color="primary">
                        AKTION BLACK FRIDAY Du erhältst einen Rabatt von CHF
                        {{
                          parseInt(
                            parseInt(getTotalPrice()) +
                              parseInt(membershipInfos.signUpFee)
                          ) -
                          parseInt(
                            parseInt(getTotalPrice()) -
                              (parseInt(membershipInfos.price) -
                                parseInt(
                                  blackFridayDiscountCalculatorManual(
                                    parseInt(membershipInfos.price) +
                                      parseInt(membershipInfos.signUpFee)
                                  )
                                ))
                          )
                        }}.-
                        <span
                          v-if="parseInt(membershipInfos.duration.years) === 1"
                        >
                          im ersten Jahr!
                        </span>
                        <span v-else>
                          in den ersten
                          {{ parseInt(membershipInfos.duration.years) }}
                          Jahren!
                        </span>
                      </v-alert>
                    </v-col>

                    <v-col class="px-0" v-if="badge.enabled" cols="12">
                      <v-alert type="info">
                        zzgl.
                        {{ membershipFlexSelection ? "Depot" : "Kaufpreis" }}
                        für Chiparmband CHF {{ badge.amount }}.- (elektronischer
                        Zugang für verschiedene Bereiche und Funktionen)
                        bezahlbar vor Ort
                      </v-alert>
                    </v-col>
                  </v-col>
                  <v-col cols="12" v-if="!publicContract">
                    <v-textarea
                      :disabled="viewOnly"
                      rows="3"
                      label="zusätzliche Informationen / Hinweise"
                      v-model="additionalInfoPricing"
                      counter
                      :rules="[
                        (v) =>
                          (v || '').length <= 95 ||
                          'Bitte nur maximal 95 Zeichen eingeben',
                        (v) =>
                          !v ||
                          v.indexOf('\n') < 0 ||
                          'Bitte keine Zeilenumbrüche eingeben',
                      ]"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      contract &&
                      contract.preConditionText &&
                      contract.preConditionText.length > 3
                    "
                  >
                    <strong>{{ contract.preConditionText }}</strong>
                  </v-col>

                  <v-col cols="12">
                    <v-row
                      v-for="(condition, i) of conditions"
                      :key="i + '-conditionIndex'"
                      class="my-0 py-0 mt-5"
                    >
                      <v-col cols="2" sm="1" class="d-flex align-center">
                        <v-card-text class="pa-0 ma-auto"
                          >{{ i + 1 }}.</v-card-text
                        >
                      </v-col>
                      <v-col cols="10" sm="11" class="px-0 py-0">
                        <v-card-text max-width="70vw" class="py-0 px-0">
                          {{ condition.inputValue }}
                        </v-card-text>
                        <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                      </v-col>
                      <v-col class="" cols="12" v-if="condition.highlight">
                        <v-img
                          class="mx-auto"
                          width="200px"
                          v-if="fieldEntries.conditions.signature"
                          :src="fieldEntries.conditions.signature"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="
                      contract &&
                      contract.postConditionText &&
                      contract.postConditionText.length > 3
                    "
                    cols="12"
                  >
                    <strong>{{ contract.postConditionText }}</strong>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-card-subtitle class="mx-0 px-0"
                      >Unterschrift Kunde</v-card-subtitle
                    >
                  </v-col>
                  <v-col
                    v-if="!viewOnly"
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <signature-pad
                      ref="signatureContract"
                      :signature-data="fieldEntries.customer.signature"
                      @startDrawing="setSignatureContract(true)"
                      @resetDrawing="setSignatureContract(false)"
                      @saveSignature="saveSignatureContract"
                    ></signature-pad>
                  </v-col>
                  <v-col v-else class="d-flex justify-center" cols="12">
                    <v-img
                      max-width="200px"
                      :src="fieldEntries.customer.signature"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="mb-0 pb-0"
                    cols="12"
                    v-if="fieldEntries.customer.isUnderage"
                  >
                    <v-card-subtitle class="mx-0 px-0 pb-0 mb-0">
                      gesetzlicher Vertreter
                    </v-card-subtitle>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="6"
                    class="text-center mb-0 pb-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="Vorname"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.firstName
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="6"
                    class="text-center mb-0 pb-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="Nachname"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.lastName
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="fieldEntries.customer.isUnderage"
                    cols="12"
                    class="text-center mt-0 pt-0"
                  >
                    <v-text-field
                      :disabled="viewOnly"
                      label="E-Mail"
                      type="text"
                      v-model="fieldEntries.customer.legalRepresentative.email"
                    ></v-text-field>
                    <v-text-field
                      :disabled="viewOnly"
                      label="Adresse"
                      type="text"
                      v-model="
                        fieldEntries.customer.legalRepresentative.address
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="!viewOnly && fieldEntries.customer.isUnderage"
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <signature-pad
                      :signature-data="
                        fieldEntries.customer.legalRepresentative
                          .secondSignature
                      "
                      @saveSignature="
                        saveSignatureLegalRepresentativeSecondSignature
                      "
                      @startDrawing="setSignatureContractLR(true)"
                      @resetDrawing="setSignatureContractLR(false)"
                      ref="signatureConditionsLegalRepresentativeSecond"
                    ></signature-pad>
                  </v-col>
                  <v-col
                    v-if="viewOnly && fieldEntries.customer.isUnderage"
                    class="d-flex justify-center"
                    cols="12"
                  >
                    <v-img
                      max-width="200px"
                      :src="
                        fieldEntries.customer.legalRepresentative
                          .secondSignature
                      "
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </tab-content>
        </form-wizard>
      </v-row>
    </v-card>
    <register
      @register="newAccountRegistered"
      :auto-login="false"
      :requirePassword="publicContract"
      @close="showRegisterDialog = false"
      :active="showRegisterDialog"
    ></register>
    <login
      @login="accountLogin"
      :auto-login="false"
      @close="showLoginDialog = false"
      :active="showLoginDialog"
    ></login>
    <contract-minor-popup
      @acceptDialog="setMinorPopupAge"
      :active="showMinorPopup"
    ></contract-minor-popup>
  </v-dialog>
</template>
<script>
import api from "../../../api";
import userSearch from "../../../components/userSearch.vue";
import signaturePad from "../../../components/signaturePad/signaturePad.vue";
import FormWizard from "../../../components/vueStepWizard/FormWizard.vue";
import TabContent from "../../../components/vueStepWizard/TabContent.vue";
import Slim from "../../../plugins/slimImage/slim/slim.vue";
import userService from "../../../services/userService";
import register from "../../launchScreen/register.vue";
import login from "../../launchScreen/login.vue";
import contractMinorPopup from "./contractMinorPopup.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: {
    userSearch,
    FormWizard,
    TabContent,
    signaturePad,
    slimCropper: Slim,
    register,
    login,
    contractMinorPopup,
  },

  data() {
    return {
      isTakeoverContract: false,
      buttonsLoading: false,
      activeIndexContract: 0,
      isNewMember: false,
      activeIndexPackages: 0,
      showExternalPackages: false,
      externalPackagesAvailable: false,
      forceGiftSignupFee: false,
      externalPackages: [],
      preSale: false,
      showMinorPopup: false,
      publicContract: false,
      showRegisterDialog: false,
      showLoginDialog: false,
      userService,
      signatures: {
        conditions: false,
        contract: false,
      },
      signaturesCheck: {
        conditions: false,
        contract: false,
        conditionsLR: false,
        contractLR: false,
      },
      packageConfirmationDialog: null,
      showMembershipCategoryPicker: false,
      membershipCategory: null,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      startDatePicker: false,
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDatePicker: false,
      additionalInfoPricing: null,
      additionalImage: [],
      imageSources: {},
      currentStep: 0,
      dialog: true,
      contracts: [],
      contract: null,
      packageAmountSelectionDialog: null,
      conditionsHighlight: false,
      studio: null,
      loading: true,
      submitting: false,
      viewOnly: false,
      showDurationPicker: null,
      membershipFlexSelection: null,
      fieldEntries: {
        hasPurchasedPersonalTraining: false,
        contractId: null,
        studio: null,
        customer: {
          selection: null,
          signature: null,
          infos: null,
          isUnderage: false,
          legalRepresentative: {
            firstName: null,
            lastName: null,
            email: null,
            address: null,
            firstSignature: null,
            secondSignature: null,
          },
          contractCreator: null,
          contractCreatorInfos: null,
          additionalImage: [],
          additionalImages: [],
        },
        category: {
          isTakeover: false,
          takeover: {
            _id: null,
            firstName: null,
            lastName: null,
            email: null,
            signature: null,
          },
          isAffiliate: false,
          affiliateInfos: {
            _id: null,
            firstName: null,
            lastName: null,
            email: null,
          },
          selection: null,
          notesAnswer: "",
        },
        membership: {
          selection: null,
          selectedDuration: null,
          giftSignUpFee: false,
          giftCheapestPackage: false,
          renewalDiscount: false,
          renewalDiscountAmount: 0,
        },
        packages: [],
        conditions: { entries: null, signature: null },
        paymentOptions: {
          selection: null,
          selectedDate: null,
          additionalInfo: "",
        },
        createdAt: null,
      },
      slimOptions: {
        initialImage: "/img/einmalig/imageUploadPlaceholder.jpg",
        buttonCancelLabel: "Abbrechen",
        buttonConfirmLabel: "Bestätigen",
        label: "Bild hochladen",
        labelLoading: "Bild wird hochgeladen",
        statusUploadSuccess: "gespeichert",
        service: this.uploadPicture,
        serviceFormat: "file",
        instantEdit: true,
        push: true,
      },
      selectedFile: null,
      uploadStatus: "",
      bonusDuration: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.preventPageReload);
    this.fetchStudio();
    if (location.host.includes("localhost")) {
      this.updateCustomer("5f55fdad6716c132adf93baa");
      this.updateCreator("5f55fdad6716c132adf93baa");
    }
  },
  mounted() {
    if (this.navigationVisible) this.toggleNavigationBarVisibility();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventPageReload);
    if (!location.href.includes("dashboard") && !this.navigationVisible)
      this.toggleNavigationBarVisibility();
  },

  methods: {
    ...mapActions("navigationStoreNotPersisted", [
      "toggleNavigationBarVisibility",
      "setNavigationBarText",
      "setNavigationbarVisibility",
    ]),
    ...mapActions("userStore", ["setUserRights", "setUser"]),
    messeCalc(title, year) {
      if (title.includes("erwachsen")) {
        let years = {
          1: "890",
          2: "1579",
          3: "2199",
          4: "2749",
        };
        return years[year] || "";
      } else {
        let years = {
          1: "790",
          2: "1479",
          3: "2049",
          4: "2549",
        };
        return years[year] || "";
      }
    },
    addBonusDuration() {
      this.bonusDuration = !this.bonusDuration;
      if (!this.bonusDuration) {
        this.additionalInfoPricing = "";
      } else {
        if (parseInt(this.membershipInfos.duration.years) === 1) {
          this.additionalInfoPricing = "+1 Monat Bonuszeit";
        } else {
          this.additionalInfoPricing = "+2 Monate Bonuszeit";
        }
      }
    },
    resetAllSignatureChecks() {
      this.signaturesCheck = {
        conditions: false,
        contract: false,
        conditionsLR: false,
        contractLR: false,
      };
    },
    setSignatureConditions(status) {
      console.log("setting signature Conditions");
      this.signatures.conditions = status;

      this.signaturesCheck.conditions = status;
    },
    setSignatureConditionsLR(status) {
      this.signaturesCheck.conditionsLR = status;
    },
    setSignatureContract(status) {
      console.log("setting signature Contract");
      this.signatures.contract = status;
      this.signaturesCheck.contract = status;
    },
    setSignatureContractLR(status) {
      this.signaturesCheck.contractLR = status;
    },
    togglePersonalTraining() {
      this.fieldEntries.hasPurchasedPersonalTraining =
        !this.fieldEntries.hasPurchasedPersonalTraining;

      this.forceGiftSignupFee = this.fieldEntries.hasPurchasedPersonalTraining;
      this.fieldEntries.membership.giftSignUpFee =
        this.fieldEntries.hasPurchasedPersonalTraining;
      this.checkPackagePromotions();
    },
    alertThis(message) {
      alert(message);
    },
    disableEinsteinHydrojet(packageTitle) {
      if (!(this.studio && this.studio.code === "stgalleneinstein"))
        return false;
      if (packageTitle !== "Hydrojet") return false;
      if (
        !this.fieldEntries.packages.find(
          (p) => p.title === "Wellness/Spa plus"
        ) &&
        !this.fieldEntries.membership.selection.title.includes(
          "Wellness Spa Card"
        )
      )
        return true;
      return false;
    },
    async fetchImageDataSource(imageIndex) {
      let image = this.fieldEntries.customer.additionalImages[imageIndex];
      let src = image.src;

      try {
        const response = await api.fetchContractImageData(src);
        this.fieldEntries.customer.additionalImages[imageIndex].dataUrl =
          response;
        this.$forceUpdate();
      } catch (error) {
        this.$toast.error("Fehler beim laden des Bildes");
      }
    },

    deleteImageContract(index) {
      this.fieldEntries.customer.additionalImages.splice(index, 1);
      this.$forceUpdate();
    },

    uploadImagesContract(files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const formData = new FormData();
          formData.append("file", file);

          api
            .uploadContractImage(formData)
            .then((response) => {
              const uploadedImageName = response.name;

              if (!this.fieldEntries.customer.additionalImages)
                this.fieldEntries.customer.additionalImages = [];
              this.fieldEntries.customer.additionalImages.push({
                title: "",
                src: uploadedImageName,
                dataUrl: e.target.result,
              });
              this.$forceUpdate();
            })
            .catch((error) => {
              console.log("Upload failed", error);
            });
        };
        reader.readAsDataURL(file);
      }
    },
    isLocalhost() {
      return location.hostname.includes("localhost");
    },
    checkIfSale(membership) {
      let isSale = false;
      membership.forEach((e) => {
        let studioSettings = e.studioSettings[this.studio._id];
        if (studioSettings) {
          studioSettings.durations.forEach((d) => {
            if (d.saleActive) isSale = true;
          });
        }
      });
      return isSale;
    },
    logThis(e) {
      console.log(e);
    },
    scrollToIndexPackages(index) {
      let container = this.$refs.scrollContainer;
      if (Array.isArray(container)) container = container[0];
      const target = this.$refs.cards[index].$el;
      const rect = target.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const scrollLeft = rect.left - containerRect.left + container.scrollLeft;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    },
    updateActiveIndexPackages() {
      const cards = this.$refs.cards;
      for (let i = 0; i < cards.length; i++) {
        const card = cards[i].$el;
        const { left, right } = card.getBoundingClientRect();
        if (left < window.innerWidth / 2 && right > window.innerWidth / 2) {
          this.activeIndexPackages = i;
          break;
        }
      }
      // this.$forceUpdate()
    },
    logger(e) {
      console.log(e);
    },
    selectCategory(category) {
      if (
        this.fieldEntries.category.selection &&
        this.fieldEntries.category.selection.title &&
        this.fieldEntries.category.selection.title === category.title
      ) {
        this.isNewMember = false;
        this.fieldEntries.category.selection = null;
        this.fieldEntries.category.isTakeover = false;
        this.fieldEntries.category.takeover = {
          _id: null,
          firstName: null,
          lastName: null,
          email: null,
          signature: null,
        };
      } else {
        if (category.title === "Neumitglied" || category.title == "Ehemalig")
          this.isNewMember = true;
        else this.isNewMember = false;
        if (category.title === "Abonnementübernahme")
          this.fieldEntries.category.isTakeover = true;
        else this.fieldEntries.category.isTakeover = false;
        this.fieldEntries.category.takeover = {
          _id: null,
          firstName: null,
          lastName: null,
          email: null,
          signature: null,
        };
        this.fieldEntries.category.selection = category;
      }

      this.isTakeoverContract = this.fieldEntries.category.isTakeover;
    },
    shortenPackagePrintText(text) {
      if (text.length > 15) {
        return text.substr(0, 15) + "...";
      } else {
        return text;
      }
    },
    async accountLogin(login) {
      localStorage.setItem("token", login.token);
      localStorage.setItem("user", JSON.stringify(login.user));

      api.getUserRights().then((r) => {
        localStorage.setItem("userRights", JSON.stringify({ userRights: r }));
        this.setUserRights(JSON.parse(JSON.stringify(r)));
      });

      this.fieldEntries.customer.selection = login.user._id;
      let customer = await api.getUser();
      customer = customer.user;

      let customerEntry = {
        gender: customer.geschlecht,
        firstName: customer.vorname,
        lastName: customer.nachname,
        email: customer.email,
        phone: customer.telefon,
        birthday: customer.geburtstag,
        street: customer.adresse_1,
        city: customer.stadt,
        zip: customer.plz,
      };

      // this.resetLegalRepresentative()

      this.fieldEntries.customer.infos = customerEntry;
      if (
        !this.checkIfBirthdayIsValid(this.fieldEntries.customer.infos.birthday)
      ) {
        this.$toast.error("Bitte gib ein gültiges Geburtsdatum ein");
      }

      let contractCreatorInfos = {
        firstName: customer.vorname,
        lastName: customer.nachname + " " + "(Kunde)",
        email: customer.email,
      };

      this.fieldEntries.customer.contractCreator = customer._id;
      this.fieldEntries.customer.contractCreatorInfos = contractCreatorInfos;

      this.$forceUpdate();
      this.showLoginDialog = false;
    },
    async newAccountRegistered(data) {
      if (!this.publicContract) {
        this.showRegisterDialog = false;
        this.$toast.success(
          "Neuer Account erfolgreich erstellt. Du kannst den Kunden nun auswählen"
        );
      } else {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));

        api.getUserRights().then((r) => {
          localStorage.setItem("userRights", JSON.stringify({ userRights: r }));
          this.setUserRights(JSON.parse(JSON.stringify(r)));
        });

        this.fieldEntries.customer.selection = data.user._id;
        let customer = await api.getUser();
        customer = customer.user;

        let customerEntry = {
          gender: customer.geschlecht,
          firstName: customer.vorname,
          lastName: customer.nachname,
          email: customer.email,
          phone: customer.telefon,
          birthday: customer.geburtstag,
          street: customer.adresse_1,
          city: customer.stadt,
          zip: customer.plz,
        };

        //  this.resetLegalRepresentative()

        this.fieldEntries.customer.infos = customerEntry;
        if (
          !this.checkIfBirthdayIsValid(
            this.fieldEntries.customer.infos.birthday
          )
        ) {
          this.$toast.error("Bitte gib ein gültiges Geburtsdatum ein");
        }

        let contractCreatorInfos = {
          firstName: customer.vorname,
          lastName: customer.nachname + " " + "(Kunde)",
          email: customer.email,
        };

        this.fieldEntries.customer.contractCreator = customer._id;
        this.fieldEntries.customer.contractCreatorInfos = contractCreatorInfos;

        this.$forceUpdate();
        this.showRegisterDialog = false;
      }
    },
    formatPriceFixed(price) {
      let roundedPrice = parseFloat(price).toFixed(2);

      if (roundedPrice.endsWith(".00")) {
        roundedPrice = parseInt(roundedPrice, 10);
      } else {
        roundedPrice = parseFloat(roundedPrice);
      }
      return roundedPrice;
    },
    formatPriceFixedString(price) {
      let roundedPrice = parseFloat(price).toFixed(2);

      if (roundedPrice.endsWith(".00")) {
        roundedPrice = parseInt(roundedPrice, 10);
      } else {
        roundedPrice = parseFloat(roundedPrice).toFixed(2);
      }
      return roundedPrice;
    },
    saveAndCalculateDuration() {
      console.log("Calculate Duration");
      this.$refs.menu[0].save(this.startDate);
      this.calculateDuration(this.startDate);
    },
    preventPageReload(event) {
      // Check if the dialog is open, and if so, prevent the page reload

      event.preventDefault();
      event.returnValue = "";
    },
    giftSignUpFeeToggle() {
      if (this.forceGiftSignupFee) this.forceGiftSignupFee = false;
      if ("giftSignUpFee" in this.fieldEntries.membership) {
        this.fieldEntries.membership.giftSignUpFee =
          !this.fieldEntries.membership.giftSignUpFee;
      } else {
        this.fieldEntries.membership.giftSignUpFee = true;
      }
    },
    renewalDiscountToggle() {
      if ("renewalDiscount" in this.fieldEntries.membership) {
        this.fieldEntries.membership.renewalDiscount =
          !this.fieldEntries.membership.renewalDiscount;
      } else {
        this.fieldEntries.membership.renewalDiscount = true;
      }
      if (
        this.fieldEntries.membership.renewalDiscount &&
        this.contract.promotions.renewalDiscountAmount &&
        parseInt(this.contract.promotions.renewalDiscountAmount) > 0
      )
        this.fieldEntries.membership.renewalDiscountAmount =
          this.contract.promotions.renewalDiscountAmount;
    },

    resetCustomerPublicContract() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userRights");
      this.fieldEntries.customer.selection = null;
      this.fieldEntries.customer.contractCreator = null;
      this.fieldEntries.customer.contractCreatorInfos = null;
      this.resetCustomer();
    },

    resetCustomer() {
      this.fieldEntries.customer.infos = null;
      this.resetLegalRepresentative();
      this.fieldEntries.customer.isUnderage = false;
      this.fieldEntries.customer.selection = null;
    },

    changePackageAmount(amount) {
      this.packageAmountSelectionDialog.amount += amount;
      if (this.packageAmountSelectionDialog.amount === 0) {
        this.fieldEntries.packages = this.fieldEntries.packages.filter(
          (e) => e.title !== this.packageAmountSelectionDialog.title
        );
        this.packageAmountSelectionDialog = null;
      }
      this.$forceUpdate();
      this.checkPackagePromotions();
    },
    changeAffiliateStatus(val) {
      if (!val)
        this.fieldEntries.category.affiliateInfos = {
          _id: null,
          firstName: null,
          lastName: null,
          email: null,
        };
    },
    cancelMembershipCategorySelection() {
      this.showMembershipCategoryPicker = false;
      this.membershipCategory = null;
    },
    selectMembership(membershipCategory) {
      let fields = this.membershipFields[membershipCategory];
      this.membershipCategory = membershipCategory;
      if (fields.length > 1) this.showMembershipCategoryPicker = true;
      else this.selectOption("membership", fields[0]);
    },
    calculateDuration(paymentDate) {
      this.startDate = paymentDate;
      this.endDate = this.$moment(paymentDate);
      for (let key in this.membershipInfos.duration) {
        if (this.membershipInfos.duration[key] > 0) {
          this.endDate = this.endDate.add(
            key,
            this.membershipInfos.duration[key]
          );
        }
      }
      this.endDate = this.endDate.subtract(1, "days");
      if (this.$moment(this.startDate).format("MM-DD") === "02-29")
        this.endDate = this.endDate.add(1, "days");
      this.endDate = this.endDate.format("YYYY-MM-DD");
      this.checkExternalPackages();
    },
    saveSignatureLegalRepresentativeFirstSignature(signature) {
      this.fieldEntries.customer.legalRepresentative.firstSignature = signature;
      // this.submitContract()
    },
    saveSignatureLegalRepresentativeSecondSignature(signature) {
      this.fieldEntries.customer.legalRepresentative.secondSignature =
        signature;
      // this.submitContract()
    },
    saveSignatureContract(signature) {
      if (this.viewOnly) {
        alert("You can't sign a contract in view mode");
        return false;
      }

      console.log("save signature contract");

      this.fieldEntries.customer.signature = signature;
      // this.submitContract()
    },
    submitContract(temporary = false) {
      if (this.fieldEntries.paymentOptions.selection === null) {
        this.$toast.error("Bitte wähle eine Zahlungsbedingung aus");
        return false;
      }
      if (this.viewOnly) {
        return this.$router.go(-1);
      }

      if (!temporary && !this.publicContract) {
        let confirmConditions =
          this.fieldEntries.conditions.entries.inputValue.filter(
            (d) => d.highlight && !d.accepted
          );

        if (
          confirmConditions &&
          confirmConditions.length > 0 &&
          this.fieldEntries.conditions.signature === null
        )
          return alert(
            "Bitte prüfe die Unterschriften oder reiche den Vertrag temporär ein"
          );

        if (this.fieldEntries.customer.signature === null)
          return alert(
            "Bitte prüfe die Unterschriften oder reiche den Vertrag temporär ein"
          );
      }

      this.submitting = true;
      this.$refs.formWizard.setSubmit(true);

      this.fieldEntries.studio = this.studio;
      this.fieldEntries.startDate = this.startDate;

      this.fieldEntries.endDate = this.endDate;
      this.fieldEntries.additionalInfoPricing = this.additionalInfoPricing;

      if (this.messeContract) {
        let prefix = "";
        if (this.additionalInfoPricing && this.additionalInfoPricing.length > 0)
          prefix = this.additionalInfoPricing + ", ";

        let price =
          parseInt(this.getTotalPrice()) - parseInt(this.getTotalPriceMesse());

        let durationText =
          parseInt(this.membershipInfos.duration.years) === 1
            ? "im ersten Jahr!"
            : "in den ersten " +
              parseInt(this.membershipInfos.duration.years) +
              " Jahren!";

        this.fieldEntries.additionalInfoPricing =
          prefix +
          "MESSE ANGEBOT Du erhältst einen Rabatt von CHF " +
          price +
          ".- " +
          durationText;
      }

      // additional Pricing text is possible here

      if (this.membershipInfos.saleActive) {
        let durationText =
          parseInt(this.membershipInfos.duration.years) === 1
            ? "im ersten Jahr"
            : "in den ersten " +
              parseInt(this.membershipInfos.duration.years) +
              " Jahren";
        let signUpFeeText = "";
        /*    if (this.membershipInfos.saleActiveSignUpFeeGifted) {
                    signUpFeeText =
                        ' und den Aktivierungs-Tarif von CHF ' + this.membershipInfos.saleOldSignUpFee + '.- geschenkt'
                }*/
        let prefix = "";
        if (this.additionalInfoPricing && this.additionalInfoPricing.length > 0)
          prefix = this.additionalInfoPricing + ", ";

        this.fieldEntries.additionalInfoPricing =
          prefix +
          "AKTION Du erhältst einen Rabatt von CHF " +
          this.membershipInfos.salePriceDifference +
          ".- " +
          durationText +
          signUpFeeText +
          "!";
      }

      if (this.blackFridaySelectionValidation) {
        let prefix = "";
        if (this.additionalInfoPricing && this.additionalInfoPricing.length > 0)
          prefix = this.additionalInfoPricing + ", ";

        let price =
          parseInt(
            parseInt(this.getTotalPrice()) +
              parseInt(this.membershipInfos.signUpFee)
          ) -
          parseInt(
            parseInt(this.getTotalPrice()) -
              (parseInt(this.membershipInfos.price) -
                parseInt(
                  this.blackFridayDiscountCalculatorManual(
                    parseInt(this.membershipInfos.price) +
                      parseInt(this.membershipInfos.signUpFee)
                  )
                ))
          );

        let durationText =
          parseInt(this.membershipInfos.duration.years) === 1
            ? "im ersten Jahr!"
            : "in den ersten " +
              parseInt(this.membershipInfos.duration.years) +
              " Jahren!";

        this.fieldEntries.additionalInfoPricing =
          prefix +
          "AKTION BLACK FRIDAY Du erhältst einen Rabatt von CHF " +
          price +
          ".- " +
          durationText;
      }

      if (
        this.ostWellnessSpaPlusRabatt &&
        this.fieldEntries.packages
          .map((e) => e.title)
          .join(" ")
          .toLowerCase()
          .includes("spa plus")
      ) {
        let prefix = "";
        if (this.additionalInfoPricing && this.additionalInfoPricing.length > 0)
          prefix = this.additionalInfoPricing + ", ";
        this.fieldEntries.additionalInfoPricing =
          prefix +
          "CHF 100.- Rabatt auf das Zusatzpaket Wellness Spa/Plus im ersten Jahr";
      }

      this.fieldEntries.packages.forEach((p) => {
        p.frontEndPrice = this.formatPriceFixed(this.getPackageInfos(p).price);
        p.frontEndAmount =
          parseInt(this.getPackageInfos(p).amount) === 0
            ? 1
            : parseInt(this.getPackageInfos(p).amount);
      });
      this.fieldEntries.membership.selection.frontEndMembershipPrice =
        this.formatPriceFixed(this.membershipInfos.price);
      this.fieldEntries.membership.selection.frontEndSignUpFee =
        this.formatPriceFixed(this.membershipInfos.signUpFee);
      this.fieldEntries.membership.selection.frontEndDuration =
        this.membershipInfos.duration;

      this.fieldEntries.membership.selection.frontEndPriceTotal =
        this.getTotalPrice();

      if (this.preSale) this.fieldEntries.membership.selection.preSale = true;

      let contract = this.fieldEntries;

      let studioId = contract.studio._id;

      if (
        this.fieldEntries.customer.additionalImages &&
        this.fieldEntries.customer.additionalImages.length > 0
      ) {
        this.fieldEntries.customer.additionalImages.forEach((image) => {
          delete image.dataUrl;
        });
      }

      if (location.pathname.includes("edit")) {
        if (temporary) {
          contract.status = "temporary";
        }
        api
          .updateUserContract({
            contract,
            userId: this.$route.params.userId,
            index: this.$route.params.index,
          })
          .then((response) => {
            this.$toast.success("Vertrag wurde erfolgreich bearbeitet");
            this.submitting = false;

            this.$refs.formWizard.setSubmit(false);

            if (this.userService.hasRight("c_create")) {
              this.$router.push("/dashboard/contract/manager");
            } else {
              if (!temporary) this.$router.push("/dashboard/contract/rejected");
              else this.$router.push("/dashboard/contract/temporary");
            }
          })
          .catch((e) => {
            this.$toast.error("Vertrag konnte nicht erstellt werden");
            this.submitting = false;
            this.$refs.formWizard.setSubmit(false);
          });
      } else {
        if (this.publicContract) {
          api
            .createUserContractSelf(contract)
            .then((response) => {
              this.$toast.success("Vertrag wurde erfolgreich erstellt");
              this.submitting = false;
              this.$refs.formWizard.setSubmit(false);
              this.$router.push("/user?contractCreated=true");
            })
            .catch((e) => {
              this.$toast.error("Vertrag konnte nicht erstellt werden");
              this.submitting = false;
              this.$refs.formWizard.setSubmit(false);
            });
        } else {
          if (!temporary) {
            api
              .createUserContract(contract)
              .then((response) => {
                this.$toast.success("Vertrag wurde erfolgreich erstellt");
                this.submitting = false;
                this.$refs.formWizard.setSubmit(false);
                this.$router.push("/dashboard/contract");
              })
              .catch((e) => {
                this.$toast.error("Vertrag konnte nicht erstellt werden");
                this.submitting = false;
                this.$refs.formWizard.setSubmit(false);
              });
          } else {
            api
              .createTemporaryUserContract(contract)
              .then((response) => {
                this.$toast.success("Vertrag wurde erfolgreich erstellt");
                this.submitting = false;
                this.$refs.formWizard.setSubmit(false);
                this.$router.push("/dashboard/contract");
              })
              .catch((e) => {
                this.$toast.error("Vertrag konnte nicht erstellt werden");
                this.submitting = false;
                this.$refs.formWizard.setSubmit(false);
              });
          }
        }
      }
    },
    resetLegalRepresentative() {
      this.fieldEntries.customer.legalRepresentative = {
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        firstSignature: null,
        secondSignature: null,
      };
    },
    setMinorPopupAge(status) {
      if (status) this.fieldEntries.customer.isUnderage = true;
      this.showMinorPopup = false;
    },
    checkIfUnderage(birthday) {
      let age = this.$moment().diff(birthday, "years");

      if (age < 18) {
        this.fieldEntries.customer.isUnderage = true;
      } else {
        this.fieldEntries.customer.isUnderage = false;
      }

      this.$forceUpdate();
    },
    saveSignatureTakeover(signature) {
      console.log("save signature takeover");
      this.fieldEntries.category.takeover.signature = signature;
    },
    saveSignatureConditions(signature) {
      console.log("save signature conditions");

      this.fieldEntries.conditions.signature = signature;
    },
    async validateStep(step) {
      let formFields = this.contractFields[step];

      let confirmConditions =
        this.fieldEntries.conditions.entries.inputValue.filter(
          (d) => d.highlight && !d.accepted
        );

      if (this.publicContract && !this.viewOnly) {
        if (formFields.inputType === "endPage") {
          if (!this.signatures.contract) {
            this.$toast.error("Bitte unterschreibe den Vertrag");
            return false;
          }
        }
        if (
          formFields.inputType === "conditions" &&
          this.conditionsHighlight &&
          confirmConditions &&
          confirmConditions.length > 0
        ) {
          if (!this.signatures.conditions) {
            this.$toast.error("Bitte unterschreibe die Vertragsbedingungen");
            return false;
          }
        }

        if (
          formFields.inputType === "conditions" &&
          !this.conditionsHighlight &&
          !confirmConditions
        ) {
          if (!this.signatures.conditions) {
            this.$toast.error("Bitte unterschreibe die Vertragsbedingungen");
            return false;
          }
        }
      }

      if (
        formFields.inputType === "packages" &&
        this.externalPackagesAvailable &&
        !this.showExternalPackages
      ) {
        this.showExternalPackages = true;
        return false;
      }

      if (
        formFields.inputType === "packages" &&
        this.externalPackagesAvailable &&
        this.showExternalPackages
      ) {
        this.showExternalPackages = false;
      }

      if (formFields.inputType === "duration" && this.startDatePicker) {
        this.$toast.error(
          "Bitte speichere zuerst das Startdatum durch einen Klick auf 'OK'"
        );
        return false;
      }
      if (
        false &&
        location.host.includes("localhost") &&
        formFields.inputType !== "conditions" &&
        formFields.inputType !== "endPage"
      )
        return true;

      if (
        formFields.inputType === "category" &&
        this.fieldEntries.category.isAffiliate &&
        (this.fieldEntries.category.affiliateInfos.firstName === null ||
          this.fieldEntries.category.affiliateInfos.lastName === null)
      ) {
        this.$toast.error(
          "Bitte fülle die benötigten Informationen zur Weiterempfehlung aus"
        );
        return false;
      }

      if (
        formFields.inputType === "category" &&
        this.fieldEntries.category.isTakeover &&
        (this.fieldEntries.category.takeover.firstName === null ||
          this.fieldEntries.category.takeover.lastName === null)
      ) {
        this.$toast.error(
          "Bitte fülle die benötigten Informationen zur Abonnementübernahme aus"
        );
        return false;
      }

      if (
        formFields.inputType === "category" &&
        this.fieldEntries.category.isTakeover &&
        !this.viewOnly
      ) {
        this.$refs.signatureTakeover[0].saveSignature();
      }

      if (
        formFields.inputType === "category" &&
        this.fieldEntries.category.isTakeover &&
        this.fieldEntries.category.takeover.signature === null
      ) {
        this.$toast.error("Bitte unterschreibe die Abonnementübernahme");
        return false;
      }

      /*
            
            
            */
      if (
        formFields.inputType === "category" &&
        this.fieldEntries.category.selection &&
        this.fieldEntries.category.selection.notes &&
        !this.fieldEntries.category.selection.notesAnswer
      ) {
        this.$toast.error(
          "Bitte beantworte die Frage: " +
            this.fieldEntries.category.selection.additionalInfo
        );
        return false;
      }

      if (formFields.inputType === "customer") {
        if (this.fieldEntries.customer.selection === null) {
          this.$toast.error("Bitte wählen Sie einen Kunden aus");
          return false;
        }

        if (this.fieldEntries.customer.infos === null) {
          this.$toast.error("Bitte wählen Sie einen Kunden aus");
          return false;
        }
        if (this.fieldEntries.customer.contractCreator === null) {
          this.$toast.error("Bitte wählen Sie einen Vertragsersteller aus");
          return false;
        }

        if (
          !this.checkIfBirthdayIsValid(
            this.fieldEntries.customer.infos.birthday
          )
        ) {
          this.$toast.error("Bitte gib ein gültiges Geburtsdatum ein");
          return false;
        } else {
          this.checkIfUnderage(this.fieldEntries.customer.infos.birthday);
        }

        if (
          !this.fieldEntries.customer.infos.gender ||
          !this.fieldEntries.customer.infos.gender.length > 0 ||
          this.fieldEntries.customer.infos.gender.toString() === "a"
        ) {
          this.$toast.error("Bitte wähle eine Anrede aus");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.firstName &&
            this.fieldEntries.customer.infos.firstName.length > 1
          )
        ) {
          this.$toast.error("Bitte gib einen gültigen Vornamen ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.lastName &&
            this.fieldEntries.customer.infos.lastName.length > 1
          )
        ) {
          this.$toast.error("Bitte gib einen gültigen Nachnamen ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.phone &&
            this.fieldEntries.customer.infos.phone.length > 4
          )
        ) {
          this.$toast.error("Bitte gib eine gültige Telefonnummer ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.street &&
            this.fieldEntries.customer.infos.street.length > 2
          )
        ) {
          this.$toast.error("Bitte gib eine gültige Strasse ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.city &&
            this.fieldEntries.customer.infos.city.length > 2
          )
        ) {
          this.$toast.error("Bitte gib eine gültige Adresse ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.zip &&
            this.fieldEntries.customer.infos.zip.length > 3
          )
        ) {
          this.$toast.error("Bitte gib eine gültige PLZ ein");
          return false;
        }

        if (
          !(
            this.fieldEntries.customer.infos.email &&
            this.fieldEntries.customer.infos.email.length > 2 &&
            this.fieldEntries.customer.infos.email.includes("@") &&
            this.fieldEntries.customer.infos.email.includes(".")
          )
        ) {
          this.$toast.error("Bitte gib eine Gültige E-Mail ein");
          return false;
        }

        // updateUserInfoHere
        try {
          let status = await api.editUserProfileFromContract({
            data: {
              infos: this.fieldEntries.customer.infos,
              userId: this.fieldEntries.customer.selection,
            },
          });

          if (!status.success && status.message)
            this.$toast.error(status.message);
          if (!status.success) return false;
        } catch (e) {
          this.$toast.error(
            "Fehler beim aktualisieren des Profils, bitte versuche es erneut"
          );
          return false;
        }
      }

      if (
        formFields.required &&
        "selection" in this.fieldEntries[formFields.inputType] &&
        this.fieldEntries[formFields.inputType].selection !== null
      ) {
        return true;
      }

      if (formFields.inputType === "endPage" && !this.viewOnly) {
        this.$refs.signatureContract[0].saveSignature();
        if (this.fieldEntries.customer.isUnderage) {
          this.$refs.signatureConditionsLegalRepresentativeSecond[0].saveSignature();
        }
      }

      if (
        formFields.inputType === "conditions" &&
        this.fieldEntries.conditions.entries !== null &&
        this.fieldEntries.conditions.entries.inputValue
      ) {
        let confirmConditions =
          this.fieldEntries.conditions.entries.inputValue.filter(
            (d) => d.highlight && !d.accepted
          );

        if (confirmConditions && confirmConditions.length > 0) {
          if (this.conditionsHighlight) {
            if (this.viewOnly) {
              return true;
            } else {
              this.$refs.signatureConditions[0].saveSignature();
              if (this.fieldEntries.customer.isUnderage) {
                this.$refs.signatureConditionsLegalRepresentativeFirst[0].saveSignature();
              }

              this.nextStepNoValidation(this.currentStep);
              return false;
            }
          } else {
            if (!this.viewOnly)
              this.$toast.info("Bitte bestätigen Sie die Bedingungen");

            this.conditionsHighlight = true;
            return false;
          }
        } else {
          return true;
        }
      } else if (
        formFields.inputType === "conditions" &&
        this.fieldEntries.conditions.signature !== null &&
        !this.conditionsHighlight
      ) {
        this.conditionsHighlight = true;
        return false;
      } else if (
        formFields.inputType === "conditions" &&
        this.fieldEntries.conditions.signature !== null &&
        this.conditionsHighlight &&
        !this.viewOnly
      ) {
        // this.$refs.signatureConditions[0].saveSignature()
        // this.nextStep(this.currentStep)
        this.conditionsHighlight = false;
        return true;
      }

      if (!formFields.required) return true;
      if (
        formFields.inputType === "conditions" &&
        this.fieldEntries.conditions.signature !== null
      )
        return true;
      this.$toast.error("Bitte füllen Sie alle Pflichtfelder aus", "Fehler");

      return false;
    },
    async nextStepNoValidation(step) {
      this.buttonsLoading = true;
      this.currentStep = step;
      let indexOfPricing = this.contractFields.findIndex(
        (e) => e.inputType === "pricing"
      );
      this.$refs.formWizard._switchTab(step + 1);
      this.buttonsLoading = false;
      if (step + 1 === indexOfPricing) this.$forceUpdate();
    },
    async nextStep(step) {
      this.buttonsLoading = true;
      this.currentStep = step;
      let indexOfPricing = this.contractFields.findIndex(
        (e) => e.inputType === "pricing"
      );
      if (await this.validateStep(step))
        this.$refs.formWizard._switchTab(step + 1);
      this.buttonsLoading = false;
      if (step + 1 === indexOfPricing) this.$forceUpdate();
    },
    previousStep(step) {
      this.currentStep = step;
      this.conditionsHighlight = false;
      this.showExternalPackages = false;
      let indexOfPricing = this.contractFields.findIndex(
        (e) => e.inputType === "pricing"
      );
      if (step === indexOfPricing) this.$forceUpdate();
    },
    submit() {
      if (!this.viewOnly) {
        this.$refs.signatureContract[0].saveSignature();
        if (this.fieldEntries.customer.isUnderage) {
          this.$refs.signatureConditionsLegalRepresentativeSecond[0].saveSignature();
        }
      } else {
        this.$router.go(-1);
      }
    },
    cancel() {
      if (this.$router.history) {
        this.$router.go(-1);
      }
    },
    getFlexText(value) {
      let text = "";
      if (value.flexActive && value.flexSelection) {
        if (value.flexSelection.toString() === "perMonth") text = "pro Monat";
        else text = "pro Tag";
      }
      return text;
    },
    getPackageInfos(packag, checkPromotions = true) {
      if (
        !this.fieldEntries.membership.selectedDuration ||
        !this.fieldEntries.membership.selection
      )
        return 0;

      let price = 0;
      let pricePerWeek = 0;
      let amount = 0;
      let gifted = false;

      if (this.fieldEntries.membership.selection.flexActive) {
        let flexSelection =
          this.fieldEntries.membership.selection.flexSelection.toString() ===
          "perDay"
            ? "days"
            : "months";
        // days
        let packageFlex = packag.studioSettings[
          this.studio._id
        ].memberships.find(
          (e) =>
            e.title.toString() === this.fieldEntries.membership.selection.title
        ).packageFlex;

        price =
          this.formatPriceFixed(packageFlex.price) *
          parseInt(
            this.fieldEntries.membership.selectedDuration[flexSelection]
          );

        if (
          Object.keys(packageFlex).includes("max") &&
          packageFlex.max > 0 &&
          price > parseInt(packageFlex.max)
        )
          price = packageFlex.max;

        if (
          packag.amountSelectable &&
          this.fieldEntries.packages.find((e) => e.title === packag.title)
        ) {
          amount = this.fieldEntries.packages.find(
            (e) => e.title === packag.title
          ).amount;
          price = parseInt(amount) * this.formatPriceFixed(price);
        }

        let amountt =
          this.fieldEntries.membership.selectedDuration[flexSelection];
        if (flexSelection === "months") {
          let weekamount = 4 * amountt;
          pricePerWeek = price / weekamount;
        } else {
          let weekamount = amountt / 7;
          pricePerWeek = price / weekamount;
        }
        if (Math.ceil(pricePerWeek) !== pricePerWeek)
          pricePerWeek = "weniger als " + Math.ceil(pricePerWeek);
      } else {
        let selectedMembershipDurations =
          this.fieldEntries.membership.selectedDuration;

        let packageDurationsMembership = packag.studioSettings[
          this.studio._id
        ].memberships.find(
          (m) => m.title === this.fieldEntries.membership.selection.title
        );

        if (
          packageDurationsMembership &&
          packageDurationsMembership.packageDurations
        ) {
          let packageDurations = packageDurationsMembership.packageDurations;

          let priceFound = packageDurations.find(
            (pd) =>
              parseInt(pd.days || 0) ===
                parseInt(selectedMembershipDurations.days || 0) &&
              parseInt(pd.months || 0) ===
                parseInt(selectedMembershipDurations.months || 0) &&
              parseInt(pd.years || 0) ===
                parseInt(selectedMembershipDurations.years || 0)
          );

          if (priceFound) price = priceFound.packagePrice;

          if (
            packag.amountSelectable &&
            this.fieldEntries.packages.find((e) => e.title === packag.title)
          ) {
            amount = this.fieldEntries.packages.find(
              (e) => e.title === packag.title
            ).amount;
            price = parseInt(amount) * this.formatPriceFixed(price);
          }

          let durationMapped = {
            days: selectedMembershipDurations.days,
            months: selectedMembershipDurations.months,
            years: selectedMembershipDurations.years,
          };

          let startDate = this.$moment().set({
            minutes: 0,
            hours: 0,
            seconds: 0,
            milliseconds: 0,
          });
          let endDate = this.$moment().set({
            minutes: 0,
            hours: 0,
            seconds: 0,
            milliseconds: 0,
          });
          for (let key in durationMapped) {
            if (durationMapped[key] > 0) {
              endDate = endDate.add(key, durationMapped[key]);
            }
          }
          // get amount of weeks between startDate and endDate
          let weekamount = endDate.diff(startDate, "weeks");

          pricePerWeek = price / weekamount;
          if (Math.ceil(pricePerWeek) !== pricePerWeek)
            pricePerWeek = "weniger als " + Math.ceil(pricePerWeek);
        }
      }

      if (
        checkPromotions &&
        this.contract.promotions &&
        this.contract.promotions.giftPackageAdditionalPackages
      ) {
        let packageLength = this.fieldEntries.packages.length;
        if (
          packageLength >=
          this.contract.promotions.giftPackageAdditionalPackagesCount
        ) {
          if (
            this.fieldEntries.packages.find(
              (e) => e.title.toString() === packag.title.toString()
            )
          ) {
            let packageArray = this.fieldEntries.packages.map((p) => {
              return {
                packageTitle: p.title,
                packagePrice: this.formatPriceFixed(
                  this.getPackageInfos(p, false).price
                ),
              };
            });
            let cheapestPackage = packageArray[0];

            for (let i = 1; i < packageArray.length; i++) {
              if (packageArray[i].packagePrice < cheapestPackage.packagePrice) {
                cheapestPackage = packageArray[i];
              }
            }

            if (
              cheapestPackage.packageTitle.toString() ===
              packag.title.toString()
            ) {
              gifted = true;
            }
          }
        }
      }

      price = this.formatPriceFixed(price);

      return { price, amount, pricePerWeek, gifted };
    },
    unselectOption(key) {
      this.membershipFlexSelection = null;

      this.fieldEntries[key].selection = null;
      this.fieldEntries[key].selectedDuration = null;
      this.showDurationPicker = null;
      if (key === "membership") this.fieldEntries.packages = [];
      if (key === "membership") this.showExternalPackages = false;
      if (key === "membership") this.externalPackagesAvailable = false;
    },
    selectOption(key, value) {
      if (key === "membership") this.showMembershipCategoryPicker = false;
      if (key === "membership") this.membershipCategory = null;

      this.fieldEntries[key].selection = value;
      this.fieldEntries[key].selectedDuration = null;

      if (
        key === "membership" &&
        (value.flexActive ||
          (value.studioSettings[this.studio._id] &&
            value.studioSettings[this.studio._id].durations.length > 0))
      ) {
        this.showDurationPicker = value;
        this.showDurationPicker.optionType = key;
      }

      if (key === "membership") this.fieldEntries.packages = [];
      if (key === "membership") this.showExternalPackages = false;
      if (key === "membership") this.externalPackagesAvailable = false;

      if (
        key === "membership" &&
        (this.preSale ||
          this.forceGiftSignupFee ||
          this.blackFridaySelectionValidation)
      ) {
        this.fieldEntries.membership.giftSignUpFee = true;
      }

      // if (key === 'membership') this.calculateDuration(this.startDate)
    },
    checkExternalPackages() {
      let field = this.contractFields.find((e) => e.inputType === "packages");
      let realField = this.contract.formFields.find(
        (e) => e.inputType === "packages"
      );

      /*let pushIt = true
            if (realField.inputValue.find((rf) => rf.isExternalPackage)) {
                pushIt = false
            }*/
      realField.inputValue = realField.inputValue.filter(
        (rf) => !rf.isExternalPackage
      );

      let allPackages = field.inputValue.filter(
        (e) =>
          e.studios.includes(this.studio._id) &&
          this.studio._id in e.studioSettings &&
          e.studioSettings[this.studio._id].memberships.find(
            (m) =>
              m.title.toString() ===
              this.fieldEntries.membership.selection.title.toString()
          )
      );

      let newPackagesMembership = this.externalPackages.find(
        (e) =>
          e.title.toString() ===
          this.fieldEntries.membership.selection.title.toString()
      );

      let selection = this.fieldEntries.membership.selectedDuration;

      let newPackages = [];

      if (newPackagesMembership.flexActive) {
        let exPackages =
          newPackagesMembership.studioSettings.flex.externalPackages;
        if (exPackages)
          for (let pack of Object.keys(exPackages)) {
            if (realField.inputValue.map((e) => e.title).includes(pack))
              continue;
            let newPackageMapped = {
              isExternalPackage: true,
              title: pack,
              price: 0,
              packageStudios: exPackages[pack].studios,
              studios: [this.studio._id],
              index: 20,
              studioSettings: {},
              hideForMinors: newPackagesMembership.hideForMinors,
              flexActive: newPackagesMembership.flexActive,
              flexSelection:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .package.selection,
              packageFlex:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .package.packageFlex,
              flexLimits:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .package.flexLimits,
              amountSelectable:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .infos.amountSelectable,
              description:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .infos.description,
              gallery:
                newPackagesMembership.studioSettings.flex.externalPackages[pack]
                  .infos.gallery,
              active: true,
            };
            let membershipStudioSettings = JSON.parse(
              JSON.stringify(this.fieldEntries.membership.selection)
            );
            membershipStudioSettings.packageFlex =
              newPackagesMembership.studioSettings.flex.externalPackages[
                pack
              ].package.packageFlex;

            let prices = {
              perDay: {
                price: 0,
                signUpFee: 0,
              },
              perMonth: {
                price: 0,
                signUpFee: 0,
              },
              perYear: {
                price: 0,
                signUpFee: 0,
              },
            };
            prices[
              newPackagesMembership.studioSettings.flex.externalPackages[
                pack
              ].package.selection
            ].price =
              newPackagesMembership.studioSettings.flex.externalPackages[
                pack
              ].package.packageFlex.price;

            newPackageMapped.studioSettings[this.studio._id] = {
              flex: {
                prices,
              },
              memberships: [membershipStudioSettings],
            };

            newPackages.push(newPackageMapped);
          }
      } else {
        let duration = newPackagesMembership.studioSettings.durations.find(
          (psd) =>
            parseInt(psd.days || 0) === parseInt(selection.days || 0) &&
            parseInt(psd.months || 0) === parseInt(selection.months || 0) &&
            parseInt(psd.years || 0) === parseInt(selection.years || 0)
        );

        console.log("DURATION", duration);

        let externalPackages = duration.externalPackages;
        if (externalPackages)
          for (let pack of Object.keys(externalPackages)) {
            if (realField.inputValue.map((e) => e.title).includes(pack))
              continue;
            let newPackageMapped = {
              isExternalPackage: true,
              title: pack,
              price: 0,
              packageStudios: duration.externalPackages[pack].studios,
              studios: [this.studio._id],
              index: 20,
              hideForMinors: externalPackages[pack].infos.hideForMinors,
              studioSettings: {},
              flexActive: externalPackages[pack].infos.flexActive,
              flexSelection: externalPackages[pack].infos.flexSelection,
              amountSelectable: externalPackages[pack].infos.amountSelectable,
              description: externalPackages[pack].infos.description,
              gallery: externalPackages[pack].infos.gallery,
              active: true,
            };
            let membershipStudioSettings = JSON.parse(
              JSON.stringify(this.fieldEntries.membership.selection)
            );
            membershipStudioSettings.packageDurations = [];
            membershipStudioSettings.packageDurations.push({
              days: duration.days,
              months: duration.months,
              years: duration.years,
              packagePrice: duration.externalPackages[pack].price,
              packageStudios: duration.externalPackages[pack].studios,
              price: duration.price,
              signUpFee: duration.signUpFee,
            });

            newPackageMapped.studioSettings[this.studio._id] = {
              flex: {
                prices: {
                  perDay: {
                    price: 0,
                    signUpFee: 0,
                  },
                  perMonth: {
                    price: 0,
                    signUpFee: 0,
                  },
                  perYear: {
                    price: 0,
                    signUpFee: 0,
                  },
                },
              },
              memberships: [membershipStudioSettings],
            };

            newPackages.push(newPackageMapped);
          }
      }

      if (newPackages.length !== 0) this.externalPackagesAvailable = true;
      else this.externalPackagesAvailable = false;
      realField.inputValue = realField.inputValue.concat(newPackages);
    },
    selectDurationFlex(key) {
      let duration = this.membershipFlexSelection;

      if (duration === null)
        return this.$toast.error("Bitte wähle eine Dauer aus");

      if (this.showDurationPicker.flexSelection.toString() === "perDay") {
        // days
        this.fieldEntries[key].selectedDuration = {
          days: duration,
          months: 0,
          years: 0,
        };
      } else {
        // months
        this.fieldEntries[key].selectedDuration = {
          days: 0,
          months: duration,
          years: 0,
        };
      }

      this.calculateDuration(this.startDate);
      this.showDurationPicker = null;
    },
    selectDuration(key, duration) {
      this.fieldEntries[key].selectedDuration = duration;
      this.showDurationPicker = null;

      if (
        this.preSale ||
        this.forceGiftSignupFee ||
        this.blackFridaySelectionValidation
      ) {
        this.fieldEntries.membership.giftSignUpFee = true;
      }
      this.calculateDuration(this.startDate);
    },
    removePackage(packag) {
      this.packageConfirmationDialog = null;
      packag.amount = 0;

      if (
        packag.title === "Wellness/Spa plus" &&
        this.studio &&
        this.studio.code === "stgalleneinstein"
      ) {
        this.fieldEntries.packages = this.fieldEntries.packages.filter(
          (e) => e.title !== "Hydrojet"
        );
      }

      this.fieldEntries.packages = this.fieldEntries.packages.filter(
        (e) => e.title !== packag.title
      );
      this.checkPackagePromotions();
      //  if (title === this.packageAmountSelectionDialog.title) this.packageAmountSelectionDialog = null
      this.$forceUpdate();
    },
    checkPackagePromotions() {
      if (this.isTakeoverContract) return;
      let promotionFound = false;
      if (
        this.contract.promotions &&
        this.contract.promotions.giftSignUpFeeIfPackagesPriceHit &&
        this.contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount &&
        parseInt(
          this.contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount
        ) > 0
      ) {
        let giftSignUpFeePackagesPriceHitAmount = parseInt(
          this.contract.promotions.giftSignUpFeeIfPackagesPriceHitAmount
        );
        let price = this.getPackagesTotal();

        if (price >= giftSignUpFeePackagesPriceHitAmount) {
          this.fieldEntries.membership.giftSignUpFee = true;
          promotionFound = true;
        } else {
          this.fieldEntries.membership.giftSignUpFee = false;
        }
      }
      if (
        this.contract.promotions &&
        this.contract.promotions.giftSignUpFeeIfAdditionalPackages
      ) {
        let length = this.fieldEntries.packages.length;
        if (
          length >=
          this.contract.promotions.giftSignUpFeeIfAdditionalPackagesCount
        ) {
          if (false && !this.fieldEntries.membership.giftSignUpFee)
            this.$toast.success(
              "Du erhältst den Aktivierungs-Tarif geschenkt!"
            );

          this.fieldEntries.membership.giftSignUpFee = true;
        } else {
          if (false && this.fieldEntries.membership.giftSignUpFee)
            this.$toast.error(
              "Wenn Du " +
                this.contract.promotions
                  .giftSignUpFeeIfAdditionalPackagesCount +
                " Zusatzpakete wählst, schenken wir dir den Aktivierungs-Tarif!"
            );
          this.fieldEntries.membership.giftSignUpFee = false;
        }
      } else if (!promotionFound) {
        this.fieldEntries.membership.giftSignUpFee = false;
      }

      if (
        this.preSale ||
        this.forceGiftSignupFee ||
        this.blackFridaySelectionValidation
      ) {
        this.fieldEntries.membership.giftSignUpFee = true;
      }
    },
    addPackage(packag) {
      if (packag.studioSettings[this.studio._id].showAdditionalConfirmation) {
        this.packageConfirmationDialog = packag;
      }

      if (
        this.studio.code === "pfungen" &&
        packag.title.toLowerCase().includes("milon")
      ) {
        this.packageConfirmationDialog = {
          studioSettings: {
            [this.studio._id]: {
              additionalConfirmationText:
                "Am Standort Winterthur steht ein Milon Kraftzirkel für dein Training zur Verfügung.",
            },
          },
        };
      }

      if (packag.amountSelectable) {
        packag.amount = 1;
        this.packageAmountSelectionDialog = packag;
      }

      if (packag.title.toLowerCase().includes("wellness"))
        this.fieldEntries.packages = this.fieldEntries.packages.filter(
          (e) => !e.title.toLowerCase().includes("wellness")
        );

      this.fieldEntries.packages.push(packag);

      this.checkPackagePromotions();
    },
    updateAmount(amount, title) {
      if (parseInt(amount) === 0)
        this.fieldEntries.packages = this.fieldEntries.packages.filter(
          (e) => e.title !== title
        );
      else
        this.fieldEntries.packages.find((e) => e.title === title).amount =
          amount;
    },
    async updateCreator(id) {
      this.fieldEntries.customer.contractCreator = id;
      let contractCreator = await api.getUserForManager(id);
      let contractCreatorInfos = {
        firstName: contractCreator.vorname,
        lastName: contractCreator.nachname,
        email: contractCreator.email,
      };

      this.fieldEntries.customer.contractCreatorInfos = contractCreatorInfos;

      this.$forceUpdate();
    },
    updateCustomerBirthday(birthday) {},
    async updateCustomer(id) {
      this.fieldEntries.customer.selection = id;
      let customer = await api.getUserForManager(id);
      let customerEntry = {
        gender: customer.geschlecht,
        firstName: customer.vorname,
        lastName: customer.nachname,
        email: customer.email,
        phone: customer.telefon,
        birthday: customer.geburtstag,
        street: customer.adresse_1,
        city: customer.stadt,
        zip: customer.plz,
      };

      this.resetLegalRepresentative();

      this.fieldEntries.customer.infos = customerEntry;

      if (
        !this.checkIfBirthdayIsValid(this.fieldEntries.customer.infos.birthday)
      ) {
        this.$toast.error("Bitte gib ein gültiges Geburtsdatum ein");
      }

      this.$forceUpdate();
    },
    async updateTakeover(id) {
      this.fieldEntries.category.takeover._id = id;
      let customer = await api.getUserForManager(id);

      this.fieldEntries.category.takeover.firstName = customer.vorname;
      this.fieldEntries.category.takeover.lastName = customer.nachname;
      this.fieldEntries.category.takeover.email = customer.email;
      this.fieldEntries.category.takeover.signature = null;

      this.$forceUpdate();
    },
    async updateAffiliate(id) {
      this.fieldEntries.category.affiliateInfos._id = id;
      let customer = await api.getUserForManager(id);

      this.fieldEntries.category.affiliateInfos.firstName = customer.vorname;
      this.fieldEntries.category.affiliateInfos.lastName = customer.nachname;
      this.fieldEntries.category.affiliateInfos.email = customer.email;

      this.$forceUpdate();
    },
    checkIfBirthdayIsValid(birthday) {
      return this.$moment(birthday, "YYYY-MM-DD", true).isValid();
    },
    async fetchStudio() {
      if (this.$route.params.studioId) {
        this.publicContract = true;

        let studios = await api.fetchPublicStudios();
        let studio = studios.find(
          (e) => e._id.toString() === this.$route.params.studioId.toString()
        );
        this.studio = studio;

        if (localStorage.getItem("token")) {
          try {
            let customer = await api.getUser();
            customer = customer.user;
            this.fieldEntries.customer.selection = customer._id;

            let customerEntry = {
              gender: customer.geschlecht,
              firstName: customer.vorname,
              lastName: customer.nachname,
              email: customer.email,
              phone: customer.telefon,
              birthday: customer.geburtstag,
              street: customer.adresse_1,
              city: customer.stadt,
              zip: customer.plz,
            };

            this.resetLegalRepresentative();

            this.fieldEntries.customer.infos = customerEntry;

            let contractCreatorInfos = {
              firstName: customer.vorname,
              lastName: customer.nachname + " " + "(Kunde)",
              email: customer.email,
            };

            this.fieldEntries.customer.contractCreator = customer._id;
            this.fieldEntries.customer.contractCreatorInfos =
              contractCreatorInfos;

            this.$forceUpdate();
          } catch (e) {
            console.log("token expired");
          }
        } else {
          this.showMinorPopup = true;
        }
      } else {
        let studio = await api.getUserStudio();
        this.studio = studio.studio;
      }

      // new studios gift signup fee temportary 1 year start
      let newStudioArray = [
        "wallisellen",
        "wetzikon",
        "oerlikon",
        "basel",
        "rikon",
        "obfelden",
      ];

      const today = this.$moment();

      // Ziel-Datum zum Vergleich
      const targetDate = this.$moment("2024-03-01");

      if (today.isSameOrAfter(targetDate, "day")) {
        newStudioArray = ["obfelden"];
        // new studios gift signup fee temportary 1 year end
        // vorverkauf schwnaden / sg ost
        if (this.publicContract) {
          if (
            this.studio.code === "arbon" &&
            this.$route.params.skipPresale === "false"
          ) {
            console.log("hoi");
            this.preSale = true;
            this.forceGiftSignupFee = true;
            this.$forceUpdate();
          }
        }
      }

      if (newStudioArray.includes(this.studio.code)) {
        this.forceGiftSignupFee = true;
      }

      if (this.$route.params.userId) {
        let user = await api.fetchUserContract({
          userId: this.$route.params.userId,
        });
        if (
          user &&
          user.contracts &&
          user.contracts[this.$route.params.index]
        ) {
          if (user.contracts[this.$route.params.index].publicContract)
            this.publicContract = true;
          let fieldEntries = user.contracts[this.$route.params.index];
          if (
            !Object.keys(fieldEntries).includes("hasPurchasedPersonalTraining")
          ) {
            fieldEntries.hasPurchasedPersonalTraining = false;
          }
          this.startDate = fieldEntries.startDate.toString();
          this.endDate = fieldEntries.endDate.toString();
          this.additionalInfoPricing = fieldEntries.additionalInfoPricing
            ? fieldEntries.additionalInfoPricing.toString()
            : "";

          delete fieldEntries.startDate;
          delete fieldEntries.endDate;
          delete fieldEntries.additionalInfoPricing;

          let contract = await api.fetchContract(fieldEntries.contractId);
          this.fieldEntries = fieldEntries;
          if (this.fieldEntries.studio) this.studio = this.fieldEntries.studio;
          if (!this.fieldEntries.customer.legalRepresentative)
            this.resetLegalRepresentative();
          if (location.pathname.includes("view")) {
            this.viewOnly = true;
          } else {
            this.fieldEntries.customer.signature = null;
            this.fieldEntries.conditions.signature = null;
            this.fieldEntries.customer.legalRepresentative.firstSignature =
              null;
            this.fieldEntries.customer.legalRepresentative.secondSignature =
              null;
            this.resetAllSignatureChecks();
            this.fieldEntries.conditions.entries.inputValue.forEach(
              (c) => (c.accepted = false)
            );
          }
          this.checkImageSources();
          this.editCreateMode(contract, true);
        }
      } else {
        this.fetchContracts();
        // console.log('calculating duration because new contract')
      }
    },
    async fetchContracts() {
      let contracts = await api.fetchContract(this.$route.params.id);
      this.editCreateMode(contracts);
    },
    async editCreateMode(contract, viewOnly = false) {
      if (!viewOnly && !location.pathname.includes("edit")) {
        for (let field of contract.formFields) {
          if (field.inputType !== "packages") {
            field.selection = null;
            field.required = true;
          }
        }
      }

      let indexOfPayment = contract.formFields.findIndex(
        (e) => e.inputType === "paymentOptions"
      );

      contract.formFields.splice(indexOfPayment, 0, {
        identifier: "Übersicht",
        inputType: "pricing",
        inputValue: "",
        selection: null,
        required: false,
      });

      let indexOfConditions = contract.formFields.findIndex(
        (e) => e.inputType === "conditions"
      );

      contract.formFields.splice(indexOfConditions, 0, {
        identifier: "Vertragslaufzeit",
        inputType: "duration",
        inputValue: "",
        selection: null,
        required: false,
      });

      contract.formFields.splice(indexOfConditions + 2, 0, {
        identifier: "Abschluss",
        inputType: "endPage",
        inputValue: "",
        selection: null,
        required: false,
      });

      if (!viewOnly && !location.pathname.includes("edit")) {
        this.fieldEntries.contractId = contract._id;

        this.fieldEntries.conditions.entries = contract.formFields.find(
          (e) => e.inputType === "conditions"
        );
      }
      this.contract = contract;

      if (true)
        try {
          let memberships = contract.formFields.find(
            (e) => e.inputType === "membership"
          );

          if (memberships && memberships.inputValue) {
            let membershipsForExternalPackages = memberships.inputValue.map(
              (e) => {
                return {
                  active: e.active,
                  flexActive: e.flexActive,
                  title: e.title,
                  studioSettings: e.studioSettings[this.studio._id],
                };
              }
            );

            let fetchedExternalPackages = await api.fetchExternalPackages({
              membershipsForExternalPackages,
              customerId: this.studio.customer_id,
              studioId: this.studio._id,
            });
            this.externalPackages = fetchedExternalPackages;
          }
        } catch (e) {}

      this.loading = false;
    },

    getPackagesTotal() {
      if (
        !(
          this.fieldEntries.membership.selection &&
          this.fieldEntries.membership.selection.title &&
          this.fieldEntries.membership.selectedDuration
        )
      )
        return 0;

      let packagePrice = 0;
      for (let packag of this.fieldEntries.packages) {
        packagePrice += this.formatPriceFixed(
          this.getPackageInfos(packag).price
        );
      }
      let returnPrice = this.formatPriceFixed(packagePrice);
      return returnPrice;
    },
    getTotalPriceMesse() {
      if (
        !(
          this.fieldEntries.membership.selection &&
          this.fieldEntries.membership.selection.title &&
          this.fieldEntries.membership.selectedDuration
        )
      )
        return 0;
      let membershipPrice = parseInt(
        this.messeCalc(
          this.fieldEntries.membership.selection.title.toLowerCase(),
          parseInt(this.membershipInfos.duration.years)
        )
      );
      let signUpFee = this.membershipInfos.signUpFee;
      let packagePrice = 0;
      for (let packag of this.fieldEntries.packages) {
        packagePrice += this.formatPriceFixed(
          parseInt(this.getPackageInfos(packag).price) -
            parseInt(this.getPackageInfos(packag).price) / 5
        );
      }
      let returnPrice =
        this.formatPriceFixed(membershipPrice) +
        this.formatPriceFixed(packagePrice);

      if ("giftSignUpFee" in this.fieldEntries.membership) {
        if (!this.fieldEntries.membership.giftSignUpFee)
          returnPrice += this.formatPriceFixed(signUpFee);
      } else {
        returnPrice += this.formatPriceFixed(signUpFee);
      }

      if ("renewalDiscount" in this.fieldEntries.membership) {
        if (
          this.fieldEntries.membership.renewalDiscount &&
          this.formatPriceFixed(
            this.contract.promotions.renewalDiscountAmount
          ) > 0
        ) {
          //   console.log('subtracting discount')
          returnPrice -= this.formatPriceFixed(
            this.contract.promotions.renewalDiscountAmount
          );
        }
      }

      if (false && this.badge.enabled) {
        returnPrice += this.formatPriceFixed(this.badge.amount);
      }

      returnPrice = this.formatPriceFixed(returnPrice);
      return returnPrice;
    },
    getTotalPrice() {
      if (
        !(
          this.fieldEntries.membership.selection &&
          this.fieldEntries.membership.selection.title &&
          this.fieldEntries.membership.selectedDuration
        )
      )
        return 0;
      let membershipPrice = this.membershipInfos.price;
      let signUpFee = this.membershipInfos.signUpFee;
      let packagePrice = 0;
      for (let packag of this.fieldEntries.packages) {
        packagePrice += this.formatPriceFixed(
          this.getPackageInfos(packag).price
        );
      }
      let returnPrice =
        this.formatPriceFixed(membershipPrice) +
        this.formatPriceFixed(packagePrice);

      if ("giftSignUpFee" in this.fieldEntries.membership) {
        if (!this.fieldEntries.membership.giftSignUpFee)
          returnPrice += this.formatPriceFixed(signUpFee);
      } else {
        returnPrice += this.formatPriceFixed(signUpFee);
      }

      if ("renewalDiscount" in this.fieldEntries.membership) {
        if (
          this.fieldEntries.membership.renewalDiscount &&
          this.formatPriceFixed(
            this.contract.promotions.renewalDiscountAmount
          ) > 0
        ) {
          //   console.log('subtracting discount')
          returnPrice -= this.formatPriceFixed(
            this.contract.promotions.renewalDiscountAmount
          );
        }
      }

      if (false && this.badge.enabled) {
        returnPrice += this.formatPriceFixed(this.badge.amount);
      }

      returnPrice = this.formatPriceFixed(returnPrice);
      return returnPrice;
    },
    onFileSelected(event) {
      this.uploadStatus = "";
      this.selectedFile = event.target.files[0];
    },
    async checkImageSources() {
      for (let image of this.fieldEntries.customer.additionalImage) {
        if (!this.imageSources[image]) {
          //     console.log(image, 'IMAGE PATH')
          this.imageSources[image] = await api.getContractImage(image);
          //    console.log(this.imageSources)
        }
      }
      this.$forceUpdate();
    },
    removeImage(index) {
      delete this.imageSources[
        this.fieldEntries.customer.additionalImage[index]
      ];
      this.fieldEntries.customer.additionalImage.splice(index, 1);

      this.$forceUpdate();
    },
    async uploadPictureNew() {
      if (!this.selectedFile) {
        this.uploadStatus = "Please select an image to upload.";
        return;
      }

      const formData = new FormData();
      formData.append("file", this.selectedFile);

      let uploadImg = {
        art: "contract",
        formData: formData,
      };

      this.uploadStatus = "Uploading...";

      try {
        const data = await api.uploadImage(uploadImg);

        if (data.status) {
          //this.editing.contracts[index].customer.additionalImage.push(data.name)
          this.fieldEntries.customer.additionalImage.push(data.name);
          this.checkImageSources();

          this.uploadStatus = "Upload successful!";
        } else {
          this.uploadStatus = "Error uploading: " + data.message;
        }
      } catch (error) {
        this.uploadStatus = `Error uploading: ${error.message}`;
      } finally {
        this.$refs.fileInput.value = "";
        this.selectedFile = null;
      }
    },
    uploadPicture(formdata, progress, success, failure, slim) {
      const formData = new FormData();
      formData.append("file", formdata[0]);

      let uploadimg = {};
      uploadimg.art = "contract";
      uploadimg.formData = formData;

      api
        .uploadImage(uploadimg)
        .then((data) => {
          if (data.status) {
            this.fieldEntries.customer.additionalImage.push(data.name);
            this.$forceUpdate();
            success(data);
          }
        })
        .catch((error) => {
          this.fieldEntries.customer.additionalImage.push(data.name);
          this.$forceUpdate();
          failure(error);
        });
    },
    blackFridayDiscountCalculatorManual(price) {
      let priceModel = {
        2940: 1950,
        2670: 1800,
        980: 890,
        890: 790,
        2169: 1500,
        2070: 1500,
        789: 690,
        690: 690,
      };

      if (priceModel[parseInt(price)]) {
        return priceModel[parseInt(price)];
      } else {
        return null;
      }
    },
  },
  computed: {
    ...mapState("navigationStoreNotPersisted", [
      "navigationItems",
      "navigationVisible",
    ]),
    /* showBonustimeButton() {
            return (
                !this.publicContract &&
                this.membershipInfos.duration &&
                (parseInt(this.membershipInfos.duration.years) === 1 ||
                    parseInt(this.membershipInfos.duration.years) === 2) &&
                ((this.studio.code === 'basel' &&
                    (this.userService.hasRight('c_showBlackFridayOffer') || this.$moment().isAfter('2024-10-23'))) ||
                    (this.fieldEntries.category.selection && this.fieldEntries.category.selection.title === 'OLMA'))
            )
        },*/
    showBonustimeButton() {
      return (
        !this.publicContract &&
        this.membershipInfos.duration &&
        (parseInt(this.membershipInfos.duration.years) === 1 ||
          parseInt(this.membershipInfos.duration.years) === 2) &&
        (this.userService.hasRight("c_showBlackFridayOffer") ||
          // Für Basel: Zwischen dem 23. Oktober und 4. November 2024
          (this.studio.code === "basel" &&
            this.$moment().isBetween("2024-10-23", "2024-11-11")) ||
          // Für Dietlikon, Wallisellen, Oerlikon, Sirnach, Obfelden: Zwischen dem 26. und 28. Oktober 2024
          ((this.studio.code === "netstal" ||
            this.studio.code === "wetzikon") &&
            this.$moment().isBetween("2024-11-16", "2024-11-18")) ||
          // Für die Kategorie 'OLMA'
          (this.fieldEntries.category.selection &&
            this.fieldEntries.category.selection.title === "OLMA"))
      );
    },

    ostWellnessSpaPlusRabatt() {
      const targetDate = this.$moment("2024-04-08");

      return (
        this.$moment().isBefore(targetDate) &&
        parseInt(this.membershipInfos.duration.years) === 1
      );
    },
    imageUploadLabelText() {
      if (this.publicContract) {
        if (
          this.membershipInfos &&
          this.membershipInfos.title &&
          this.membershipInfos.title.includes("bis 20 Jahre")
        )
          return "Bild von der ID hochladen";
        return "Selfie hochladen";
      } else {
        return "Bilder hochladen";
      }
    },
    isRenewalContract() {
      return (
        this.fieldEntries.category.selection &&
        this.fieldEntries.category.selection.title === "Erneuerung"
      );
    },

    blackFridayDiscountCalculator() {
      let priceModel = {
        2940: 1950,
        2670: 1800,
        980: 890,
        890: 790,
        2169: 1500,
        789: 690,
      };

      if (
        this.blackFridaySelectionValidation &&
        this.membershipInfos &&
        this.membershipInfos.price
      ) {
        let price = this.membershipInfos.price;
        return priceModel[parseInt(price)];
      } else {
        return null;
      }
    },
    messeContract() {
      if (
        this.fieldEntries.membership &&
        this.fieldEntries.membership.selection &&
        this.fieldEntries.membership.selection.title &&
        this.fieldEntries.membership.selection.title
          .toLowerCase()
          .includes("messe")
      ) {
        return true;
      } else {
        return false;
      }
    },
    blackFridaySelectionValidation() {
      if (
        this.blackFridayValidation &&
        this.fieldEntries.membership &&
        this.fieldEntries.membership.selection &&
        this.fieldEntries.membership.selection.title &&
        this.fieldEntries.membership.selection.title
          .toLowerCase()
          .includes("black") &&
        !this.isFlexiContract
      ) {
        return true;
      } else {
        return false;
      }
    },
    premiumContractSelectionValidation() {
      if (
        this.fieldEntries.membership &&
        this.fieldEntries.membership.selection &&
        this.fieldEntries.membership.selection.title &&
        this.fieldEntries.membership.selection.title
          .toLowerCase()
          .includes("premium")
      ) {
        return true;
      } else {
        return false;
      }
    },
    blackFridayValidation() {
      if (
        this.$moment().isSame("2023-11-10", "day") ||
        this.$moment().isSame("2023-11-17", "day") ||
        this.$moment().isSame("2023-11-24", "day") ||
        this.userService.hasRight("c_showBlackFridayOffer") ||
        (this.fieldEntries.membership &&
          this.fieldEntries.membership.selection &&
          this.fieldEntries.membership.selection.title &&
          this.fieldEntries.membership.selection.title
            .toLowerCase()
            .includes("friday"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlexiContractCheck() {
      console.log(
        "Flexi Check",
        this.fieldEntries?.membership?.selection?.flexActive
      );
      return this.fieldEntries?.membership?.selection?.flexActive;
    },
    isFlexiContract() {
      try {
        let memberships = this.contract.find(
          (e) => e.inputType === "membership"
        );
        let flexActive = false;
        memberships.forEach((e) => {
          if (e.flexActive) {
            flexActive = true;
          }
        });
        return flexActive;
      } catch (e) {
        return false;
      }
    },

    externalPackagesFiltered() {
      if (
        this.externalPackages &&
        this.fieldEntries.membership.selection &&
        this.fieldEntries.membership.selectedDuration
      ) {
        let membership = this.externalPackages.find(
          (item) => item.title === this.fieldEntries.membership.selection.title
        );
        if (!membership) return null;

        if (membership.flexActive) {
          return membership.studioSettings.flex.externalPackages;
        } else {
          const durationsArray = membership.studioSettings.durations;

          const objToFind = this.fieldEntries.membership.selectedDuration;

          // Wandle die Werte in objToFind in Zahlen um, damit sie leichter verglichen werden können
          const parsedObjToFind = {
            years: parseInt(objToFind.years, 10),
            months: parseInt(objToFind.months, 10),
            days: parseInt(objToFind.days, 10),
          };

          // Finde das passende Element im Array
          const foundObj = durationsArray.find((item) => {
            // Wandle die Werte in item in Zahlen um
            const itemYears = parseInt(item.years, 10);
            const itemMonths = parseInt(item.months, 10);
            const itemDays = parseInt(item.days, 10);

            // Überprüfe, ob item und objToFind übereinstimmen
            return (
              itemYears === parsedObjToFind.years &&
              itemMonths === parsedObjToFind.months &&
              itemDays === parsedObjToFind.days
            );
          });

          // Wenn ein passendes Objekt gefunden wurde, wird es in der Konsole ausgegeben
          if (foundObj) {
            return foundObj.externalPackages;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    },
    badge() {
      let badge = {
        enabled: false,
        amount: 0,
      };

      let categorySelection = this.fieldEntries.category.selection;

      if (
        !categorySelection ||
        !categorySelection.notes ||
        !categorySelection.additionalInfo.includes("Chip vorhanden") ||
        categorySelection.notesAnswer !== "Nein"
      )
        return badge;

      if (
        !this.contract ||
        !this.contract.badge ||
        !this.publicContract ||
        !this.contract.badge.enabled
      ) {
        return badge;
      }

      badge.enabled = true;
      if (this.premiumContractSelectionValidation) badge.enabled = false;

      badge.amount = this.contract.badge.amount;
      return badge;
    },
    conditions() {
      if (!this.contract) return [];
      let conditionField = this.contract.formFields.find(
        (e) => e.inputType === "conditions"
      );
      if (!conditionField) return [];
      return conditionField.inputValue;
    },
    companyInfos() {
      if (!this.contract) return {};
      let companyField = this.contract.formFields.find(
        (e) => e.inputType === "company"
      );
      if (!companyField) return {};
      return companyField.inputValue.find((e) =>
        e.studios.includes(this.studio._id)
      );
    },

    contractFields() {
      // return this.contract.formFields.filter((e) => e.inputType !== 'title' && e.inputType !== 'company')
      let fieldsExceptPaymentOptions = this.contract.formFields.filter(
        (e) =>
          e.inputType !== "title" &&
          e.inputType !== "company" &&
          e.inputType !== "paymentOptions"
      );

      if (this.publicContract) {
        fieldsExceptPaymentOptions = fieldsExceptPaymentOptions.filter(
          (e) => e.inputType !== "customer"
        );
        fieldsExceptPaymentOptions = [
          ...fieldsExceptPaymentOptions,
          this.contract.formFields.find((e) => e.inputType === "customer"),
        ];
      }

      let paymentOptionsField = this.contract.formFields.find(
        (e) => e.inputType === "paymentOptions"
      );

      if (this.publicContract) {
        if (true || this.preSale) {
          paymentOptionsField = {
            inputValue: [
              {
                title: "Rechnung",
                additionalInfo:
                  "innert 5 Tagen nach Datum des Vertragsabschlusses, Rechnungen werden per E-Mail versandt",
                selectDate: true,
                studios: [this.studio._id],
                index: 0,
                active: true,
              },
            ],
            inputType: "paymentOptions",
            identifier: "Zahlungsbedingungen",
            selection: null,
            required: true,
          };
        } else {
          paymentOptionsField = {
            inputValue: [
              {
                title: "Rechnung",
                additionalInfo:
                  "innert 5 Tagen nach Datum des Vertragsabschlusses, Rechnungen werden per E-Mail versandt",
                selectDate: true,
                studios: [this.studio._id],
                index: 0,
                active: true,
              },
              {
                title: "Kreditkarte",
                additionalInfo: "",
                selectDate: true,
                studios: [this.studio._id],
                index: 1,
                active: true,
              },
              {
                title: "Debitkarte",
                additionalInfo: "",
                selectDate: true,
                studios: [this.studio._id],
                index: 2,
                active: true,
              },
            ],
            inputType: "paymentOptions",
            identifier: "Zahlungsbedingungen",
            selection: null,
            required: true,
          };
        }
      }

      if (paymentOptionsField) {
        return [...fieldsExceptPaymentOptions, paymentOptionsField];
      }

      return fieldsExceptPaymentOptions;
    },
    membershipFields() {
      let field = this.contractFields.find((e) => e.inputType === "membership");
      if (!field) return {};
      let fieldEntry = field.inputValue.filter((e) =>
        e.studios.includes(this.studio._id)
      );
      if (!fieldEntry) return {};
      let titles = {};
      fieldEntry.forEach((m) => {
        let title = "";
        if (m.title.split(",").length > 1) title = m.title.split(",")[0];
        else title = m.title;
        if (!titles[title]) titles[title] = [];
        titles[title].push(m);
      });

      return titles;
    },
    noSignUpFeeCategory() {
      let signUpFee = false;
      if (
        this.fieldEntries.category.selection &&
        "noSignUpFee" in this.fieldEntries.category.selection &&
        this.fieldEntries.category.selection.noSignUpFee
      ) {
        signUpFee = true;
      }
      return signUpFee;
    },
    membershipInfos() {
      if (
        !(
          this.fieldEntries.membership.selection &&
          this.fieldEntries.membership.selection.title &&
          this.fieldEntries.membership.selectedDuration
        )
      )
        return {
          title: null,
          price: null,
          signUpFee: null,
          saleActiveSignUpFeeGifted: false,
          saleActive: false,
          salePriceDifference: null,
          saleOldPrice: null,
          giftSignUpFee: false,
          saleOldSignUpFee: null,
          duration: { days: null, months: null, years: null },
        };

      let duration = this.fieldEntries.membership.selectedDuration;
      let saleActiveSignUpFeeGifted = false;
      let saleActive = false;
      let saleOldPrice = null;
      let saleOldSignUpFee = null;

      let salePriceDifference = null;
      let selection = this.fieldEntries.membership.selection;
      let selectionStudio = selection.studioSettings[this.studio._id];
      let title = selection.title;
      let price = null;
      let signUpFee = null;
      let giftSignUpFee = false;

      if (
        this.fieldEntries.membership.giftSignUpFee ||
        this.preSale ||
        this.forceGiftSignupFee ||
        this.blackFridaySelectionValidation ||
        (duration.saleActive &&
          duration.saleGiftSignUpFee &&
          !this.isTakeoverContract)
      )
        giftSignUpFee = true;

      if (selection.flexActive) {
        let multiplier = 1;
        if (selection.flexSelection.toString() === "perDay")
          multiplier = duration.days;
        if (selection.flexSelection.toString() === "perMonth")
          multiplier = duration.months;

        price =
          this.formatPriceFixed(
            selectionStudio.flex.prices[selection.flexSelection].price
          ) * multiplier;
        signUpFee =
          selectionStudio.flex.prices[selection.flexSelection].signUpFee;
      } else {
        price = duration.price;
        signUpFee = duration.signUpFee;

        if (duration.saleActive && !this.isTakeoverContract) {
          saleActive = true;
          price = duration.salePrice;
          saleOldPrice = duration.price;

          if (this.noSignUpFeeCategory)
            saleOldPrice =
              parseFloat(saleOldPrice) - parseFloat(duration.signUpFee);

          if (duration.saleGiftSignUpFee) {
            saleOldSignUpFee = duration.signUpFee;
            signUpFee = 0;
            saleActiveSignUpFeeGifted = true;
          }

          if (duration.salePrice) {
            salePriceDifference =
              parseFloat(duration.price) -
              parseFloat(duration.salePrice) -
              parseFloat(duration.signUpFee);
          }
        }
      }

      if (this.noSignUpFeeCategory) signUpFee = 0;

      price = this.formatPriceFixed(price);

      return {
        title,
        price,
        signUpFee,
        duration,
        giftSignUpFee,
        saleActiveSignUpFeeGifted,
        saleActive,
        salePriceDifference,
        saleOldPrice,
        saleOldSignUpFee,
      };
    },
  },
  watch: {
    startDatePicker(value) {
      if (!value) this.saveAndCalculateDuration();
    },
    "fieldEntries.paymentOptions.selection": function (val) {
      if (val) {
        // this.calculateDuration(this.startDate)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
.special-offer-chip-messe {
  position: absolute;
  top: 3px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--v-primary);
  color: white;
  font-size: 12px;
  width: 100px;
  padding: 1px 5px;
  border-radius: 15px;
  /* Weitere Stilisierung nach Bedarf */
}
.special-offer-chip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  width: 100px;
  padding: 1px 5px;
  border-radius: 15px;
  /* Weitere Stilisierung nach Bedarf */
}
.special-offer-premium-chip {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);

  color: white;
  font-size: 20px;

  /* Weitere Stilisierung nach Bedarf */
}
.blackFridayOffer:not(.selectedOption) {
  background-color: black;
  color: white;
  font-weight: bold;
}
.messeAngebotChip:not(.selectedOption) {
  // background-color: var(--v-primary);
  // color: white;
  // font-weight: bold;
}

.premiumOffer:not(.selectedOption) {
  /* background-color: #fff8dd;
    color: white;
    font-weight: bold;*/
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
@media (max-width: 767px) {
  .reversedMobile {
    flex-direction: column-reverse;
  }
}
.optionCard {
  cursor: pointer;
  white-space: break-spaces;
  flex-grow: 1;
  min-height: 54px;
  height: 100%;
}
.selectedOption {
  background-color: var(--v-primary);
  color: white;
}
::v-deep .v-alert__wrapper i {
  margin-bottom: auto;
  margin-top: auto;
}
::v-deep .infoText p {
  margin-bottom: 0px !important;
}
::v-deep .infoText p.smallHeight {
  line-height: 0.3;
}
::v-deep .infoText p:only-child:has(br) {
  line-height: 0.5;
}
.option {
  font-size: 1.2rem;
  font-weight: 500;
}

.contractCreator {
  &__dialog {
    position: relative;
    &-title {
      position: sticky;
      top: 0;
      color: white;
      border-bottom: 2px solid var(--v-primary);
      z-index: 9;

      background-color: var(--v-primary);
    }
    &-actions {
      background-color: #fff;
      border-top: 2px solid var(--v-primary);
      width: 100%;
    }
  }
}

.containerFileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.file-upload {
  display: flex;
  align-items: center;
}

.file-upload-label,
.upload-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px;
  font-size: 16px;
}

.file-upload-label {
  background-color: #4caf50;
  color: white;
}

#fileInput {
  display: none;
}

.selected-file,
.upload-status {
  margin-top: 8px;
  font-size: 14px;
}

.selected-file {
  color: #333;
}

.upload-status {
  color: #f44336;
}
</style>
  