<template>
    <v-navigation-drawer
        dark
        fixed
        v-model="drawer"
        :temporary="$vuetify.breakpoint.mobile"
        :expand-on-hover="!$vuetify.breakpoint.mobile"
    >
        <v-list>
            <div v-for="(item, index) in filteredItems" :key="index">
                <v-list-group v-if="item.subItems" no-action>
                    <template v-slot:activator @click="enterRoute(item)">
                        <v-list-item-content>
                            <div class="d-flex">
                                <v-list-item-icon>
                                    <v-icon
                                        :color="
                                            item.name === $route.name
                                                ? 'primary'
                                                : item.iconColor
                                                ? item.iconColor
                                                : 'white'
                                        "
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        :class="item.name === $route.name ? 'primary--text font-weight-bold' : ''"
                                    >
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </div>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="subItem in item.subItems"
                        :key="'item' + subItem.text"
                        link
                        @click="enterRoute(subItem)"
                    >
                        <v-list-item-icon v-if="subItem.icon">
                            <v-icon :color="subItem.name === $route.name ? 'primary' : 'white'">
                                {{ subItem.icon }}
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                :class="subItem.name === $route.name ? 'primary--text font-weight-bold' : ''"
                            >
                                {{ subItem.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else @click="enterRoute(item)">
                    <v-list-item-icon>
                        <v-icon
                            :color="item.name === $route.name ? 'primary' : item.iconColor ? item.iconColor : 'white'"
                        >
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            :class="
                                item.name === $route.name
                                    ? 'primary--text font-weight-bold'
                                    : item.iconColor
                                    ? item.iconColor + '--text'
                                    : ''
                            "
                        >
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import userService from '../../../services/userService'

export default {
    props: {
        drawerstate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            userService,
            drawer: true,
        }
    },
    created() {
        this.drawer = !this.$vuetify.breakpoint.mobile
    },
    computed: {
        filteredItems() {
            let user = localStorage.getItem('user')
            let items = [
                {
                    icon: 'mdi-view-dashboard',
                    text: 'Home',
                    name: 'dashboardHome',
                },
                {
                    icon: 'mdi-timer',
                    text: 'Zeiterfassung',

                    rights: ['tt_employeeManage', 'tt_management', 'tt_office'],
                    subItems: [
                        {
                            text: 'Zeiterfassung',
                            rights: ['tt_employeeManage'],
                            name: 'timeTracker',
                        },
                        {
                            text: 'Übersicht',
                            rights: ['tt_management'],
                            name: 'timeTrackerManagement',
                        },
                        {
                            text: 'Abrechnung',
                            rights: ['tt_office'],
                            name: 'timeTrackerOffice',
                        },
                    ],
                },
                {
                    icon: 'mdi-chart-line',
                    text: 'Statistiken',
                    rights: ['stat_viewstudio', 'stat_admin', 'stat_gf'],
                    subItems: [
                        /*{
                            text: 'Kennzahlen',
                            name: 'homeDashboardFigures',
                            rights: ['stat_viewstudio', 'stat_admin'],
                        },*/
                        {
                            text: 'Kunden Übersicht',
                            name: 'homeDashboardUserStatistics',
                            rights: ['stat_viewstudio', 'stat_admin'],
                        },
                        {
                            text: 'Checkins',
                            name: 'homeDashboardCheckinStatistics',
                            rights: ['stat_viewstudio', 'stat_admin'],
                        },
                        {
                            text: 'Group Fitness',
                            name: 'homeDashboardGroupFitnessStatistics',
                            rights: ['stat_gf', 'stat_admin', 'stat_gf_stud'],
                        },
                        {
                            text: 'Verträge',
                            name: 'homeDashboardContractStatistics',
                            rights: ['stat_admin', 'stat_viewstudio'],
                        },
                        {
                            text: 'Aboentwicklung',
                            name: 'homeDashboardContractDevelopmentStatistics',
                            rights: ['stat_admin'],
                        },
                        {
                            text: 'Leads',
                            name: 'homeDashboardLeadStatistics',
                            rights: ['stat_admin', 'stat_viewstudio'],
                        },
                        {
                            text: 'Trainer',
                            name: 'homeDashboardTrainerStatistics',
                            rights: ['stat_admin', 'stat_viewstudio'],
                        },
                        {
                            text: 'Mitarbeiterzahlen',
                            name: 'homeDashboardEmployeeStatistics',
                            rights: ['stat_employee'],
                        },
                        {
                            text: 'Anamnese',
                            name: 'homeDashboardAnamneseStatistics',
                            rights: ['yyyy'],
                        },
                    ],
                },
                {
                    icon: 'mdi-newspaper-variant-outline',
                    text: 'Verträge',
                    name: 'contract',
                    rights: ['u_downloadUserList', 'c_create_customer', 'c_open_contracts', 'c_create'],
                    subItems: [
                        {
                            text: 'Ernährungsberatung',
                            name: 'nutritionSales',
                            rights: ['c_create_customer', 'c_create'],
                        },
                        { text: 'Übersicht', name: 'contractList', rights: ['c_create_customer', 'c_create'] },
                        { text: 'Offen', name: 'contractManager', rights: ['c_open_contracts', 'c_create_customer'] },
                        {
                            text: 'Temporär',
                            name: 'contractTemporary',
                            rights: ['c_create_customer', 'c_open_contracts'],
                        },
                        {
                            text: 'Abgelehnt',
                            name: 'contractRejected',
                            rights: ['c_create_customer', 'c_open_contracts'],
                        },
                        { text: 'Einstellungen', name: 'contractSettings', rights: ['c_create'] },
                        { text: 'Statistik (abgelehnt)', name: 'contractStats', rights: ['c_create'] },
                    ],
                },
                {
                    icon: 'mdi-newspaper-variant-outline',
                    text: 'Upgrades',
                    name: 'contractUpgrades',
                    rights: ['c_create_customer', 'c_open_contracts', 'c_create'],
                    subItems: [
                        {
                            text: 'Akzeptiert',
                            name: 'contractUpgradesAccepted',
                            rights: ['c_create_customer', 'c_open_contracts'],
                        },
                        {
                            text: 'Offen',
                            name: 'contractUpgradesOpen',
                            rights: ['c_create_customer', 'c_open_contracts'],
                        },
                        {
                            text: 'Abgelehnt',
                            name: 'contractUpgradesClosed',
                            rights: ['c_create_customer', 'c_open_contracts'],
                        },
                    ],
                },
                {
                    icon: 'mdi-file-document-multiple-outline',
                    text: 'Dokumente',

                    rights: ['d_v_healthcare', 'd_v_timestops', 'd_c_timestops', 'd_c_timestop'],
                    subItems: [
                        {
                            text: 'KK-Bestätigungen',
                            rights: ['d_v_healthcare'],
                            name: 'healthcareView',
                        },
                        {
                            text: 'Timestops',
                            rights: ['d_c_timestops', 'd_v_timestops'],
                            name: 'timestopView',
                        },
                        /*{
                            text: 'Timestop Rechner',
                            rights: ['d_c_timestops', 'd_v_timestops'],
                            name: 'timestopCalculator',
                        },*/
                    ],
                },
                {
                    icon: 'mdi-cash-100',
                    text: 'Zahlungen',

                    rights: ['payment_create'],
                    subItems: [
                        {
                            text: 'Zahlung erfassen',
                            rights: ['payment_create'],
                            name: 'payment',
                        },
                        {
                            text: 'Übersicht',
                            rights: ['payment_overview'],
                            name: 'paymentOverview',
                        },
                    ],
                },
                {
                    icon: 'mdi-cart',
                    text: 'Shop',
                    rights: ['shop_manage', 'shop_purchase_view'],
                    subItems: [
                        {
                            text: 'Produkte',
                            rights: ['shop_manage'],
                            name: 'shopProducts',
                        },
                        {
                            text: 'Einkäufe',
                            rights: ['shop_manage', 'shop_purchase_view'],
                            name: 'shopPurchases',
                        },
                    ],
                },
                {
                    icon: 'mdi-nutrition',
                    text: 'Ernährung',
                    rights: ['sebdomspecial'],
                    subItems: [
                        {
                            text: 'Rezepte Durchsuchen',
                            rights: ['nutrition_admin'],
                            name: 'nutritionSearch',
                        },
                        {
                            text: 'Kunden',
                            rights: ['nutrition_admin'],
                            name: 'nutritionCustomers',
                        },
                    ],
                },
                {
                    icon: 'mdi-bullhorn',
                    text: 'Marketing',
                    name: 'communication',
                    rights: ['mk_news', 'mk_popup', 'mk_qrcode', 'mk_campaigns', 'mk_push', 'mk_slider'],

                    subItems: [
                        {
                            text: 'News',
                            name: 'communicationNews',
                            icon: 'mdi-newspaper-variant',
                            rights: ['mk_news'],
                        },
                        {
                            text: 'PopUps',
                            name: 'communicationPopups',
                            icon: 'mdi-cellphone-information',
                            rights: ['mk_popup'],
                        },
                        {
                            text: 'QR-Code',
                            name: 'communicationQRCode',
                            icon: 'mdi-qrcode',
                            rights: ['mk_qrcode'],
                        },
                        {
                            text: 'Kampagnen',
                            name: 'communicationDashboardCampaign',
                            icon: 'mdi-bullhorn',
                            rights: ['mk_campaigns'],
                        },
                        {
                            text: 'Push',
                            name: 'communicationDashboardPush',
                            icon: 'mdi-cellphone-text',
                            rights: ['mk_push_deactivated'],
                        },
                        {
                            text: 'Slider',
                            name: 'communicationDashboardSlideManager',
                            icon: 'mdi-view-gallery',
                            rights: ['mk_slider'],
                        },
                    ],
                },

                {
                    icon: 'mdi-dumbbell',
                    text: 'Geräte',
                    rights: ['ex_create', 'ex_edit', 'ex_delete', 'ex_transfer'],
                    name: 'devicesDashboardList',
                },

                {
                    icon: 'mdi-account-search',
                    text: 'Benutzersuche',
                    rights: ['u_search'],
                    name: 'userDashboardSearch',
                },

                {
                    icon: 'mdi-alpha-a-circle-outline',
                    text: 'Anamnese',
                    name: 'anamnese',
                    rights: ['ab_view', 'ab_edit', 'ab_delete'],
                    subItems: [
                        {
                            text: 'Anamnese',
                            name: 'anamneseDashboardSearch',
                            rights: ['ab_view', 'ab_edit', 'ab_delete'],
                        },
                        {
                            text: 'Anamnese Einstellungen',
                            name: 'anamneseDashboardSettings',
                            rights: ['ab_view', 'ab_edit', 'ab_delete'],
                        },
                    ],
                },
                {
                    icon: 'mdi-account-heart',
                    text: 'Weiterempfehlungen',
                    name: 'leads',
                    rights: ['af_downloadList', 'af_view', 'af_edit', 'af_settingsEdit', 'af_leadSettings', 'af_cost'],
                    subItems: [
                        {
                            text: 'Anfragen Liste',
                            name: 'leadsDashboardLeadsList',
                            rights: ['af_settingsEdit'],
                        },
                        {
                            text: 'Alle Leads / Referrals',
                            name: 'leadsDashboardAllLeads',
                            rights: ['af_downloadList', 'af_view', 'af_edit'],
                        },
                        {
                            text: 'Referral',
                            name: 'leadsDashboardReferralList',
                            rights: ['af_settingsEdit'],
                        },
                        {
                            text: 'Referral (Alt)',
                            name: 'leadsDashboardLeadsDeprecated',
                            rights: ['af_settingsEdit'],
                        },
                        {
                            text: 'Weiterempfehlungen Einstellungen',
                            name: 'leadsDashboardReferralSettings',
                            rights: ['af_settingsEdit'],
                        },
                        {
                            text: 'Leads Einstellungen',
                            name: 'leadsDashboardSettings',
                            rights: ['af_leadSettings'],
                        },
                        {
                            text: 'Lead Kosten',
                            name: 'leadsCostManager',
                            rights: ['af_cost'],
                        },
                    ],
                },
                {
                    icon: 'mdi-alpha-a-circle-outline',
                    text: 'Membercard',
                    rights: ['m_downloadList', 'm_signupUser', 'm_viewSubs', 'm_createSubs', 'm_createMembercard'],
                    name: 'membercardDashboardAdmin',
                },
                {
                    icon: 'mdi-domain',
                    text: 'TAC',
                    name: 'tac',
                    rights: ['tac_accessDevices'],
                    subItems: [
                        {
                            text: 'Zugangsgeräte',
                            name: 'tacAccessDevices',
                            rights: ['tac_accessDevices'],
                        },
                    ],
                },
                {
                    icon: 'mdi-clipboard-list-outline',
                    text: 'Trainingsplan',
                    name: 'workouts',
                    rights: ['tp_create', 'tp_viewAll', 'tp_feedback'],
                    subItems: [
                        {
                            text: 'Suchen und erstellen',
                            name: 'workoutsDashboardSearchAndCreate',
                            rights: ['tp_create', 'tp_viewAll'],
                        },
                        {
                            text: 'Bewertungen',
                            name: 'workoutsDashboardFeedback',
                            rights: ['tp_feedback'],
                        },
                    ],
                },
                {
                    icon: 'mdi-weight-lifter',
                    text: 'Group Fitness',
                    name: 'gf',
                    rights: [
                        'gf_c_create',

                        'gf_c_edit',

                        'gf_c_delete',

                        'gf_c_plan',

                        'gf_c_signupRange',

                        'r_edit',

                        'l_edit',

                        'gf_r_viewAll',

                        'gf_r_threestars',
                        'a_add',
                        'a_delete',
                        'a_edit',
                    ],
                    subItems: [
                        {
                            text: 'Kurse',
                            name: 'groupFitnessDashboardCourses',
                            icon: 'mdi-list-box',
                            rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete'],
                        },
                        {
                            text: 'Räume',
                            name: 'groupFitnessDashboardRooms',
                            icon: 'mdi-map-marker',
                            rights: ['r_edit'],
                        },
                        {
                            text: 'Labels',
                            name: 'groupFitnessDashboardLabels',
                            icon: 'mdi-label',
                            rights: ['l_edit'],
                        },
                        {
                            text: 'Videos',
                            name: 'groupFitnessDashboardVideos',
                            icon: 'mdi-folder-play',
                            rights: ['a_add', 'a_delete', 'a_edit'],
                        },
                        {
                            text: 'Events',
                            name: 'groupFitnessDashboardEventHome',
                            icon: 'mdi-calendar',
                            rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete', 'gf_c_plan'],
                        },
                        {
                            text: 'Bewertungen',
                            name: 'groupFitnessDashboardRatings',
                            icon: 'mdi-comment-quote',
                            rights: ['gf_r_viewAll', 'gf_r_threestars'],
                        },
                        {
                            text: 'Downloads',
                            name: 'groupFitnessDashboardDownloads',
                            icon: 'mdi-download-circle-outline',
                            rights: ['gf_c_plan'],
                        },

                        {
                            text: 'Einstellungen',
                            name: 'groupFitnessDashboardSettings',
                            icon: 'mdi-cog',
                            rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete'],
                        },
                    ],
                },
                {
                    icon: 'mdi-home',
                    text: user && user.studio ? user.studio.name : 'Studio',
                    name: 'studio',
                    rights: ['g_studioadmin', 'g_employeemanager', 'g_daycare', 'u_search', 'g_websitecontent'],
                    subItems: [
                        {
                            text: 'Admin',
                            name: 'studioEditor',
                            rights: ['g_studioadmin'],
                        },
                        {
                            text: 'Mitarbeiter',
                            name: 'studioEmployee',
                            icon: 'mdi-account-group',
                            rights: ['g_employeemanager'],
                        },
                        {
                            text: 'Kinderhort',
                            name: 'studioDaycare',
                            icon: 'mdi-teddy-bear',
                            rights: ['g_daycare'],
                        },
                        {
                            text: 'Öffnungszeiten',
                            name: 'openingHours',
                            icon: 'mdi-clock',
                            rights: ['g_employeemanager'],
                        },
                        {
                            text: 'Webseite',
                            name: 'websiteContent',
                            icon: 'mdi-web',
                            rights: ['g_websitecontent'],
                        },
                        /* { text: 'Slim-ME', name: 'studioSlimMe', icon: 'mdi-gymnastics', rights: ['u_search'] },*/
                    ],
                },
                {
                    icon: 'mdi-logout',
                    text: 'Zurück zu roody',

                    function: function () {
                        location.href = '/'
                    },
                },
            ]

            return items
                .filter((item) => {
                    if (item.subItems) {
                        item.subItems = item.subItems.filter((subItem) => {
                            if (subItem.rights) {
                                return this.userService.hasRightArray(subItem.rights)
                            }
                            return true
                        })
                    }
                    if (item.rights) {
                        return this.userService.hasRightArray(item.rights)
                    }
                    return true
                })
                .sort((a, b) => {
                    // "Home" soll immer zuoberst sein
                    if (a.text === 'Home') return -1
                    if (b.text === 'Home') return 1

                    // Restliche Elemente alphabetisch sortieren
                    let textA = a.text.toLowerCase()
                    let textB = b.text.toLowerCase()

                    if (textA < textB) {
                        return -1
                    }
                    if (textA > textB) {
                        return 1
                    }
                    return 0
                })
        },
    },
    watch: {
        drawerstate() {
            console.log('drawerstate', this.drawerstate)
            this.drawer = true
        },
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'toggleNavigationBarVisibility',
            'setNavigationBarText',
            'setNavigationbarVisibility',
        ]),
        enterRoute(subItem) {
            if (subItem.name) {
                this.$router.push({ name: subItem.name })
            } else {
                this.$router.push({
                    path: '/',
                })
            }
        },
    },
    created() {
        this.setNavigationbarVisibility(false)
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },
}
</script>
