<template>
  <div style="touch-action: none !important; height: 100vh; width: 100%">
    <v-col cols="12" style="overflow-x: auto">
      <v-card-title class="h6 pa-0">Deine Splits</v-card-title>
      <div class="button-switcher">
        <v-btn-toggle v-model="selectedSplit" mandatory class="custom-toggle">
          <div class="button-container">
            <v-btn
              :ripple="false"
              v-for="(item, index) in form.splits.workouts.map(
                (workout) => workout.title
              )"
              :key="item"
              class="custom-btn rounded-lg mr-2"
              @click="updateHighlight(index)"
            >
              <span
                :class="{ 'custom-toggle--active': index === selectedSplit }"
                style="z-index: 100"
              >
                {{ item }}
              </span>
            </v-btn>
          </div>
          <span ref="highlight" class="highlight"></span>
        </v-btn-toggle>
      </div>
    </v-col>
    <v-col cols="12">
      <v-card-title class="h6 pa-0">
        Füge Übungen zu deinem
        {{ form.splits.workouts[selectedSplit].title }} Split hinzu
      </v-card-title>

      <v-window touchless v-model="selectedSplit">
        <v-window-item
          :key="key"
          v-for="(item, key) in form.splits.workouts"
          :value="key"
        >
          <div v-if="selectedSplit === key">
            <div class="custom-toggle">
              <v-btn
                @click="toggleSelectedTag(tag)"
                style="flex: 0 0 auto"
                class="mr-1"
                v-for="(tag, key) in item.recommendedTags"
                :color="tagColors[key]"
                :key="key"
                small
              >
                {{ tag }}
              </v-btn>
              <v-btn
                @click="toggleAllTags = !toggleAllTags"
                style="flex: 0 0 auto"
                class="mr-1"
                color="red"
                :key="key"
                small
              >
                Alle Filter anzeigen
              </v-btn>
            </div>
            <div class="custom-toggle mt-2">
              <v-chip
                @click:close="toggleSelectedTag(tag)"
                class="mr-1"
                style="flex: 0 0 auto"
                outlined
                color="primary"
                small
                v-for="(tag, key) in selectedTags"
                :key="'tag' + key"
                close
              >
                {{ tag }}
              </v-chip>
            </div>
            <v-row no-gutters>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <v-card-title class="h6 pa-0 mt-2">Alle Übungen</v-card-title>
                <!-- Exercise List (Draggable Items) -->
                <v-virtual-scroll
                  style="height: 60vh"
                  :items="exercises"
                  item-height="80"
                  bench="1"
                >
                  <template v-slot:default="{ item }">
                    <div
                      class="draggable-item workoutAddExercise__list-item my-3 px-0 elevation-3 d-flex black rounded-lg"
                      :key="'draggable-item-selected' + item._id"
                      :data-id="item._id"
                      :data-source="'exercises'"
                    >
                      <!-- Exercise Item Content -->
                      <v-row no-gutters>
                        <v-col cols="10" class="ml-5">
                          <v-list-item-title>
                            {{ item.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="font-weight-bold"
                            v-if="item.deviceShortForm"
                          >
                            {{ item.deviceShortForm }}
                          </v-list-item-subtitle>
                        </v-col>
                        <v-col cols="2" class="align-content-center">
                          <!-- Drag Handle Button -->
                          <v-btn
                            icon
                            class="drag-handle"
                            @mousedown.stop
                            @touchstart.stop
                          >
                            <v-icon>mdi-drag-horizontal-variant</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </v-virtual-scroll>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <v-card-title class="h6 pa-0 mt-2">Dein Workout</v-card-title>

                <!-- Selected Exercises (Dropzone) -->
                <div class="dropzone" ref="dropzone">
                  <div
                    v-if="
                      selectedExercisesWithPlaceholders.length === 1 &&
                      !isDragging
                    "
                  >
                    <div class="text-center py-0">
                      <v-card-title
                        class="font-weight-bold py-0"
                        style="place-content: center"
                      >
                        Ziehe deine erste Übung ins Workout
                      </v-card-title>
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in selectedExercisesWithPlaceholders"
                    :key="item._id || 'placeholder' + index"
                  >
                    <div v-if="item.isPlaceholder">
                      <div
                        :class="{
                          'show-placeholder':
                            isDragging &&
                            (parseInt(placeholderIndex) - 1 ===
                              parseInt(index) ||
                              parseInt(placeholderIndex) + 1 ===
                                parseInt(index)),
                        }"
                        style="z-index: 10000"
                        class="placeholder-item"
                        :data-index="index"
                        :data-source="'selectedExercises'"
                      >
                        <v-list-item-title
                          >Übung hier einfügen</v-list-item-title
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      class="draggable-selected workoutAddExercise__list-item mb-1 px-0 elevation-3 d-flex black rounded"
                      :key="item._id"
                      :data-id="item._id"
                      :data-index="index"
                      :data-source="'selectedExercises'"
                    >
                      <!-- Selected Exercise Item Content -->
                      <v-row
                        no-gutters
                        v-if="parseInt(placeholderIndex) !== index"
                      >
                        <v-col @click="test(item)" cols="10">
                          <v-list-item-title>
                            {{ item.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="font-weight-bold"
                            v-if="item.deviceShortForm"
                          >
                            {{ item.deviceShortForm }}
                            <v-btn @click="edit">edit</v-btn>
                          </v-list-item-subtitle>
                        </v-col>
                        <v-col cols="2" class="align-content-center">
                          <!-- Drag Handle Button -->
                          <v-btn
                            icon
                            class="drag-handle"
                            @mousedown.stop
                            @touchstart.stop
                          >
                            <v-icon>mdi-drag-horizontal-variant</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col
                          cols="12"
                          class="text-center"
                          style="cursor: pointer; align-content-center"
                        >
                          <v-list-item-title>
                            Kombinieren zu Supersatz oder Zirkeltraining
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 1.5rem">
                            💪 | 🔄
                          </v-list-item-subtitle>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-window-item>
      </v-window>
    </v-col>
    <v-dialog
      v-model="editDialog"
      :width="dropzoneWidth"
      :style="dialogStyles"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- Hier wird dein Button erstellt -->
      </template>
      <v-card>
        <!-- In dieser v-card kannst du deinen Dialog-Inhalt einfügen -->
        <v-card-title>
          <span class="headline">Bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <!-- Füge hier die Bearbeitung der Übungen hinzu -->
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="saveEdit">Speichern</v-btn>
          <v-btn color="grey" text @click="editDialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn @click="$emit('return')">zurück</v-btn>
  </div>
</template>
  
  <script>
import interact from "interactjs";
import api from "@/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WorkoutAddExercise",
  props: {
    form: {
      type: Object,
    },
    exerciseIds: {
      type: Array,
    },
  },
  data() {
    return {
      editDialog: false,
      dropzoneWidth: "0",
      dropzoneHeight: "0",
      dialogStyles: {
        top: "0",
        left: "0",
      },
      onDragIndex: null,
      selectedExercises: [],
      tags: null,
      tagColors: [
        "#00963F", // Startfarbe (Grün)
        "#009655",
        "#009660",
        "#009676",
        "#009681",
        "#009696",
        "#009570", // Türkis beginnt
        "#00957A",
        "#009584",
        "#009584",
        "#00958E",
        "#009598",
        "#00909C", // Türkisfarbe
        "#008BB5", // Übergang zu Blau
        "#0088BB", // Blau beginnt
        "#0084C2",
        "#007FC9",
        "#007FC9",
        "#007BC6",
        "#0073B6",
        "#006BA5",
        "#006BA5",
        "#006395",
        "#005B84",
        "#416ABC", // Endfarbe (Blau-Violett)
      ],
      interactablePlaceholders: [], // To store placeholder interactions
      interactableExercises: [], // To store placeholder interactions
      interactableDraggables: null,
      selectedTags: [],
      exercises: [],
      selectedSplit: 0, // Default set to the first button
      placeholderIndex: 1,
      draggedIndex: null,
      isDragging: false, // Flag to track if an item is being dragged
    };
  },
  computed: {
    ...mapGetters("deviceStore", ["exerciseList"]),
    selectedExercisesWithPlaceholders() {
      // If not dragging, return selectedExercises as is

      const exercisesWithPlaceholders = [{ isPlaceholder: true }];

      this.selectedExercises.forEach((item) => {
        exercisesWithPlaceholders.push(item);
        exercisesWithPlaceholders.push({ isPlaceholder: true });
      });
      return exercisesWithPlaceholders;
    },
  },
  watch: {
    selectedExercisesWithPlaceholders(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.initializeDragAndDrop();
        });
      }
    },
  },
  async created() {
    this.fetchExercises();
    this.tags = await api.fetchExerciseTags();
  },
  mounted() {
    // Initialize highlight position and draggable items after DOM is rendered
    this.$nextTick(() => {
      this.initializeDragAndDrop();
      this.updateHighlight(this.selectedSplit);
      this.activateDraggableItems();
    });
  },
  methods: {
    ...mapActions("deviceStore", ["setDeviceList"]),
    edit(test) {
      console.log("triggered edit", test);
    },
    openEditDialog(item, index) {
      const dropzone = this.$refs.dropzone;
      this.dropzoneWidth = dropzone.offsetWidth + "px";
      this.dropzoneHeight = dropzone.offsetHeight + "px";
      const rect = dropzone.getBoundingClientRect();
      this.dialogStyles = {
        top: rect.top + "px",
        left: rect.left + "px",
      };
      this.editDialog = true;
    },
    saveEdit() {
      this.editDialog = false;
      // Logik zum Speichern der Änderungen hier
    },
    test(index) {
      console.log("hover", index);
    },
    selectExercise(item) {
      // Logic to select an exercise
    },
    activateDraggableItems() {
      const vm = this;

      // Unset existing interactions to prevent duplicates
      if (this.interactableDraggables) {
        this.interactableDraggables.unset();
      }

      this.interactableDraggables = interact(
        ".draggable-item, .draggable-selected"
      )
        .draggable({
          allowFrom: ".drag-handle", // Only allow dragging from elements with this class
          inertia: true,
          autoScroll: {
            container: document.querySelector(".dropzone"),
          },
          listeners: {
            move: this.dragMoveListener,
            end(event) {
              event.target.style.transform = "translate(0, 0)";
              event.target.removeAttribute("data-x");
              event.target.removeAttribute("data-y");
            },
          },
        })
        .on("dragstart", (event) => {
          event.target.classList.add("dragging");
          vm.isDragging = true;

          if (event.target.classList.contains("draggable-selected")) {
            vm.draggedIndex = parseInt(event.target.getAttribute("data-index"));
          }
        })
        .on("dragend", (event) => {
          event.target.classList.remove("dragging");
          vm.placeholderIndex = -1;
          vm.isDragging = false;
          vm.draggedIndex = null;
          vm.onDragIndex = null;
        });
    },
    fetchExercises() {
      if (!this.exerciseList.length) {
        api.fetchDevices().then((data) => {
          this.setDeviceList(data);
          this.exercises = this.exerciseList;
        });
      } else {
        this.exercises = this.exerciseList;
      }
      this.$emit("triggerKey");
    },
    getImageUrl(image) {
      return "/img/uebungen/" + image.replace(/\.[^/.]+$/, "") + ".webp";
    },
    toggleSelectedTag(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter(
          (selectedTags) => selectedTags !== tag
        );
      } else {
        this.selectedTags.push(tag);
      }
    },
    updateHighlight(index) {
      // Move highlight
      const btns = this.$el.querySelectorAll(".custom-btn");
      const highlight = this.$refs.highlight;

      if (btns.length > 0) {
        const selectedBtn = btns[index];
        const offsetLeft = selectedBtn.offsetLeft;
        const btnWidth = selectedBtn.offsetWidth;

        // Adjust highlight
        highlight.style.width = `${btnWidth}px`;
        highlight.style.left = `${offsetLeft}px`;
      }
    },
    initializeDragAndDrop() {
      const vm = this;
      console.log("*beep* *beep* initialisierung *beep*");
      // Clean up existing interactions
      this.interactablePlaceholders.forEach((interaction) =>
        interaction.unset()
      );
      this.interactablePlaceholders = [];
      // Clean up existing interactions
      this.interactableExercises.forEach((interaction) => interaction.unset());
      this.interactableExercises = [];

      // Setup dropzones on placeholders
      this.$nextTick(() => {
        const placeholderElements =
          vm.$el.querySelectorAll(".placeholder-item");
        placeholderElements.forEach((element) => {
          const interaction = interact(element).dropzone({
            accept: ".draggable-item, .draggable-selected, .drag-handle",
            overlap: 0.1,
            autoScroll: true,
            ondropactivate(event) {
              event.target.classList.add("drop-active");
            },
            ondragenter(event) {
              vm.onDragIndex = event.target.getAttribute("data-id");
              console.log("entered", vm.onDragIndex);
              event.target.classList.add("drop-target");
            },
            ondragleave(event) {
              console.log("left");
              event.target.classList.remove("drop-target");
            },
            ondrop(event) {
              console.log("ich droppe", event);
              const draggedId = event.relatedTarget.getAttribute("data-id");
              const source = event.relatedTarget.getAttribute("data-source");
              const index =
                vm.placeholderIndex !== null
                  ? vm.placeholderIndex
                  : vm.selectedExercises.length;

              if (source === "exercises") {
                const droppedExercise = JSON.parse(
                  JSON.stringify(
                    vm.exercises.find((ex) => ex._id === draggedId)
                  )
                );
                if (droppedExercise) {
                  vm.selectedExercises.splice(1, 0, droppedExercise);
                }
              } else if (source === "selectedExercises") {
                const fromIndex = vm.draggedIndex;
                if (fromIndex !== null && fromIndex !== index) {
                  const movedExercise = vm.selectedExercises.splice(
                    fromIndex,
                    1
                  )[0];
                  vm.selectedExercises.splice(index, 0, movedExercise);
                }
              }
              vm.placeholderIndex = -1;
            },
            ondropdeactivate(event) {
              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
              vm.placeholderIndex = -1;
            },
          });

          // Store the interaction so it can be unset later
          vm.interactablePlaceholders.push(interaction);
        });
      });
      // Setup dropzones on placeholders
      this.$nextTick(() => {
        const draggableExercises = vm.$el.querySelectorAll(
          ".draggable-selected"
        );
        draggableExercises.forEach((element) => {
          const interaction = interact(element).dropzone({
            accept: ".draggable-item, .draggable-selected, .drag-handle",
            overlap: 0.1,
            ondropactivate(event) {
              event.target.classList.add("drop-active");
            },
            ondragenter(event) {
              vm.placeholderIndex = event.target.getAttribute("data-index");
              event.target.classList.add("drop-target-selectedexercise");
            },
            ondragleave(event) {
              console.log("left");
              event.target.classList.remove("drop-target-selectedexercise");
            },
            ondrop(event) {
              console.log("ich droppe", event);
              const draggedId = event.relatedTarget.getAttribute("data-id");
              const source = event.relatedTarget.getAttribute("data-source");
              const index =
                vm.placeholderIndex !== null
                  ? vm.placeholderIndex
                  : vm.selectedExercises.length;

              if (source === "exercises") {
                const droppedExercise = JSON.parse(
                  JSON.stringify(
                    vm.exercises.find((ex) => ex._id === draggedId)
                  )
                );
                if (droppedExercise) {
                  vm.selectedExercises.splice(1, 0, droppedExercise);
                }
              } else if (source === "selectedExercises") {
                const fromIndex = vm.draggedIndex;
                if (fromIndex !== null && fromIndex !== index) {
                  const movedExercise = vm.selectedExercises.splice(
                    fromIndex,
                    1
                  )[0];
                  vm.selectedExercises.splice(index, 0, movedExercise);
                }
              }
              vm.placeholderIndex = -1;
            },
            ondropdeactivate(event) {
              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
              vm.placeholderIndex = -1;
            },
          });

          // Store the interaction so it can be unset later
          vm.interactablePlaceholders.push(interaction);
        });
      });

      // Unset existing interactions to prevent duplicates
    },

    dragMoveListener(event) {
      const target = event.target;
      // Keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // Translate the element
      target.style.transform = `translate(${x}px, ${y}px)`;

      // Update the position attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.show-placeholder {
  opacity: 0.7 !important;
  transition: opacity 0.5s;
}
.button-switcher {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.custom-toggle {
  background: transparent !important;
  position: relative;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.button-container {
  display: flex;
  flex-wrap: nowrap;
}

.custom-btn {
  border: 3px solid white;

  flex: 0 0 auto;
  color: #d7d7d7;
  font-weight: bold;
  transition: color 0.3s;
  text-align: center;
}

.highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--v-primary);
  border-radius: 8px;
  transition: left 0.3s ease, width 0.3s ease;
}

.custom-toggle--active {
  color: #fff !important;
}

.workoutAddExercise {
  &__list-item {
    height: 76px;
    overflow: hidden;

    .v-list-item__title {
      font-weight: bold;
      margin-bottom: 3px;
    }

    &-image {
      height: 100%;

      &__container {
        height: 100%;
        width: 40%;
      }
    }
  }
}

/* Styles for Interact.js drag-and-drop */
.draggable-item,
.draggable-selected {
  touch-action: none;
  cursor: move;
}

.dragging {
  opacity: 0.5;
  z-index: 100000;
}

.dropzone {
  overflow: auto;
  max-height: 60vh;
  min-height: 80px;
  border: 2px dashed white;
  margin-top: 10px;
  padding: 10px;
  position: relative;
}

.drop-active {
  border-color: blue;
}

.drop-target {
  color: black;
  background-color: #f0f0f0 !important;
}

.drop-target-selectedexercise {
  opacity: 0.5;
}

.can-drop {
  border-color: green;
}

.placeholder-item {
  background-color: var(--v-primary);
  border: 2px dashed #aaa;
  text-align: center;
  padding: 2px 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.invalid-drop {
  border: 2px solid red;
}

.drop-on-exercise {
  background: red !important;
  border: 5px dashed violet !important;
}
</style>
  