<template>
  <v-row justify="center">
    <v-dialog
      persistent
      content-class="dilog"
      max-width="1140px"
      v-model="dialog"
      fullscreen
      hide-overlay
      no-click-animation
      transition="dialog-bottom-transition"
    >
      <div class="wrapper-outer">
        <div class="wrapper-inner d-flex justify-center" v-if="courseView">
          <v-img
            class="position-fixed headerImage"
            height="35vh"
            :src="headerImageSrc"
          ></v-img>

          <v-card
            color="#f7f8f8"
            max-width="500px"
            class="courseListView elevation-0"
          >
            <v-btn
              class="courseListView__container-back"
              fab
              dark
              color="white"
              @click="closeDialog"
            >
              <v-icon color="black">mdi-chevron-left</v-icon>
            </v-btn>

            <v-row no-gutters class="courseListView__container flex-column">
              <div class="courseListView__container-like" v-if="false">
                <v-btn
                  class="courseListView__container-like-btn"
                  fab
                  dark
                  color="white"
                  @click="addAsHabit"
                >
                  <v-icon :color="courseView.course.isHabit ? 'red' : 'primary'"
                    >mdi-heart</v-icon
                  >
                </v-btn>
              </div>
              <div
                v-if="
                  userService.hasRight('gf_cp_edit', 'gf_cp_cancel') &&
                  !livestreamStudio &&
                  !schwandenStudio &&
                  !niederurnenStudio
                "
                class="courseListView__container-edit"
              >
                <v-btn
                  class="courseListView__container-edit-btn"
                  fab
                  dark
                  color="white"
                  @click="editCourse"
                >
                  <v-icon color="primary">mdi-tooltip-edit</v-icon>
                </v-btn>
              </div>
              <v-row
                no-gutters
                class="courseListView__container-info mt-2"
                :class="{ 'mb-5': !(lsSignedUp && lsRunning) }"
              >
                <v-col cols="10">
                  <v-card-title
                    v-if="
                      courseView && courseView.course && courseView.course.name
                    "
                    class="h4 mb-0 courseListView__container-info-title"
                  >
                    <span>{{ courseView.course.name }}</span>
                  </v-card-title>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                  <v-btn
                    large
                    style=""
                    icon
                    class="mr-2"
                    @click="shareViaWebShare"
                  >
                    <v-icon color="black">mdi-export-variant</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  atLeastOneInstructor &&
                  (isInstructor ||
                    userService.hasRight('gf_claimforinstructor')) &&
                  courseView &&
                  !courseView.hoursClaimed &&
                  past
                "
                no-gutters
              >
                <v-col cols="12">
                  <v-alert class="mx-5" type="info">
                    Dieser Kurs wurde noch nicht für die Stundenabrechnung
                    beansprucht
                  </v-alert>
                </v-col>
                <v-col cols="12" class="d-flex justify-center mb-2">
                  <v-btn
                    @click="claimCourseExecutionHours"
                    color="primary"
                    outlined
                  >
                    Jetzt beanspruchen
                  </v-btn>
                </v-col>
              </v-row>
              <div class="ls-announcement" v-if="lsSignedUp && !lsRunning">
                <span>LIVESTREAM STARTET HIER IN</span>
                <div class="ls-announcement-countdown">
                  <div class="ls-announcement-countdown-time">
                    <span>{{ lsTimeLeft.split(":")[0] }}</span>
                    <span>H</span>
                  </div>
                  <div class="ls-announcement-countdown-time">
                    <span>{{ lsTimeLeft.split(":")[1] }}</span>
                    <span>MIN</span>
                  </div>
                  <div class="ls-announcement-countdown-time">
                    <span>{{ lsTimeLeft.split(":")[2] }}</span>
                    <span>SEK</span>
                  </div>
                </div>
              </div>

              <div
                v-if="lsRunning && livestreamStudio && fullyLoaded"
                style="
                  padding: 56.25% 0 0 0;
                  position: relative;
                  margin: 0px 0px 20px 0px;
                  background-image: url('/img/icons/loader.gif');
                  background-size: 80px;
                  background-position: center center;
                "
              >
                <iframe
                  :src="courseView.lsLink"
                  frameborder="0"
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                ></iframe>
              </div>

              <v-row
                v-if="courseView"
                no-gutters
                class="courseListView__container-instructor"
              >
                <v-col
                  class="mb-5"
                  cols="12"
                  v-for="(instructor, i) of courseView.instructors"
                  :key="i"
                >
                  <v-row no-gutters>
                    <v-col cols="3">
                      <v-img
                        width="65px"
                        height="65px"
                        v-if="instructor.bild"
                        class="courseListView__container-instructor-img ma-auto"
                        :src="'/img/profilbilder' + instructor.bild"
                      ></v-img>
                      <v-img
                        v-else
                        width="65px"
                        height="65px"
                        class="courseListView__container-instructor-img ma-auto"
                        src="https://media.istockphoto.com/id/1337144146/de/vektor/standard-avatar-profilsymbolvektor.jpg?s=612x612&w=0&k=20&c=rw3jpsQAdTti6Hh9SAGN15Ex8HYYwmHv3JFHS6KStMo="
                      ></v-img>
                      <v-btn
                        v-if="instructor.telefon"
                        color="primary"
                        fab
                        absolute
                        style="right: 5px; top: 40px"
                        @click="callPhoneNumber(instructor.telefon)"
                        x-small
                      >
                        <v-icon>mdi-phone</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="9" class="d-flex align-center text-align-left">
                      <span v-if="instructor" class="mr-auto">
                        {{ instructor.vorname }} {{ instructor.nachname }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="no-gutters mx-2 mt-3">
                <v-col class="pr-1" cols="6">
                  <v-card
                    elevation="0"
                    class="fill-height rounded-xl py-2 px-2"
                  >
                    <v-row no-gutters class="fill-height no-gutters">
                      <v-col
                        cols="3"
                        class="text-center align-center justify-center fill-height d-flex"
                      >
                        <span style="font-size: 25px">🌍</span>
                      </v-col>
                      <v-col
                        cols="9"
                        class="d-flex flex-column pl-3 justify-center"
                      >
                        <span>
                          <span class="font-weight-bold">GF-Raum</span>
                          <br />
                          <span
                            v-if="
                              courseView &&
                              courseView.room &&
                              courseView.room.name
                            "
                          >
                            {{ courseView.room.name }}
                          </span>
                          <span v-else>Studio</span>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col class="pl-1" cols="6">
                  <v-card
                    elevation="0"
                    class="fill-height rounded-xl py-2 px-2"
                  >
                    <v-row no-gutters class="fill-height">
                      <v-col
                        cols="3"
                        class="text-center align-center justify-center fill-height d-flex"
                      >
                        <span style="font-size: 25px">🕒</span>
                      </v-col>
                      <v-col
                        cols="9"
                        class="d-flex flex-column pl-3 justify-center"
                      >
                        <span>
                          <span class="font-weight-bold">Uhrzeit</span>
                          <br />
                          {{ courseStartDate(courseView) }} -
                          {{ courseEndDate(courseView) }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="px-3 mt-5 d-flex justify-center"
                v-if="userService.hasRight('gf_cp_downloadImage')"
              >
                <v-btn
                  width="60px"
                  height="60px"
                  @click="showMarketingDialog = true"
                  class="rounded-circle"
                  color="#343a40"
                >
                  <lottie-player
                    mode="normal"
                    style="width: 45px; height: 45px"
                    class="app-bar__lottie"
                    src="/img/lottiefiles/gf/downloadIcon.json"
                  ></lottie-player>
                </v-btn>
              </v-row>

              <v-row
                class="courseListView__ratings"
                v-if="
                  courseView.ratings &&
                  courseView.ratings.length > 0 &&
                  (userService.hasRight('gf_r_viewAll') ||
                    (isInstructor && courseView.ratings))
                "
                no-gutters
              >
                <v-col cols="12"
                  ><v-card-title class="h3 pt-2 pb-2"
                    >Bewertungen</v-card-title
                  ></v-col
                >

                <v-row no-gutters>
                  <v-col
                    cols="6"
                    class="courseListView__ratings-entry d-flex flex-column mx-5 mb-2"
                    v-for="(rating, ir) of courseView.ratings"
                    :key="ir"
                  >
                    <v-card-title class="h6 py-0 px-1">
                      {{ rating.user.vorname }} {{ rating.user.nachname }}
                    </v-card-title>

                    <v-rating
                      background-color="#ffd700"
                      color="#ffd700"
                      v-if="rating.stars"
                      length="5"
                      size="25"
                      readonly
                      :value="rating.stars"
                    ></v-rating>
                    <v-card-text
                      class="py-2 px-1"
                      v-if="
                        rating.message &&
                        (rating.stars > 3 ||
                          userService.hasRight('gf_r_viewAll'))
                      "
                    >
                      {{ rating.message }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-row>
              <v-row
                v-if="
                  (userService.hasRight(
                    'gf_cp_viewSignups',
                    'gf_cp_manageSignups'
                  ) ||
                    isInstructor) &&
                  !livestreamStudio
                "
                no-gutters
                class="my-5 flex-column pt-3"
              >
                <v-dialog
                  content-class="signupDialog"
                  origin="top center"
                  v-model="dialog2"
                  style="z-index: 999999 !important; height: 60%"
                  transition="dialog-bottom-transition"
                  max-width="600"
                  v-if="
                    (userService.hasRight(
                      'gf_cp_viewSignups',
                      'gf_cp_manageSignups'
                    ) ||
                      (isInstructor && $moment().isAfter(signUpLimit))) &&
                    !livestreamStudio
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="user-add-btn mb-10 mx-auto"
                      max-width="300"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span class="pr-2">Anmeldung hinzufügen</span>
                      <v-icon color="white">mdi-account-multiple-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-toolbar color="primary" dark
                      >Benutzer anmelden</v-toolbar
                    >
                    <user-search
                      :ignoreRights="isInstructor"
                      :hideEmail="!userService.hasRight('gf_cp_edit')"
                      @update="selectUserIdSignIn"
                      class="px-5 py-5"
                      label="Suche"
                      rights="gf_cp_manageSignups"
                    ></user-search>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="cancelUserSignIn">Abbrechen</v-btn>
                      <v-btn text @click="signInUser(signInUserId)"
                        >Hinzufügen</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-col v-if="!(courseView && courseView.signups.length > 0)">
                  <v-row class="no-gutters px-5 d-flex justify-center">
                    <span>Keine Anmeldungen vorhanden</span>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(signup, index) in courseView.signups"
                  :key="index + '-signup'"
                >
                  <v-row no-gutters>
                    <span class="ml-5 glowingDot-signup"></span>
                    <v-row v-if="signup && signup.user" class="px-5" no-gutters>
                      <v-col
                        @click="showUserInfo(signup, true)"
                        cols="7"
                        class="d-flex flex-column"
                      >
                        <span style="max-width: 220px" class="my-auto">
                          {{ signup.user.vorname }} {{ signup.user.nachname }}
                        </span>
                        <span class="my-auto">{{
                          formatSignupDate(signup.ts)
                        }}</span>
                      </v-col>
                      <v-col cols="5" class="d-flex justify-end">
                        <v-icon
                          color="primary ml-2"
                          v-show="
                            signup.user.certChecked == true &&
                            signup.user.certInvalid == false
                          "
                        >
                          mdi-check-decagram
                        </v-icon>

                        <v-btn
                          @click="signOutUser(signup.user._id)"
                          class="my-auto ml-1"
                          icon
                        >
                          <v-icon>mdi-account-minus</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="!signup.onQueue"
                          class="my-auto ml-1"
                          plain
                          icon
                          large
                          @click="manageVisited(signup)"
                        >
                          <v-icon color="primary" v-if="signup.visited">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else>mdi-check-circle-outline</v-icon>
                        </v-btn>
                        <v-btn v-else class="my-auto ml-1" plain icon large>
                          <v-icon class="my-auto ml-1" color="red">
                            mdi-clipboard-alert
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider class="mb-4"></v-divider>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(signoff, index) in courseView.signoffs"
                  :key="index + 'signoff'"
                >
                  <v-row no-gutters>
                    <span class="ml-5 glowingDot-signoff"></span>
                    <v-row
                      v-if="signoff && signoff.user"
                      class="px-5"
                      no-gutters
                    >
                      <v-col
                        cols="7"
                        @click="showUserInfo(signoff)"
                        class="d-flex flex-column"
                      >
                        <span style="max-width: 220px" class="my-auto">
                          {{ signoff.user.vorname }} {{ signoff.user.nachname }}
                        </span>
                        <span class="my-auto">{{
                          formatSignupDate(signoff.ts)
                        }}</span>
                      </v-col>
                      <v-col cols="5" class="d-flex justify-end">
                        <v-icon
                          color="primary ml-2"
                          v-show="
                            signoff.user.certChecked == true &&
                            signoff.user.certInvalid == false
                          "
                        >
                          mdi-check-decagram
                        </v-icon>

                        <v-btn
                          @click="signInUser(signoff.user._id)"
                          class="my-auto ml-1"
                          icon
                        >
                          <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>

                  <v-divider class="mb-4"></v-divider>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  userService.hasRight(
                    'gf_cp_viewSignups',
                    'gf_cp_manageSignups'
                  ) || isInstructor
                "
                no-gutters
                class="mb-5 flex-column pt-3"
              >
                <v-col
                  v-if="courseView.lsSignups && courseView.lsSignups.length > 0"
                  cols="12"
                >
                  <v-card-title class="h3 pt-2 pb-2">
                    <v-icon class="pr-2" color="primary">mdi-video</v-icon>
                    Anmeldungen
                  </v-card-title>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(signup, index) in courseView.lsSignups"
                  :key="index + '-signup'"
                >
                  <v-row v-if="signup && signup.user" class="px-5" no-gutters>
                    <v-col
                      @click="showUserInfo(signup, true)"
                      cols="7"
                      class="d-flex flex-column"
                    >
                      <span style="max-width: 220px" class="my-auto">
                        {{ signup.user.vorname }} {{ signup.user.nachname }}
                      </span>
                      <span class="my-auto">{{
                        formatSignupDate(signup.ts)
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <v-icon
                        color="primary ml-2"
                        v-show="
                          signup.user.certChecked == true &&
                          signup.user.certInvalid == false
                        "
                      >
                        mdi-check-decagram
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4"></v-divider>
                </v-col>

                <v-col
                  cols="12"
                  v-if="
                    courseView.lsSignoffs && courseView.lsSignoffs.length > 0
                  "
                >
                  <v-card-title class="h3 pt-2 pb-2">
                    <v-icon class="pr-2" color="primary">mdi-video</v-icon>
                    Abmeldungen
                  </v-card-title>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(signoff, index) in courseView.lsSignoffs"
                  :key="index + '-signout'"
                >
                  <v-row v-if="signoff" class="px-5" no-gutters>
                    <v-col
                      @click="showUserInfo(signoff)"
                      cols="7"
                      class="d-flex flex-column"
                    >
                      <span style="max-width: 220px" class="my-auto">
                        {{ signoff.user.vorname }} {{ signoff.user.nachname }}
                      </span>
                      <span class="my-auto">{{
                        formatSignupDate(signoff.ts)
                      }}</span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <v-icon
                        color="primary ml-2"
                        v-show="
                          signoff.user.certChecked == true &&
                          signoff.user.certInvalid == false
                        "
                      >
                        mdi-check-decagram
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4"></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-5">
                <v-card-title class="mb-0 pb-0 h6 font-weight-bold"
                  >Beschreibung</v-card-title
                >
                <v-card-text class="mb-10">{{
                  courseView.course.beschreibung
                }}</v-card-text>
              </v-row>
            </v-row>

            <div class="hide-lower-1"></div>
            <div class="hide-lower-2"></div>
          </v-card>
        </div>
        <div class="wrapper-inner d-flex justify-center align-center" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn @click="$router.push('/kurse')" text>Abbrechen</v-btn>
        </div>
        <div v-if="!isLivestream" class="signupsCount">
          <span>{{ signupCounter() }}</span>
        </div>
        <div v-else class="signupsCount"><span></span></div>
      </div>
    </v-dialog>

    <v-dialog v-model="showMarketingDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 pt-5 pb-5"
          >Social Media Material</v-card-title
        >

        <v-card-text>
          Lade hier Material für Instagram / Facebook und Whatsapp Stories,
          sowie Beiträge herunter
        </v-card-text>
        <v-row no-gutters class="d-flex justify-center">
          <v-btn
            @click="downloadMarketingImage('square')"
            class="mb-5"
            color="primary"
          >
            Posts (Quadratisch)
          </v-btn>
          <v-btn
            @click="downloadMarketingImage('vertical')"
            class="mb-5"
            color="primary"
          >
            Stories (Hochformat)
          </v-btn>
          <v-btn @click="downloadPrint" color="primary"
            >A4 PDF Druckversion</v-btn
          >
        </v-row>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="showMarketingDialog = false"
            >Fertig</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUserInformation" persistent max-width="400">
      <v-card class="d-flex flex-column justify-left" v-if="userInformation">
        <v-card-title class="text-h5 pt-5 pb-5">
          {{ userInformation.user.vorname }}
          {{ userInformation.user.nachname }} ({{
            userInformation.user.geschlecht
          }})
        </v-card-title>

        <v-btn
          class=""
          text
          color="primary"
          v-if="
            userService.hasRight('gf_cp_edit') && userInformation.user.telefon
          "
          @click="copyText(userInformation.user.telefon)"
        >
          {{ userInformation.user.telefon }}
        </v-btn>

        <v-btn
          text
          class="mt-2 my-5"
          color="primary"
          v-if="
            userService.hasRight('gf_cp_edit') && userInformation.user.email
          "
          @click="copyText(userInformation.user.email)"
        >
          {{ userInformation.user.email }}
        </v-btn>
        <v-card-text
          v-if="!userInformation.signupStatus && userInformation.signupTs"
        >
          Angemeldet am: {{ formatSignupDate(userInformation.signupTs) }} Uhr
        </v-card-text>
        <v-card-text>
          {{ userInformation.signupStatus ? "Angemeldet" : "Abgemeldet" }} am:
          {{ formatSignupDate(userInformation.ts) }} Uhr
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn color="green darken-1" text @click="hideUserInfo"
            >Fertig</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="lastCheckinAllowedDialog"
      persistent
      :hide-overlay="false"
    >
      <v-card>
        <v-card-title class="primary white--text"
          >Wichtige Information!</v-card-title
        >
        <v-card-text class="mt-1">
          Hallo {{ userService.getUserFirstName() }},
          <br />
          wir konnten leider keinen Vertrag unter der E-Mail-Adresse finden, mit
          der du dich in Roody registriert hast. Es kann sein, dass die
          E-Mail-Adressen in unserer App und in unserem
          Mitgliedersoftware-System nicht übereinstimmen. Bitte beachte, dass du
          dich nur noch
          <strong>dieses Mal</strong>
          ohne gültigen Vertrag für einen Kurs anmelden kannst. Danach ist eine
          Anmeldung nur noch mit einem aktiven Vertrag möglich.
          <br />
          Falls es sich um ein Versehen handelt oder die E-Mail-Adressen nicht
          übereinstimmen, kannst du dies an der Theke in deinem Fitnesscenter
          klären. Unsere Mitarbeiter helfen dir gerne weiter. Vielen Dank für
          dein Verständnis.
        </v-card-text>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" @click="lastCheckinAllowedDialog = false"
            >Gelesen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkinNotAllowedDialog"
      persistent
      :hide-overlay="false"
    >
      <v-card>
        <v-card-title class="primary white--text"
          >Wichtige Information!</v-card-title
        >
        <v-card-text class="mt-1">
          Hallo {{ userService.getUserFirstName() }},
          <br />
          wir konnten leider keinen Vertrag unter der E-Mail-Adresse finden, mit
          der du dich registriert hast. Eventuell gibt es Unterschiede zwischen
          der E-Mail-Adresse in unserer App und unserem
          Mitgliederverwaltungssystem. Da du dich bereits einmalig ohne gültigen
          Vertrag angemeldet hast, ist eine weitere Anmeldung ohne aktiven
          Vertrag nicht möglich.
          <br />
          Bitte überprüfe deine Vertragsdaten oder kläre dies an der Theke in
          deinem Fitnesscenter. Unsere Mitarbeiter stehen dir gerne zur
          Verfügung. Vielen Dank für dein Verständnis.
        </v-card-text>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" @click="checkinNotAllowedDialog = false"
            >Gelesen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import api from "../../api";
import userService from "../../services/userService";
import { mapGetters, mapActions } from "vuex";
import userSearch from "../../components/userSearch.vue";
import { habitService } from "@/services/habitService.js";
import PullTo from "vue-pull-to";
export default {
  name: "courseListView",
  components: { userSearch, PullTo },
  data() {
    return {
      lastCheckinAllowedDialog: false,
      checkinNotAllowedDialog: false,
      activeContract: false,
      allowLastTime: false,
      fullyLoaded: false,
      userService,
      showUserInformation: false,
      userInformation: null,
      showMarketingDialog: false,
      dialog2: false,
      past: false,
      current: false,
      disableSignup: false,
      disableSignoff: false,
      reqSignup: true,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      signedUp: null,
      lsSignedUp: null,
      isLivestream: null,
      onQueue: null,
      courseView: null,
      signInUserId: null,
      isInstructor: false,
      nowTimer: null,
      shareText:
        "Hey Ich war gerade im Group Fitness. Hättest Du Lust mich zu begleiten? Wenn ja, dann klicke doch einfach auf den Link und melde Dich auch für den Kurs an.",
      now: Math.round(Date.now() / 1000),
    };
  },
  mounted() {
    setTimeout(() => {
      this.fullyLoaded = true;
    }, 2000);
  },
  async created() {
    this.fetchCourseView();
    var self = this;
    this.nowTimer = setInterval(function () {
      self.now = Math.round(Date.now() / 1000);
    }, 1000);

    let hasContract = await api.fetchTACMemberHasExistingContractByEmail();

    this.activeContract = this.$moment(
      hasContract.date,
      "YYYY-MM-DD"
    ).isSameOrAfter(this.$moment());
  },
  beforeDestroy() {
    clearInterval(this.nowTimer);
  },
  methods: {
    ...mapActions("navigationStoreNotPersisted", ["setNavigationBarText"]),
    ...mapActions("navigationStore", ["setSelectedNavigation"]),
    /* initWebsocket() {
            let server = location.hostname.includes('local') ? 'ws://localhost:4000' : 'wss://app.wellcomefit.ch'
            console.log(this.$socket.readyState, this.$socket.readyState !== 1)
            if (this.$socket && this.$socket.readyState !== 1) {
                // the connection is open
                let token = localStorage.getItem('token')
                if (token) {
                    this.$connect(server + '/api/ws?token=' + token)
                    console.log('connecting...')
                }
            }
        },
        websocketHandler(data) { 
            if (data.topic === 'groupFitness') {
                if (data.courseExecution.toString() === this.$route.params.id) {
                    if (data.type === 'signup') this.signedUp += 1
                    if (data.type === 'signoff') this.signedUp -= 1
                }
            }
        },*/
    async claimCourseExecutionHours() {
      try {
        if (this.isInstructor) {
          let status = await api.claimCourseExecutionHours(this.courseView._id);
        } else {
          if (this.courseView?.instructors[0]?._id) {
            let status = await api.claimCourseExecutionHoursForInstructor(
              this.courseView._id,
              this.courseView.instructors[0]._id
            );
          } else {
            throw Error("Fehler");
          }
        }
        this.courseView.hoursClaimed = "yes";
        this.$toast.success("Stunde wurde erfolgreich beansprucht");
      } catch (e) {
        this.courseView.hoursClaimed = null;
        this.$toast.error(
          "Es gab einen Fehler bitte versuche es später erneut"
        );
      }
      this.$emit("updateCourse");
    },
    callPhoneNumber(tel) {
      navigator.clipboard.writeText(tel);
      window.location.href = `tel:${tel}`;
      this.$toast.success("Telefonnummer kopiert.");
    },
    refresh(loaded) {
      console.log("REFRESH");
      loaded("done");
      //this.fetchCourseView()
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      alert("In Zwischenablage kopiert: " + text);
    },
    async addAsHabit() {
      let gfHabit = habitService.getNewGroupFitnessHabit();
      gfHabit.courseTimeline = this.courseView.courseTimelineId;
      if (await gfHabit.save()) {
        // save habit to list
        console.log("saved");
      } else {
        // habit not saved
      }
    },
    showUserInfo(userInformation, signup = false) {
      this.showUserInformation = true;
      this.userInformation = userInformation;

      this.userInformation.signupStatus = signup;
    },
    hideUserInfo() {
      this.showUserInformation = false;
      this.userInformation = null;
    },
    async downloadPrint() {
      let id = this.$route.params.id;
      this.$toast.success("Download gestartet");
      api.downloadA4PDFGF(id);
    },
    async downloadMarketingImage(format) {
      let data = await api.downloadMarketingImageGF(
        format,
        this.$vuetify.theme.themes.light.primary.toString().replace("#", ""),
        this.$route.params.id
      );

      if (window.flutter_inappwebview) {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          var dataUrl = reader.result;
          var base64 = dataUrl.split(",")[1];
          window.flutter_inappwebview.callHandler(
            "blobToBase64Handler",
            base64.toString(),
            "png",
            "SocialMedia"
          );
        };
      } else {
        saveAs(
          data,
          "Marketing_" + format + "_" + Math.round(new Date() / 1000)
        );
      }
    },
    manageVisited(signup) {
      signup.visited = !signup.visited;
      let status = signup.visited ? "1" : "0";
      api.manageSignupVisited(signup._id, status);
    },
    createCalendar() {
      this.$ics.removeAllEvents();
      let startDate = this.$moment(this.courseView.ts * 1000)
        .set({
          hours: this.courseView.hours,
          minutes: this.courseView.minutes,
          seconds: 0,
        })
        .format("YYYY-MM-DD HH:mm");
      let endDate = this.$moment(this.courseView.ts * 1000)
        .set({
          hours: this.courseView.hours,
          minutes: this.courseView.minutes,
          seconds: 0,
        })
        .add(this.courseView.duration, "minutes")
        .format("YYYY-MM-DD HH:mm");
      let until = this.$moment(this.courseView.ts * 1000)
        .set({
          hours: this.courseView.hours,
          minutes: this.courseView.minutes,
          seconds: 0,
        })
        .add(this.courseView.duration, "minutes")
        .add(2, "years")
        .format("YYYY-MM-DD");
      this.$ics.addEvent(
        "de-CH",
        this.courseView.course.name,
        this.courseView.course.beschreibung,
        this.courseView.studio.adresse,
        startDate,
        endDate,
        location.host + "/kurse",
        {
          name: this.courseView.studio.art,
          email: this.courseView.studio.email,
        },
        {
          freq: "WEEKLY",
          until,
          interval: 1,
        }
      );
    },
    downloadCalendar() {
      this.$ics.download("Event");
    },
    formatSignupDate(ts) {
      return this.$moment.unix(ts).format("DD.MM.YY HH:mm");
    },
    async fetchCourseView() {
      let ts = 0;
      if (this.$route.params.ts) ts = this.$route.params.ts;
      let courseViewTemp = await api
        .fetchCourseView(this.$route.params.id, ts)
        .catch((e) => {
          this.$router.push("/kurse");
        });
      this.courseView = courseViewTemp.courseView;
      this.signedUp = courseViewTemp.signedUp;
      this.lsSignedUp = courseViewTemp.lsSignedUp;
      this.onQueue = courseViewTemp.onQueue;
      this.isInstructor = courseViewTemp.isInstructor;
      this.isLivestream = courseViewTemp.isLivestream;
      this.signUpLimit = courseViewTemp.signUpLimit;
      this.past = this.courseView.past;
      this.current = this.courseView.current;

      this.disableSignup = this.courseView.disableSignup;
      this.disableSignoff = this.courseView.disableSignoff;
      this.reqSignup = this.courseView.reqSignup;
      if (this.isLivestream && !this.past && !this.current) {
        this.setNavigationBarText({
          false: "ANMELDEN",
          true: "ABMELDEN",
          state: this.lsSignedUp,
        });
      } else {
        if (
          (this.disableSignup && !this.signedUp) ||
          (this.disableSignoff && this.signedUp) ||
          this.past ||
          this.current ||
          !this.reqSignup ||
          this.courseView.cancelled
        ) {
          this.setNavigationBarText({ text: "ZURÜCK", state: false });
        } else {
          if (
            courseViewTemp.onQueue ||
            (!courseViewTemp.signedUp &&
              this.courseView.amount <= this.courseView.signups.length)
          ) {
            this.setNavigationBarText({
              false: "IN WARTELISTE EINTRAGEN",
              true: "AUSTRAGEN",
              state: this.signedUp,
            });
          } else {
            this.setNavigationBarText({
              false: "ANMELDEN",
              true: "ABMELDEN",
              state: this.signedUp,
            });
          }
        }
      }

      if (this.courseView._id !== this.$route.params.id) {
        this.$router.push("/kurse/" + this.courseView._id);
      }
      this.createCalendar();
    },
    cancelUserSignIn() {
      this.dialog2 = false;
      this.signInUserId = null;
    },
    async signOutUser(userId) {
      let courseSignup = await api.manageUserCourseSignup(
        this.courseView._id,
        false,
        userId
      );
      this.fetchCourseView();
    },
    async signInUser(id) {
      let courseSignup = await api.manageUserCourseSignup(
        this.courseView._id,
        true,
        id
      );
      this.fetchCourseView();
      this.cancelUserSignIn();
      this.signInUserId = null;
    },
    selectUserIdSignIn(user) {
      this.signInUserId = user;
    },
    async manageSignUp(signUpStatus) {
      /*if (!this.activeContract) {
                await api.courseSignupWithoutContract()
            }*/
      let courseSignup = await api.manageCourseSignup(
        this.courseView._id,
        signUpStatus
      );
      if (courseSignup.simulatenousSignup) {
        this.$toast.error(
          "Du kannst dich nicht für mehrere Kurse gleichzeitig anmelden"
        );
      }
      if (courseSignup.toManyEventSignups) {
        alert(
          "Du hast dich bereits für zu viele Kurse dieses Events angemeldet."
        );
      }
      this.fetchCourseView();
      this.$emit("updateCourse");
    },
    editCourse() {
      this.$router.push("/kurse/" + this.courseView._id + "/edit");
    },
    signupCounter() {
      if (
        (this.disableSignup && !this.signedUp) ||
        (this.disableSignoff && this.signedUp) ||
        this.past ||
        this.current ||
        !this.reqSignup ||
        (this.courseView && this.courseView.cancelled)
      ) {
        return "";
      } else {
        if (this.courseView && this.courseView.signups) {
          let amount = this.courseView.amount - this.courseView.signups.length;
          if (amount === 1) {
            return "noch " + amount + " Platz verfügbar!";
          } else {
            if (amount > 0) {
              return "noch " + amount + " Plätze verfügbar!";
            } else {
              if (this.onQueue) {
                return "";
              } else {
                return "";
              }
            }
          }
        } else {
          if (this.courseView) return this.courseView.amount + " Plätze";
          else return "";
        }
      }
    },
    shareViaWebShare() {
      navigator.share({
        title: this.courseView.course.name,
        text: this.shareText,
        url: window.location.href,
      });
    },
    destroyed() {
      this.setNavigationBarText(null);
    },
    closeDialog() {
      this.setSelectedNavigation(null);
      this.setNavigationBarText(null);

      this.dialog = false;
      setTimeout(() => {
        this.setSelectedNavigation("groupFitness");
        if (
          this.$router.history &&
          this.$router.history._startLocation &&
          this.$router.history._startLocation.includes("event")
        ) {
          this.$router.go(-1);
        }

        this.$router.push("/kurse");
      }, 25);
    },
    courseEndDate(course) {
      let endTime = this.$moment();
      endTime.set({
        hours: course.hours,
        minutes: course.minutes,
        seconds: 0,
        miliseconds: 0,
      });

      endTime.add(course.duration, "minutes");
      return endTime.format("LT");
    },
    courseStartDate(course) {
      let startTime = this.$moment();
      startTime.set({
        hours: course.hours,
        minutes: course.minutes,
        seconds: 0,
        miliseconds: 0,
      });

      return startTime.format("LT");
    },
  },
  watch: {
    navigationBarTextState(value) {
      if (this.isLivestream && !this.past && !this.current) {
        if (value !== this.lsSignedUp && value !== null) {
          this.manageSignUp(value);
          if (!this.activeContract) {
            /*if (this.allowLastTime) {
                            return (this.lastCheckinAllowedDialog = true)
                        }
                        return (this.checkinNotAllowedDialog = true) */
          }
        }
      } else {
        if (
          (this.disableSignup && !this.signedUp) ||
          (this.disableSignoff && this.signedUp) ||
          this.past ||
          this.current ||
          !this.reqSignup ||
          this.courseView.cancelled
        ) {
          if (value) this.closeDialog();
        } else {
          if (value !== this.signedUp && value !== null) {
            if (!this.activeContract) {
              //await api.createSignupWithoutContract()
              /* if (this.allowLastTime) {
                                return (this.lastCheckinAllowedDialog = true)
                            }
                            return (this.checkinNotAllowedDialog = true) */
            }
            this.manageSignUp(value);
          }
        }
      }
    },
  },

  computed: {
    atLeastOneInstructor() {
      return this.courseView?.instructors[0]?._id;
    },
    isIOS() {
      let isIOS = localStorage.getItem("isIOS") ? true : false;
      return isIOS;
    },
    userMail() {
      let mail = this.userService.getUser().email
        ? this.userService.getUser().email
        : null;
      return mail;
    },
    lsTimeLeft() {
      this.now;

      if (this.lsSignedUp && !this.lsRunning) {
        let dateNow = this.$moment();
        let startDate = this.$moment(this.courseView.ts * 1000);

        startDate.set({
          hours: this.courseView.hours,
          minutes: this.courseView.minutes,
        });

        var duration = this.$moment.duration(startDate.diff(dateNow));

        // duration in hours
        var hours = parseInt(duration.asHours());
        if (hours < 10) hours = "0" + hours;

        // duration in minutes
        var minutes = parseInt(duration.asMinutes()) % 60;
        if (minutes < 10) minutes = "0" + minutes;

        var seconds = parseInt(duration.asSeconds()) % 60;
        if (seconds < 10) seconds = "0" + seconds;

        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "";
      }
    },
    lsRunning() {
      this.now;

      if (
        this.courseView &&
        this.courseView.lsLink &&
        (this.lsSignedUp ||
          this.userMail === "kn@wellcomefit.ch" ||
          this.userMail === "dominic.ammann.ch@gmail.com" ||
          this.userMail === "sebs@wellcomefit.ch" ||
          this.userMail === "tk@wellcomefit.ch" ||
          this.userMail === "mk@wellcomefit.ch" ||
          this.userMail === "cm@wellcomefit.ch") &&
        this.courseView.duration
      ) {
        let startDate = this.$moment(this.courseView.ts * 1000);
        startDate.set({
          hours: this.courseView.hours,
          minutes: this.courseView.minutes,
        });
        let stopDate = this.$moment(startDate).add(
          this.courseView.duration + 5,
          "minutes"
        );
        if (this.now > startDate.unix() && this.now < stopDate.unix()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    schwandenStudio() {
      let user = JSON.parse(localStorage.getItem("user"));
      let studioCode = null;
      this.routeChanged;
      if (user && user.user) studioCode = user.user.studio_id;
      if (studioCode && studioCode === "schwanden") {
        return true;
      } else {
        return false;
      }
    },
    niederurnenStudio() {
      let user = JSON.parse(localStorage.getItem("user"));
      let studioCode = null;
      this.routeChanged;
      if (user && user.user) studioCode = user.user.studio_id;
      if (studioCode && studioCode === "niederurnen") {
        return true;
      } else {
        return false;
      }
    },

    livestreamStudio() {
      let user = JSON.parse(localStorage.getItem("user"));
      let studioCode = null;
      this.routeChanged;
      if (user && user.user) studioCode = user.user.studio_id;
      if (studioCode && studioCode === "ot") {
        return true;
      } else {
        return false;
      }
    },
    headerImageSrc() {
      if (this.courseView && this.courseView.course) {
        return "/img/kurse/" + this.courseView.course.bild;
      } else {
        return "";
      }
    },
    ...mapGetters("navigationStoreNotPersisted", ["navigationBarTextState"]),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .signupDialog {
  align-self: flex-start;
}
.ls-announcement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--v-primary);
  color: white;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: -25px;

  > span {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  &-countdown {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    &-time {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      > span:first-of-type {
        font-size: 34px;
      }
      span {
        text-align: center;
      }
    }
  }
}
.glowingDot {
  width: 10px;
  height: 10px;
  margin: auto 0px;
  padding-right: 5px;
  border-radius: 50%;
  &-signup {
    box-shadow: inset 0px 0px 10px 2px rgb(0 255 0 / 20%),
      0px 0px 10px 2px rgb(0 255 0);
  }
  &-signoff {
    box-shadow: inset 0px 0px 10px 2px rgb(255 0 0 / 20%),
      0px 0px 10px 2px rgb(255 0 0);
  }
}
.user-add-btn {
  background-color: var(--v-primary);
}
::v-deep .mdi-star {
  padding: 0px;
}
::v-deep .mdi-star-outline {
  padding: 0px;
}
::v-deep .dialog {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper-outer {
  height: 100vh;
  width: 100vw;
  background-color: #f7f8f8;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.wrapper-inner {
  height: 84vh;
  background-color: #f7f8f8;
  overflow-y: scroll;
  width: 100vw;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.headerImage {
  max-width: 500px;
  min-height: 35vh;
  background-color: white;
  width: 100%;
}
.speech-bubble {
  border-radius: 4px;
  position: relative;
  background-color: var(--v-primary) !important;
}
s .speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 26px solid #0000;
  border-top-color: var(--v-primary);
  border-bottom: 0;
  border-left: 4.5px;
  margin-left: -25.5px;
  margin-bottom: -20px;
}

.hide-lower-1 {
  position: fixed;
  width: 100vw;
  height: 30px;
  bottom: 130px;
  right: 0px;
  background-image: linear-gradient(#ffffff4d, #f7f8f8f5);
}
.hide-lower-2 {
  position: fixed;
  width: 100vw;
  height: 130px;
  bottom: 0px;
  right: 0px;
  background-image: linear-gradient(#f7f8f8f5, #f7f8f8);
}
.signupsCount {
  z-index: 999;
  position: fixed;
  bottom: 105px;
  width: 100vw;
  display: flex;
  justify-content: center;
  right: 0px;
}

.courseListView {
  margin-top: 35vh !important;
  z-index: 999;
  width: 100vw;
  max-width: 500px;
  &__container {
    position: relative;
    min-height: 500px;
    background-color: #f7f8f8;

    &-like {
      position: absolute;
      top: -80px;
      right: 11%;
      z-index: 2;
    }
    &-edit {
      position: absolute;
      top: -80px;
      right: 10px;
      z-index: 2;
    }
    &-instructor {
      &-img {
        border-radius: 100%;
      }
    }
    &-back {
      position: fixed;
      top: 20px;
      left: 11%;
      z-index: 3;
    }
    &-info {
      z-index: 1;

      padding-top: 10px;

      &-title {
        text-align: center;
        letter-spacing: 1.2px;
        font-weight: bold;
        color: #071e20;
        word-break: break-word;
      }
    }
  }
  &-course {
    &-signups {
      &-checkbox {
        font-size: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
