<template xmlns="">
  <v-dialog
    persistent
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div class="wrapper-outer primary">
      <div
        @scroll="fixPosition"
        class="wrapper-inner"
        :class="{ 'wrapper-inner--open': windowIndex === 2 }"
      >
        <div
          v-if="windowIndex === 1"
          style="align-content: center"
          :class="windowIndex === 1 ? 'slim-cropper' : 'slim-cropper--second'"
          class="justify-content-center ma-0"
        >
          <v-col cols="auto">
            <lottie-player
              mode="normal"
              style="height: 20vh"
              name="roody_icon"
              autoplay
              src="/img/lottiefiles/roody_animated.json"
            ></lottie-player>
          </v-col>
        </div>

        <v-card
          dark
          style="background: transparent !important"
          class="courseListView"
          elevation="0"
        >
          <v-btn
            :class="
              windowIndex === 1
                ? 'courseListView__container-back'
                : 'courseListView__container-back--second'
            "
            fab
            dark
            color="white"
            @click="closeCreateView"
          >
            <v-icon color="black">mdi-chevron-left</v-icon>
          </v-btn>

          <div
            no-gutters
            class="courseListView__container"
            style="display: flex; flex-direction: column"
          >
            <v-row
              v-if="windowIndex === 1"
              style="margin-bottom: -2px !important"
              :class="
                windowIndex === 1 ? 'create-title' : 'create-title--second'
              "
            >
              <v-col class="pa-0" cols="12">
                <v-row class="d-flex flex-row py-3 justify-center mx-0 mb-0">
                  <v-card-title
                    style="letter-spacing: 1px"
                    class="h5 pt-5 pb-2 white--text my-0 font-weight-bold text-uppercase"
                  >
                    {{ formTitle }}
                  </v-card-title>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              v-if="windowIndex === 1"
              no-gutters
              justify="center"
              style="max-height: 100vh; background: #313131"
            >
              <v-form
                lazy-validation
                dark
                ref="createForm"
                class="workoutCreate__swiper-card-form"
              >
                <v-window touchless v-model="formWindow">
                  <v-window-item :value="1">
                    <v-text-field
                      label="Titel"
                      class="mt-3"
                      filled
                      rounded
                      outlined
                      ref="title"
                      :value="form.title"
                      :rules="rules.title"
                      prepend-icon="mdi-format-title"
                      counter="50"
                      @change="updateFormByChange('title', $event)"
                      required
                    ></v-text-field>

                    <user-search
                      filled
                      rounded
                      @inputEvent="fixPosition"
                      :value="form.user_id"
                      rights="tp_create"
                      class="mb-5"
                      v-if="userService.hasRight('tp_create')"
                      label="Kunde"
                      @update="updateCustomer"
                    ></user-search>

                    <user-search
                      filled
                      rounded
                      @inputEvent="fixPosition"
                      :value="form.instructor_id"
                      rights="tp_create"
                      v-if="userService.hasRight('tp_create')"
                      label="Trainer"
                      class="mb-5"
                      instructor
                      @update="updateInstructor"
                    ></user-search>
                    <p class="red--text" v-if="noInstructorSnackbar">
                      Bitte Trainer auswählen
                    </p>
                    <v-card-actions class="justify-content-center">
                      <v-btn
                        color="primary"
                        x-large
                        rounded
                        @click="formWindow = 2"
                      >
                        weiter
                      </v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-row no-gutters>
                      <v-col
                        v-for="(split, index) in splitItems"
                        cols="12"
                        :key="index"
                      >
                        <v-card
                          @click="
                            form.splits = JSON.parse(JSON.stringify(split))
                          "
                          class="rounded split-item mb-3"
                          :class="{
                            'split-item--active':
                              form.splits && form.splits.text === split.text,
                          }"
                        >
                          <div style="background: #313131 !important">
                            <v-card-title>{{ split.text }}</v-card-title>
                            <v-card-subtitle>
                              {{ split.info }}
                            </v-card-subtitle>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-select
                      v-if="false"
                      outlined
                      filled
                      rounded
                      return-object
                      :hide-details="form.splits"
                      prepend-icon="mdi-format-list-bulleted"
                      label="Split"
                      :items="splitItems"
                      @change="
                        (data) =>
                          (form.splits = JSON.parse(JSON.stringify(data)))
                      "
                      v-model="form.splits"
                    ></v-select>
                    <div v-if="form.splits && false">
                      <v-card-subtitle>{{ form.splits.info }}</v-card-subtitle>
                      <div class="d-flex flex-row" style="flex-wrap: wrap">
                        <v-chip
                          @click="activeFormSplit = index"
                          close
                          @click:close="form.splits.workouts.splice(index, 1)"
                          :key="workout.title"
                          class="mr-1 mb-1"
                          :color="index === activeFormSplit ? 'primary' : ''"
                          v-for="(workout, index) in form.splits.workouts"
                        >
                          {{ workout.title }}
                        </v-chip>
                        <v-chip @click="addWorkoutToSplit = true">+</v-chip>
                      </div>
                    </div>
                    <v-card-actions class="justify-content-center">
                      <v-btn
                        color="primary"
                        x-large
                        rounded
                        @click="formWindow = 1"
                      >
                        zurück
                      </v-btn>
                      <v-btn
                        color="primary"
                        x-large
                        rounded
                        @click="windowIndex = 2"
                      >
                        weiter
                      </v-btn>
                    </v-card-actions>
                  </v-window-item>
                </v-window>
                <v-menu
                  v-if="false"
                  ref="menu1"
                  v-model="menu"
                  :close-on-content-click="false"
                  auto
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      filled
                      v-model="formattedDate"
                      label="Neuer Plan fällig"
                      ref="calendar"
                      readonly
                      outlined
                      prepend-icon="mdi-calendar"
                      :rules="[(v) => !!v || 'Dies ist ein Pflichtfeld']"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-form>
            </v-row>
            <v-row
              justify="center"
              style="max-height: 100vh; background: #313131"
              v-if="windowIndex === 2"
              no-gutters
            >
              <workout-split-edit
                @return="windowIndex = 1"
                :exerciseIds="
                  workoutCreateExercises.map((exercise) => exercise._id)
                "
                :form="form"
              ></workout-split-edit>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="addWorkoutToSplit">
      <v-card dark>
        <v-card-title>Split hinzufügen</v-card-title>
        <v-card-text>
          <v-text-field
            label="Split Bezeichnung"
            filled
            outlined
            v-model="addNewSplitText"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" @click="saveWorkoutToSplit">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--
                <workout-step-editable
                v-for="(exercise, index) in workoutCreateExercises"
                :id="exercise.title"
                :addState="workoutCreateExercises.length + 2"
                :key="'workoutStepEditable' + index + exercise._id"
                :prop-exercise="exercise"
            :exercise-index="index"
            @updateBottomNavigation="updateBottomNavigation"
            ></workout-step-editable>
            -->
    <!-- .filter((exercise) => exercise._id !== exerciseInEditMode._id) -->
    <!-- <workout-create-edit-exercise v-else :exercise="exerciseInEditMode" @resetEdit="resetExerciseInEditMode" @saveEdit="saveExerciseEdited"></workout-create-edit-exercise>
            -->

    <workout-add-exercise
      :value="workoutCreateExercises.length + 2"
      @add="addExercise"
      :exerciseIds="workoutCreateExercises.map((exercise) => exercise._id)"
      @triggerKey="updateSwiperKey()"
      @updateBottomNavigation="updateBottomNavigation"
    ></workout-add-exercise>
    <workout-create-submit-slide
      :value="workoutCreateExercises.length + 3"
      @save="createWorkoutPlan"
      @changeSorting="setSwiperAllowTouchMove"
      :exercises="workoutCreateExercises"
      @updateBottomNavigation="updateBottomNavigation"
    ></workout-create-submit-slide>
  </v-dialog>
</template>

<script>
import api from "../../api";
import userSearch from "../../components/userSearch.vue";

import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState, mapActions, mapGetters } from "vuex";
import WorkoutAddExercise from "./WorkoutAddExercise";
import WorkoutCreateEditExercise from "./WorkoutCreateEditExercise";
import WorkoutCreateSubmitSlide from "./WorkoutCreateSubmitSlide.vue";
import userService from "../../services/userService";
import imageOverlappingCard from "../../components/imageOverlappingCard.vue";
import workoutStepEditable from "./WorkoutStepEditable";
import workoutSplitEdit from "./WorkoutSplitEdit.vue";

export default {
  name: "WorkoutCreate",
  components: {
    WorkoutAddExercise,
    userSearch,
    Swiper,
    SwiperSlide,
    WorkoutCreateEditExercise,
    WorkoutCreateSubmitSlide,
    workoutSplitEdit,
    imageOverlappingCard,
    workoutStepEditable,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      formWindow: 1,
      addNewSplitText: "",
      addWorkoutToSplit: false,
      activeFormSplit: 0,
      splitItems: [
        {
          text: "Ganzkörper-Training",
          value: "fullBodySplit",
          info: "Jede Trainingseinheit trainiert den gesamten Körper.",
          workouts: [
            {
              title: "Ganzkörper",
              recommendedTags: [
                "Schienbein",
                "Rundmuskel",
                "Trapez",
                "Trizeps",
                "Latissimus",
                "Rückenstrecker",
                "Gesäss",
                "Beinbeuger",
                "Wade",
                "Vordere Schulter",
                "Scholle",
                "Seitliche Schulter",
                "Brust",
                "Hintere Schulter",
                "Gerader Bauch",
                "Bizeps",
                "Seitlicher Bauch",
                "Unterarm Unten",
                "Oberschenkel",
                "Nacken",
                "Adduktoren",
                "Unterarm Oben",
                "Herz-Kreislauf",
                "Dehnen",
                "Abduktoren",
              ],
            },
          ],
        },
        {
          text: "Oberkörper-/Unterkörper Split",
          value: "upperLowerSplit",
          info: "Ein Tag ist für den Oberkörper, der andere für den Unterkörper.",
          workouts: [
            {
              title: "Oberkörper",
              recommendedTags: [
                "Brust",
                "Vordere Schulter",
                "Seitliche Schulter",
                "Hintere Schulter",
                "Bizeps",
                "Trizeps",
                "Rückenstrecker",
                "Latissimus",
                "Trapez",
                "Unterarm Unten",
                "Unterarm Oben",
                "Gerader Bauch",
                "Seitlicher Bauch",
                "Nacken",
              ],
            },
            {
              title: "Unterkörper",
              recommendedTags: [
                "Oberschenkel",
                "Wade",
                "Beinbeuger",
                "Adduktoren",
                "Abduktoren",
                "Scholle",
                "Gesäss",
                "Schienbein",
              ],
            },
          ],
        },
        {
          text: "Push/Pull/Beine-Split",
          value: "pushPullLegSplit",
          info: "In diesem Split werden drückende Muskeln (Push), ziehende Muskeln (Pull) und Beine jeweils an separaten Tagen trainiert.",
          workouts: [
            {
              title: "Push",
              recommendedTags: [
                "Brust",
                "Vordere Schulter",
                "Trizeps",
                "Seitliche Schulter",
              ],
            },
            {
              title: "Pull",
              recommendedTags: [
                "Rückenstrecker",
                "Latissimus",
                "Trapez",
                "Bizeps",
                "Hintere Schulter",
                "Unterarm Unten",
                "Unterarm Oben",
              ],
            },
            {
              title: "Beine",
              recommendedTags: [
                "Oberschenkel",
                "Wade",
                "Beinbeuger",
                "Adduktoren",
                "Abduktoren",
                "Scholle",
              ],
            },
          ],
        },
        {
          text: "Bro-Split",
          value: "broSplit",
          info: "Jede Muskelgruppe hat einen eigenen Trainingstag.",
          workouts: [
            {
              title: "Brust",
              recommendedTags: ["Brust", "Seitlicher Bauch", "Gerader Bauch"],
            },
            {
              title: "Rücken",
              recommendedTags: [
                "Latissimus",
                "Rückenstrecker",
                "Trapez",
                "Schienbein",
              ],
            },
            {
              title: "Schultern",
              recommendedTags: [
                "Vordere Schulter",
                "Seitliche Schulter",
                "Hintere Schulter",
                "Nacken",
              ],
            },
            {
              title: "Beine",
              recommendedTags: [
                "Oberschenkel",
                "Wade",
                "Beinbeuger",
                "Adduktoren",
                "Abduktoren",
                "Scholle",
              ],
            },
            {
              title: "Arme",
              recommendedTags: [
                "Bizeps",
                "Trizeps",
                "Unterarm Unten",
                "Unterarm Oben",
              ],
            },
          ],
        },
      ],
      windowIndex: 1,
      allowTouchMove: false,
      slimOptions: {
        ratio: "1:1",
        initialImage: "/img/einmalig/imageUploadPlaceholderBottomBorder.jpg",
        buttonCancelLabel: "Abbrechen",
        buttonConfirmLabel: "Bestätigen",
        label: "Bild hochladen",
        labelLoading: "Bild wird hochgeladen",
        statusUploadSuccess: "gespeichert",
        service: this.uploadPicture,
        serviceFormat: "file",
        instantEdit: true,
        push: true,
      },
      swiperKey: 0,
      loaded: false,
      navigationBarInitiated: false,
      dialog: true,
      noInstructorSnackbar: false,
      userService,
      showDialog: false,
      menu: false,
      exercises: [],
      exerciseInEditMode: {
        _id: null,
      },
      date: this.parseDate(this.$moment().add(10, "weeks")),
      form: {
        picture: null,
        title: "",
        duration: "",
        user_id: null,
        instructor_id: null,
        splits: { title: "" },
      },
      rules: {
        title: [
          (v) =>
            (v.length <= 50 && v.length > 0) ||
            "Der Titel darf bis zu 50 Zeichen lang sein.",
        ],
        duration: [(v) => (v > 0 && v < 1000) || "Maximal 3 Zeichen"],
      },
      exampleImages: [
        "workoutExample.png",
        "workoutExample1.png",
        "workoutExample2.png",
      ],
    };
  },
  created() {
    if (
      this.workoutCreate.form.title &&
      !this.isInEditMode &&
      !this.planInEditMode
    ) {
      if (
        !confirm(
          "Es wurden zwischengespeicherte Daten gefunden. Möchtest du diese laden?"
        )
      )
        this.closeWorkoutForm(false);
      this.form = this.workoutCreate.form;
    } else {
      if (this.isInEditMode) {
        this.form = this.planInEditMode.form;
      } else if (this.planInEditMode) {
        this.resetPlanInEditMode();
        this.closeWorkoutForm(false);
      }
    }
    if (!this.form.ts) {
      let ts = {
        key: "ts",
        value: this.$moment().unix().toString(),
      };
      this.updateWorkoutCreateForm(ts);
      this.form.ts = ts;
    }
    let due = {
      key: "due",
      value: this.formatDate(this.$moment().add(10, "weeks")),
    };
    this.updateWorkoutCreateForm(due);
    this.form.due = due.value;
    this.setNavigationBarText({ text: "weiter", state: false });

    this.loaded = true;
  },
  beforeDestroy() {
    window.removeEventListener("orientationchange", this.reloadLocation);
    this.setNavigationBarText(null);
  },
  mounted() {
    window.addEventListener("orientationchange", this.reloadLocation);
  },
  methods: {
    ...mapActions("navigationStore", ["setSelectedNavigation"]),
    ...mapActions("navigationStoreNotPersisted", ["setNavigationBarText"]),
    ...mapActions("workingoutStore", [
      "updateWorkoutCreateForm",
      "addExerciseToWorkoutCreateExercises",
      "resetWorkoutCreateExercises",
      "resetWorkoutCreateForm",
      "resetPlanInEditMode",
      "setWorkoutCreateDefaultValues",
    ]),
    saveWorkoutToSplit() {
      this.form.splits.workouts.push({
        title: JSON.parse(JSON.stringify(this.addNewSplitText)),
      });
      this.addNewSplitText = "";
      this.addWorkoutToSplit = false;
    },
    changeSlide() {
      return this.setSelectedNavigation(this.windowIndex);
    },
    fixPosition() {
      const menu = document.querySelector(
        '.v-menu__content:not([style*="display: none"])'
      );

      if (!menu) return;
      const input = document
        .querySelector(".v-select--is-menu-active")
        .getBoundingClientRect();

      menu.style.top = input.bottom - 20 + "px";
    },
    reloadLocation() {
      //location.reload()
    },
    getRandomImage() {
      let index = Math.floor(Math.random() * 3);
      return this.exampleImages[index];
    },
    uploadPicture(formdata, progress, success, failure, slim) {
      const formData = new FormData();
      formData.append("file", formdata[0]);

      let uploadimg = {};
      uploadimg.art = "workout";
      uploadimg.formData = formData;
      api
        .uploadImage(uploadimg)
        .then((data) => {
          if (data.status) {
            this.form.picture = data.name;
            success(data);
          }
        })
        .catch((error) => {
          failure(error);
        });
    },
    updateSwiperKey() {},
    async updateBottomNavigation(state = null) {
      if (this.windowIndex === this.workoutCreateExercises.length + 3) return;
      console.log("enter update");
      let buttons = [
        {
          title: 1,
          text: "Informationen",
          icon: "/img/lottiefiles/BottomNavigation/infosFilled.json",
        },
      ];
      let newState = this.workoutCreateExercises.length + 2;
      this.workoutCreateExercises.forEach((exercise, index) => {
        buttons.push({
          title: index + 2,
          text: exercise.title,
          ignoreIndex: true,
          image: this.getImageForExercise(exercise),
        });
      });

      buttons.push({
        title: this.workoutCreateExercises.length + 2,
        text: "neue Übung",
        icon: "/img/lottiefiles/BottomNavigation/addExerciseFilled.json",
      });
      buttons.push({
        title: this.workoutCreateExercises.length + 3,

        text: "Abschliessen",
        icon: "/img/lottiefiles/BottomNavigation/checkFilled.json",
      });

      await this.setNavigationBarText({
        customButtons: true,
        buttons,
        componentHandles: true,

        endPoint: "createWorkout",
      });
      if (this.workoutCreateExercises.length) newState = 2;
      if (state) newState = state;
      console.log("ddddd", newState);
      this.setSelectedNavigation(newState);
    },
    updateFormByChange(key, value) {
      this.updateWorkoutCreateForm({ key, value });
    },
    updateCustomer(id) {
      this.updateFormByChange("user_id", id);
      this.form.user_id = id;
    },
    getImageForExercise(exercise) {
      if (exercise.picture) return this.getImageUrl(exercise.picture);
      if (exercise.manual && exercise.manual[0] && exercise.manual[0].picture)
        return this.getImageUrl(exercise.manual[0].picture);
      return "/img/uebungen/einmalig/imageUploadPlaceholder.jpg";
    },
    updateInstructor(id) {
      this.updateFormByChange("instructor_id", id);
      this.form.instructor_id = id;
    },
    setSwiperAllowTouchMove(value = null) {
      if (!value) value = !this.allowTouchMove;
      this.allowTouchMove = value;
    },
    async createWorkoutPlan() {
      this.$route.params.id
        ? await api.updateTrainingsPlan(this.getFormData())
        : await api.createTrainingsPlan(this.getFormData());
      this.closeWorkoutForm();
    },
    closeWorkoutForm(push = true) {
      this.resetWorkoutCreateExercises();
      this.resetWorkoutCreateForm();
      this.$emit("reload-workouts");
      if (push) {
        if (
          this.form.user_id &&
          this.form.instructor_id &&
          this.form.user_id.toString() !== this.form.instructor_id.toString() &&
          this.userService.hasRight("tp_create")
        ) {
          this.$router.push("/gfrecommendations/" + this.form.user_id);
        } else {
          this.$router.push("/workouts");
        }
      }
    },
    validateForm() {
      console.log("validating");
      this.noInstructorSnackbar = false;
      if (this.$refs.createForm.validate()) {
        if (
          this.userService.hasRight("tp_create") &&
          this.form.instructor_id === null
        ) {
          console.log("no instructor");
          this.noInstructorSnackbar = true;
        } else {
          console.log("updating");
          this.updateBottomNavigation();
        }
      } else {
        console.log("not valid");
      }
    },
    getFormData() {
      let formData = {};
      formData.form = { ...this.workoutCreate.form };
      if (this.$route.params.id) formData.form.plan_id = this.$route.params.id;
      formData.form.user_id = this.form.user_id;
      formData.form.instructor_id = this.form.instructor_id;
      formData.form.picture = this.form.picture;
      if (!formData.form.picture) formData.form.picture = this.getRandomImage();
      formData.exercises = this.workoutCreateExercises.map((exercise) => {
        exercise.sets = exercise.sets.map((set, index) => {
          if (!set.settings)
            this.$toast.error(
              "Bei der Übung " +
                exercise.title +
                " ist im Satz " +
                (index + 1) +
                " keine Einstellung vorhanden."
            );
          let serializedSettings = set.settings.filter(
            (setting) => setting && setting.value
          );
          let weightSettingIndex = set.settings.findIndex(
            (setting) => setting.text === "Gewicht"
          );
          if (
            weightSettingIndex !== -1 &&
            !set.settings[weightSettingIndex].startValue &&
            !this.$route.params.id
          )
            set.settings[weightSettingIndex].startValue =
              set.settings[weightSettingIndex].value;

          return {
            settings: serializedSettings,
          };
        });
        let returnObject = {
          general_exercise_settings: exercise.general_exercise_settings,
          sets: exercise.sets,
        };
        if (exercise.exercise_id) {
          returnObject.exercise_id = exercise.exercise_id;
          returnObject._id = exercise._id;
        } else {
          returnObject.exercise_id = exercise._id;
        }
        return returnObject;
      });
      formData.form.due = this.getDateAsUnixString();
      formData.form.duration = parseInt(formData.form.duration);
      return formData;
    },
    addExercise(exercise) {
      this.addExerciseToWorkoutCreateExercises(exercise);
    },
    getImageUrl(imageId) {
      return "/img/uebungen/" + imageId.replace(/\.[^/.]+$/, "") + ".webp";

      return "/img/uebungen/" + imageId;
    },
    getItemText(user) {
      return user.vorname + " " + user.nachname + " (" + user.email + ")";
    },
    formatDate(date) {
      return this.$moment(date).format("DD. MM. YYYY");
    },
    parseDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    getDateAsUnixString() {
      return this.$moment(this.workoutCreate.form.due, "DD. MM. YYYY")
        .unix()
        .toString();
    },
    goToWorkouts() {
      this.$router.push({ name: "Workouts" });
    },
    closeCreateView() {
      this.dialog = false;
      this.setSelectedNavigation(null);
      setTimeout(() => {
        this.setSelectedNavigation("workout");
        this.goToWorkouts();
      }, 100);
    },
  },
  computed: {
    ...mapState("workingoutStore", [
      "workoutCreateExercises",
      "workoutCreate",
      "planInEditMode",
      "workoutCreateDefaultValues",
    ]),
    ...mapState("navigationStore", ["selectedNavigation"]),
    displayedPicture() {
      let url = "/img/";

      return this.form.picture
        ? url + "workout/" + this.form.picture
        : url + "einmalig/imageUploadPlaceholder.jpg";
    },
    formattedDate() {
      return this.formatDate(this.date);
    },
    isInEditMode() {
      return !!this.$route.params.id;
    },
    formTitle() {
      return this.windowIndex === 2
        ? this.form.title
        : this.windowIndex === 1 && this.formWindow === 2
        ? "Wähle deinen Split"
        : this.$route.params.id
        ? "Trainingsplan Bearbeiten"
        : "Trainingsplan Erstellen";
    },
  },
  watch: {
    date(val) {
      // ToDo: Überprüfen pls
      this.updateWorkoutCreateForm({
        key: "due",
        value: this.formatDate(this.date),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.split-item {
  --border-image: linear-gradient(90deg, #636363, #979797, #d0d0d0, #ffffff);
  --border-radius: 8px;
  background-image: var(--border-image);
  background-origin: border-box;
  border-color: transparent;
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 4px;
  clip-path: inset(0% 0% 0% 0% round var(--border-radius));
  &--active {
    --border-image: linear-gradient(90deg, #006633, #00963f, #13a633, #006633);
  }
}
::v-deep .swiper-slide {
  transform: unset !important;
}

.slim-cropper {
  height: 35vh;
  transition: height 0.5s ease-in-out;
}

.slim-cropper--second {
  height: 1vh;
  transition: height 0.5s ease-in-out;
}

.create-title {
  background: #313131;
  margin: -12px 0px;
  padding-top: 0px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  transition: border-radius 1s ease-in-out, padding-top 0.5s ease-in-out;
}

.create-title--second {
  background: #313131;
  margin: -12px 0px;
  padding-top: 10px;
  transition: border-radius 0.5s ease-in-out, padding-top 0.5s ease-in-out;
}
::v-deep .v-menu {
  z-index: 1000 !important;
}

.h5 {
  text-transform: uppercase;
  font-weight: bold;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
  bottom: 5px;
}

::v-deep .dialog {
  background-color: white !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper-outer {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.wrapper-inner {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  overflow-y: scroll;
  transition: max-width 0.3s ease-in-out;

  &--open {
    transition: max-width 0.3s ease-in-out;
    max-width: 100vw !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.headerImage {
  max-width: 500px;
  min-height: 35vh;
  background-color: white;
  width: 100%;
}
.courseListView {
  // margin-top: 35vh !important;
  &__container {
    height: 65vh;
    position: relative;
    &-like {
      position: absolute;
      top: -80px;
      right: 11%;
      z-index: 2;
    }
    &-edit {
      position: absolute;
      top: -80px;
      left: 11%;
      z-index: 2;
    }
    &-instructor {
      max-height: 130px;
      overflow-x: auto;
      &-img {
        border-radius: 100%;
      }
    }
    &-back {
      position: fixed;
      top: 20px;
      left: 11%;
      z-index: 3;
      transition: opacity 0.3s ease-in-out;
    }
    &-back--second {
      position: fixed;
      top: 20px;
      left: 11%;
      z-index: 3;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      pointer-events: none;
    }
    &-info {
      margin-top: -50px;
      z-index: 1;
      background-color: white;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      padding-top: 10px;

      &-title {
        font-weight: bold;
        color: #071e20;
        word-break: break-word;
      }
    }
  }
  &-course {
    &-signups {
      &-checkbox {
        font-size: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

.workoutCreate {
  &__swiper {
    &-card {
      &-content {
        overflow-y: auto;
        height: 62vh;
      }
      &-image {
        margin: 0 10%;
        height: 40%;
        background-size: contain;
      }

      &-form {
        width: 80%;
      }

      &-title {
        word-break: break-word;
      }

      &-delete {
        position: absolute;
        right: 20px;
      }

      &-steps {
        width: unset;

        &__wrapper {
          width: 80%;
          height: 60%;
          text-align: center;
          margin-left: 10%;
        }
      }
    }
  }
  &__new-exercise-card {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
