var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"dark":"","fixed":"","temporary":_vm.$vuetify.breakpoint.mobile,"expand-on-hover":!_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',_vm._l((_vm.filteredItems),function(item,index){return _c('div',{key:index},[(item.subItems)?_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('div',{staticClass:"d-flex"},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.name === _vm.$route.name
                                            ? 'primary'
                                            : item.iconColor
                                            ? item.iconColor
                                            : 'white'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:item.name === _vm.$route.name ? 'primary--text font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)])]},proxy:true}],null,true)},_vm._l((item.subItems),function(subItem){return _c('v-list-item',{key:'item' + subItem.text,attrs:{"link":""},on:{"click":function($event){return _vm.enterRoute(subItem)}}},[(subItem.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":subItem.name === _vm.$route.name ? 'primary' : 'white'}},[_vm._v(" "+_vm._s(subItem.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:subItem.name === _vm.$route.name ? 'primary--text font-weight-bold' : ''},[_vm._v(" "+_vm._s(subItem.text)+" ")])],1)],1)}),1):_c('v-list-item',{on:{"click":function($event){return _vm.enterRoute(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.name === _vm.$route.name ? 'primary' : item.iconColor ? item.iconColor : 'white'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:item.name === _vm.$route.name
                                ? 'primary--text font-weight-bold'
                                : item.iconColor
                                ? item.iconColor + '--text'
                                : ''},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }