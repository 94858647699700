<template>
    <v-dialog persistent fullscreen :value="active" :close-on-back="closeOnBack">
        <v-card dark>
            <v-row class="justify-content-center ma-0">
                <v-col cols="auto">
                    <div class="app-bar__image">
                        <lottie-player
                            mode="normal"
                            style="height: 60px; width: 80px"
                            name="roody_icon"
                            autoplay
                            src="/img/lottiefiles/roody_animated.json"
                        ></lottie-player>
                    </div>
                </v-col>
                <v-card-title class="pb-1 font-weight-bold">
                    | {{ forgotPassword ? 'Passwort zurücksetzen' : forgottenLink ? 'Neues Passwort' : 'Login' }}
                </v-card-title>
            </v-row>
            <v-form @submit.prevent ref="loginForm" class="ma-auto" style="max-width: 800px" lazy-validation novalidate>
                <div v-if="forgottenLink">
                    <v-card-title style="word-break: break-word">
                        Du kannst nun hier dein neues Passwort setzen.
                        <br />
                    </v-card-title>
                    <v-text-field
                        class="px-2 py-2"
                        filled
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        color="primary"
                        placeholder="NEUES PASSWORT"
                        clearable
                        rounded
                        hide-details="auto"
                        v-model="newPassword.first"
                        :rules="rules.password"
                        validate-on-blur
                    ></v-text-field>
                    <v-text-field
                        class="px-2 py-2"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        filled
                        color="primary"
                        placeholder="NEUES PASSWORT ERNEUT EINGEBEN"
                        clearable
                        rounded
                        hide-details="auto"
                        v-model="newPassword.second"
                        :rules="rules.password"
                        validate-on-blur
                    ></v-text-field>
                </div>
                <div v-else>
                    <v-card-title style="word-break: break-word">
                        {{
                            forgotPassword
                                ? 'Passwort vergessen? Kein Problem! Gib uns deine E-Mail-Adresse und erhalte einen Zurücksetzungslink. '
                                : 'Willkommen zurück bei Roody, deinem Trainingspartner!'
                        }}
                        <br />
                    </v-card-title>

                    <v-text-field
                        class="py-0 px-2"
                        type="email"
                        clearable
                        filled
                        color="primary"
                        placeholder="E-MAIL ADRESSE"
                        required
                        rounded
                        v-model="formData.email"
                        :rules="rules.email"
                        validate-on-blur
                    ></v-text-field>
                    <v-text-field
                        v-if="!forgotPassword"
                        class="py-0 px-2"
                        v-on:keypress.enter="login"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        filled
                        color="primary"
                        placeholder="PASSWORT"
                        clearable
                        rounded
                        hide-details="auto"
                        v-model="formData.password"
                        :rules="rules.password"
                        validate-on-blur
                    ></v-text-field>
                    <span
                        class="white--text pl-5"
                        style="font-size: 12px"
                        v-if="!forgotPassword"
                        @click="forgotPassword = true"
                    >
                        Passwort vergessen?
                    </span>
                </div>
                <v-card-actions class="justify-content-center">
                    <v-btn
                        v-if="forgotPassword"
                        x-large
                        rounded
                        color="grey"
                        class="ma-auto"
                        @click="forgotPassword = false"
                    >
                        zurück
                    </v-btn>
                    <v-btn x-large rounded color="primary" class="ma-auto" @click="login()" :disabled="!dataEntered">
                        {{ forgotPassword ? 'E-Mail erhalten' : forgottenLink ? 'Passwort setzen' : 'anmelden' }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-btn icon color="grey" @click="$emit('close')" x-large absolute bottom left right class="ma-auto">
            <v-icon x-large>mdi-close-circle-outline</v-icon>
        </v-btn>
    </v-dialog>
</template>

<script>
import api from '../../api'
import { EventBus } from '../../event-bus'

export default {
    name: 'StepFormValidation',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        closeOnBack: {
            type: Boolean,
            default: true,
        },
        autoLogin: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialog: true,
            showPassword: false,
            forgotPassword: false,
            newPassword: {
                first: '',
                second: '',
            },
            formData: {
                email: '',
                password: '',
            },
            rules: {
                email: [
                    (v) => !!v || 'Die E-Mail wird benötigt',
                    (v) =>
                        /^[\w]+([.-]{0,2}[\w]+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
                        'Das ist keine gültige E-Mail',
                ],
            },
        }
    },
    created() {
        // old regex: /^\w+([\.-]{0,2}\w+)*@\w+([\.-]{0,2}\w+)*(\.\w{2,5})+$/
    },
    computed: {
        forgottenLink() {
            return this.$route.name === 'Forgot'
        },
        dataEntered() {
            if (this.forgottenLink) return this.newPassword.first.length && this.newPassword.second.length
            if (this.formData.email && (this.formData.password || this.forgotPassword)) {
                return this.$refs.loginForm.validate()
            }
        },
    },
    methods: {
        login() {
            if (this.forgottenLink) {
                if (!this.newPassword.first.length || !this.newPassword.second.length)
                    return alert('Bitte gib dein neue Passwort ein.')
                if (this.newPassword.first !== this.newPassword.second)
                    return alert('Die Passwörter müssen übereinstimmen')
                api.changePassword({
                    key: location.search.substring(1),
                    password: this.newPassword.first,
                })
                    .then((data) => {
                        if (data.status) {
                            alert('Passwort Aktualisiert.. Bitte melden Sie sich erneut an')
                            this.$router.push('/login')
                        } else {
                            alert('Bitte versuchen Sie es erneut...')
                        }
                    })
                    .catch((error) => {
                        alert('es gab einen Fehler')
                        console.log(error)
                    })
            }
            if (this.forgotPassword) {
                api.resetPassword({
                    email: this.formData.email,
                }).then((data) => {
                    alert(
                        'Falls diese E-Mail in unserem System registriert ist, wurde eine E-Mail an diese versendet. Um das Passwort wiederherzustellen, klicken Sie auf den Link in der Mail. Falls sie keine E-Mail erhalten haben, prüfen Sie bitte Ihren Spam / Werbungs Ordner und die Schreibweise der eingegebenen E-Mail.'
                    )
                })
            } else if (!this.forgotPassword && !this.forgottenLink) {
                api.authenticate({
                    email: this.formData.email,
                    password: this.formData.password,
                })
                    .then((data) => {
                        // TODO: emit a global event
                        if (this.autoLogin) {
                            EventBus.$emit('login', data)
                        } else {
                            this.$emit('login', data)
                        }
                    })
                    .catch((err) => {
                        alert('Falsche E-Mail oder Passwort')
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.v-dialog-transition {
    /* Override default transition */
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
::v-deep .v-text-field__slot {
    input {
        color: red !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
}

.gender {
    opacity: 0.5;

    &--selected {
        opacity: 1;
    }
}
</style>
